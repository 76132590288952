import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { __ } from "@/libs/utilities"
import { ILand } from "@/modules/pe-fest-module/data/interfaces"

/* Protopia Ecosystem component */
export interface IPatternFestivalChooserProps {
   onChoose : (value: ILand) => void
   festival: ILand
}
const PatternFestivalChooser = ({onChoose, festival}: IPatternFestivalChooserProps): JSX.Element => {
    return <div className='container'>
       <FieldInput
            value={ festival }
            title={__( "source Land from this Landscape" )}
            vertical={ VerticalType.HORIZONTAL }
            editable
            type={ SCALAR_TYPES.EXTERNAL }  
            placeholder={__("Select pattern Land")}
            component="Land" 
            onChange={ (value: ILand) => { 
                onChoose( value )
            }}
            seed="-pattern-festival-chooser"
        />
    </div>
}
export default PatternFestivalChooser