import { MediaChooser } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { IFestival, ILand } from "@/modules/pe-fest-module/data/interfaces"
import { useFestExport } from "@/modules/pe-fest-module/data/store"
import { IToposAdminStore, useEventAdminStore } from "@/modules/pe-topos-module/data/store"
import { Tab, TabId, TabPanel, Tabs } from "@blueprintjs/core"
import { useMemo, useState } from "react"
import ContactsTab from "./add-festival-tabs/ContactsTab"
import EventSettingsTab from "./add-festival-tabs/EventSettingsTab"
import EventTypesChooser from "./EventTypesChooser"


const DEFAULT_TABS: any = {
    contacts: <ContactsTab />,
    settings: <EventSettingsTab />
}
/* Protopia Ecosystem component */
export interface ILandscapeContentProps { 
}
const SettingContent = ({ ...props}: ILandscapeContentProps): JSX.Element => {
    const festival: IFestival = useFestExport((state: any) => state.festival)
    const land: ILand = { event_types: [], images: [], ...useFestExport((state: any) => state.land) }
    const newEventTypeSetting: any = useEventAdminStore((state:IToposAdminStore) => state.newEventTypeSetting)

    const [navbarTabId, setNavbarTabId] = useState<TabId>("settings")
    const panels = useMemo(() => {
        let _panels = {...DEFAULT_TABS}
        Object.keys( newEventTypeSetting ).forEach((key: string) => {
            if( land.event_types.includes( newEventTypeSetting[key].eventType ) ) {
                const ElComponento = newEventTypeSetting[key].component
                _panels[key] = <ElComponento item={festival} />
            }
        })
        return _panels
    }, [ land ])

    const onTab = ( nId:TabId ) => {
        setNavbarTabId( nId )
    }

    const setLand = ( _land: ILand ) => {
        useFestExport.setState({ land: _land })
    } 
    
    return <div className="p-4 mb-2 position-relative">
        <div className="py-3">
            <Tabs id="new-fest-settings" onChange={ onTab } selectedTabId={ navbarTabId }>  
                <Tab
                    id="settings"
                    title={__("Settings")}
                />
                    <Tab
                    id="contacts"
                    title={__("Contacts")} 
                /> 
                {
                    Object.keys( newEventTypeSetting )
                    .filter(key => land.event_types.includes( newEventTypeSetting[key].eventType ) )
                    .map((key:string) => {
                        return <Tab
                            key={key}
                            id={key}
                            title={__(key)}
                        />
                    })
                }
            </Tabs>
        </div>
        <div className="lead mb-1">{__("Default image")}</div>
        <div className="row">
            <div className=" col-12 ">
                <MediaChooser
                    url={ land.defaultThumbnail || "" }
                    prefix={"icon"}
                    height={230}
                    width={"100%"}
                    padding={5}
                    onChange={ () => {} }
                    id={ land.defaultThumbnail || "" }
                    isURLHide={ true }
                    isDescr={ false }
                    isFileNameHide
                    hideLib
                    ID="headerImg"
                    limitSize={ 4000000 }
                />
                <div className="small mt-1 text-right">{
                    __("This is the header image for the home page.")}
                </div>
            </div> 
            
            <div className="lead mt-3 col-12">{__("Set event type")}</div>       
            <div className="d-flex flex-wrap col-12">
                <EventTypesChooser land={land}  setLand={setLand} />
            </div>

        </div>
        <TabPanel
            id={navbarTabId}
            selectedTabId={navbarTabId}
            parentId="new-fest-settings"
            panel={ panels[ navbarTabId ] }
        />
    </div>
}
export default SettingContent