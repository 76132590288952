import React from 'react'
import { Link } from 'react-router-dom'
import { __ } from 'src/libs/utilities'
import { EMTY_IMAGE_NAME, ICardProjectProps, IFestival } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import getProjectLink from '../../data/utils/getProjectLink'
import { useOwnerPartner, useUserOwner } from '../../hooks/project'
import ExtendedForms from '../project/projectData/ExtendedForms'
import MemberRatingDisplay from './MemberRatingDisplay'
import TrackLabel from './TrackLabel'

const CardProject = (props: ICardProjectProps): JSX.Element => { 
    const isOwner: boolean = useUserOwner( props.item )
    const isPartner:boolean = useOwnerPartner(props.item)
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const gColor = "#14172b" 
    const url = getProjectLink(props.item.id)
    const backgroundImage = props.item.thumbnail && props.item.thumbnail.indexOf(EMTY_IMAGE_NAME) === -1  
        ? 
        props.item.thumbnail 
        : 
        festival.defaultProjectThumbnail 
    const onIgnore = (evt: React.MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        evt.stopPropagation()
    }
    return <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">      
        <Link className="card h-100 m-0 p-0 position-relative " to={url}>
            <div
                className="card-img"
                style={{ 
                    backgroundImage: `url(${backgroundImage})`,
                    borderColor: gColor,
                    height: festival.memberCardHeight,
                    opacity: !props.item.is_verified ? .5 : 1,
                }}
                data-mid={props.item.id}
                data-fmru_type="fmru_player"
                data-args={props.item.id}
            >
                <div className="card-id">
                    {props.item.order}
                </div>
                <TrackLabel track={props.item.track} />
            </div>
            <div className="card-icons">
                {/* {_ganres} */}
            </div>
            <div
                className="card-header d-flex justify-content-center align-items-center"
                title={props.item.title}
                style={{
                    height: 76,
                    overflow: "hidden",
                    padding: "0 1.25em",
                    position: "relative",
                    display: "table",
                }}
                >
                <h5 className="m-0 w-100 text-center " style={{ lineHeight: 1.0 }} >
                    {props.item.title}
                </h5>
            </div>
            {
                !props.item.is_verified && 
                <div className="mine-corner-no-verified corner-card-project">
                    {__("Project has not yet been approved")}
                </div>
            }
            <ul className="list-group list-group-flush flex-grow-100  mt-auto">
                {
                    props.item.prize
                    ?
                    <li className="list-group-item pb-2"> 
                        <div className=" pe-pennant pe-pumpkit" style={{height:80}}> 
                            {props.item.prize} 
                        </div>
                    </li>
                    :
                    null
                }
                {
                    props.item.nomination
                    ?
                    <li className="list-group-item pb-2">
                        <div className=" pe-pennant pe-pumpkit-dark" style={{height:80}}> 
                            {props.item.nomination} 
                        </div>
                    </li>
                    :
                    null
                }   
                <div className='mt-auto w-100' />  
                <ExtendedForms 
                    vertical
                    item={ props.item } 
                    isCollapseOpen 
                    hidden="hide_in_card"
                    titleClassName="small d-flex justify-content-center align-items-center text-center"
                    className="text-center small title flex-centered"
                />
                {
                    Array.isArray(props.item.fmru_school) 
                        &&
                        props.item.fmru_school.map(school => {
                            return <li className="list-group-item pb-2" key={school.id}> 
                                <div className="p-3 small text-center text-dark" > 
                                    { school.title } 
                                </div>
                            </li>
                        })
                        
                }
                <li className="list-group-item justify-content-between d-flex align-items-center">
                    <span className="discr text-dark">
                        {__("Rating")}:
                    </span>
                    <span className="data p-2"> 
                        <MemberRatingDisplay 
                            correct_member_rate={props.item.correctMemberRate}
                            countedRating={ props.item.actualRating }
                            className={"text-dark "}
                        />  
                    </span>
                </li>
                <li className="list-group-item justify-content-between d-flex align-items-center">
                    <span className="discr text-dark">
                        {__("Expert count:")}
                    </span>
                    <span className="data selected p-2">
                        {props.item.experts?.length || 0}
                    </span>
                </li>
                <li className="list-group-item " onClick={onIgnore}>
                    <div
                        className="social-likes share text-dark"
                        data-url={url}
                        data-title={props.item.title}
                        style={{ padding: "11px 20px" }}
                    > 
                        <div className="social-likes__widget social-likes__widget_mailru " title="Поделиться ссылкой в Моём мире" >
                            <div className="social-likes__icon social-likes__icon_mailru"></div>
                        </div>
                        <div className="social-likes__widget social-likes__widget_vkontakte " title="Поделиться ссылкой во Вконтакте" >
                            
                        </div>
                        <div className="social-likes__widget social-likes__widget_odnoklassniki " title="Поделиться ссылкой в Одноклассниках" >
                            
                        </div>


                        <div className="mailru hover-straght" title="Поделиться ссылкой в Моём мире" >
                            <i className="fas fa-at"></i>
                        </div>
                        <div className="vkontakte hover-straght" title="Поделиться ссылкой во Вконтакте" >
                            <i className="fab fa-vk"></i>
                        </div>
                        <div className="odnoklassniki hover-straght" title="Поделиться ссылкой в Одноклассниках" >
                            <i className="fab fa-odnoklassniki"></i>
                        </div>
                        <div className="dzen hover-straght" title="Поделиться ссылкой в Дзен" >
                            <svg 
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg" 
                                xmlnsXlink="http://www.w3.org/1999/xlink" 
                                x="0px" 
                                y="0px"
                                width="14"
                                height="14"
                                viewBox="0 0 105 105.5" 
                                xmlSpace="preserve"
                            >
                                <path fill='#FFF' d="M50.9,0c3.3,0,6.7,0,10,0c0.3,0,0.5,0.1,0.8,0.1C67.4,0.2,73.3,0.3,79,1.2c3,0.5,5.9,1.1,8.7,2.3c2.7,1.1,5.1,2.6,6.9,4.9
                                    c2,2.4,3.2,5.3,4,8.3c1.2,4.1,1.7,8.3,1.9,12.6c0.2,3.6,0.3,7.1,0.4,10.7c0,0.2,0,0.3,0.1,0.5c0,3.2,0,6.5,0,9.7
                                    c-3-0.1-6.1-0.2-9.1-0.3c-2.5-0.1-5.1-0.2-7.6-0.4c-2.5-0.2-5-0.5-7.5-0.8c-3.1-0.4-6-1.2-8.9-2.4c-4-1.7-7.4-4.3-10.1-7.8
                                    c-2.5-3.4-4-7.2-4.9-11.3c-0.4-2.2-0.7-4.4-1-6.6c-0.2-1.8-0.4-3.6-0.5-5.4c-0.1-1.9-0.2-3.8-0.3-5.7C51.1,6.3,51,3.1,50.9,0z"/>
                                <path fill='#FFF' d="M60.7,101.5c-3.3,0-6.6,0-9.9,0c0-1.5,0-2.9,0.1-4.4c0.1-2.9,0.3-5.8,0.4-8.7c0.1-1.8,0.2-3.5,0.4-5.3
                                c0.2-2.2,0.5-4.4,0.8-6.5c0.4-2.7,1.1-5.3,2.1-7.8c2.1-5.1,5.4-9.1,10.1-11.9c4-2.4,8.5-3.5,13-4.2c1.5-0.2,3.1-0.4,4.6-0.5
                                c1.7-0.2,3.4-0.3,5.1-0.4c1.4-0.1,2.9-0.1,4.3-0.2c3.1-0.1,6.1-0.2,9.2-0.3c0,3.2,0,6.5,0,9.7c0,0.3-0.1,0.5-0.1,0.8
                                c-0.1,5.8-0.3,11.7-1.1,17.4c-0.4,2.9-1.1,5.7-2.1,8.4c-1.1,2.9-2.7,5.5-5.1,7.5c-1.9,1.5-4,2.6-6.2,3.4c-3.9,1.4-8,2.1-12.1,2.3
                                c-4,0.3-8.1,0.4-12.1,0.6C61.6,101.4,61.2,101.5,60.7,101.5z"/>
                                <path fill='#FFF' d="M-0.5,61.2c0-3.3,0-6.6,0-9.9c2.5,0.1,5,0.2,7.5,0.3c2.5,0.1,5,0.2,7.5,0.4c2.7,0.2,5.4,0.5,8.1,0.8
                                    c2.6,0.3,5.2,0.9,7.7,1.7c4.9,1.6,9,4.3,12.2,8.4c2.6,3.4,4.2,7.4,5,11.6c0.4,2.2,0.7,4.4,1,6.6c0.2,1.8,0.4,3.6,0.5,5.4
                                    c0.1,1.9,0.2,3.8,0.3,5.7c0.1,3.1,0.2,6.2,0.3,9.2c-3.3,0-6.6,0-9.9,0c-0.3,0-0.5-0.1-0.8-0.1c-5.5-0.1-10.9-0.2-16.3-1
                                    c-3.3-0.5-6.6-1.1-9.8-2.4c-2.4-1-4.6-2.3-6.3-4.2c-2.1-2.3-3.4-5-4.3-7.9c-1.4-4.5-2-9.1-2.3-13.7c-0.2-3.5-0.3-7-0.4-10.5
                                    C-0.4,61.6-0.5,61.4-0.5,61.2z"/>
                                <path fill='#FFF' d="M-0.5,50.1c0-3.3,0-6.6,0-9.9c0-0.2,0.1-0.4,0.1-0.6c0.1-5.8,0.3-11.6,1.1-17.4c0.5-3,1.1-5.9,2.3-8.8c1-2.3,2.2-4.5,4-6.3
                                    c2.3-2.2,5-3.5,8-4.5c4-1.3,8.1-1.9,12.3-2.2c3.9-0.3,7.7-0.3,11.6-0.5c0.3,0,0.6,0,0.8-0.1c3.3,0,6.7,0,10,0c0,0.6,0,1.2,0,1.8
                                    c-0.1,3.2-0.3,6.4-0.4,9.6c-0.1,1.6-0.1,3.2-0.3,4.8c-0.2,2.3-0.5,4.6-0.7,6.9c-0.3,2.6-0.9,5.2-1.7,7.7c-1.6,4.9-4.3,9-8.3,12.1
                                    c-3.8,3-8.2,4.5-12.9,5.4c-1.8,0.3-3.6,0.6-5.4,0.8c-1.8,0.2-3.6,0.4-5.4,0.5c-1.9,0.1-3.7,0.2-5.6,0.3C5.7,49.9,2.6,50-0.5,50.1z"
                                />
                            </svg>
                        </div>
                    </div>
                </li>
            </ul>
            {
                isOwner
                    ?
                    <div className="mine-corner corner-3 ">
                        { __( "My project" ) }
                    </div>
                    :                    
                    isPartner 
                        ?
                        <div className="mine-honeycombs-corner mine-corner1">
                            {__("My honeycombs")}
                        </div>
                        :
                        null
            }  
            {/* {
                this.props.store?.myTracks?.filter(mtID => mtID == track.ID).length > 0
                    ?
                    <div className="my-track-label-stroke">
                    { __( "My Track" ) }
                    </div>
                    :
                    null
            }   */}
        </Link>
    </div>
}
 

export default CardProject