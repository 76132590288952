import { CHANGE_SERVAICE_OPTIONS_ACTION, GET_ALL_ROLES_ACTION, GET_SERVICE_OPTIONS_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import actions from "@/modules/pe-basic-module/data/actions"
import switchToBlog from "@/modules/pe-basic-module/hooks/switchToBlog"
import NoMatchForm from "@/modules/pe-basic-module/views/no-mathc/NoMatchForm"
// import { clearFestival } from "@/modules/pe-fest-module/hooks/festival"
import { isRole } from "@/settings/zustand/user"
import { DEFAULT_404_PROPS } from "@/libs/interfaces/dummies"
import { Json, Role } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { Loading } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { Button, Intent } from "@blueprintjs/core"
import { useEffect, useState } from "react"

const EventSettingForm = (props:any) :JSX.Element => {
    useEffect(() => {   
        //clearFestival()        
        switchToBlog( "-1" )
    }, [])
    const [item, setItem] = useState<any>( { roles: [] } )
    const [itemJson, setItemJson] = useState<Json>( JSON.stringify({ roles: [] }) )
    const [allRoles, setAllRoles] = useState<Role[]>( [] ) 
    const [isLoading, setIsLoading] = useState<any>( false )
    useEffect(() => { 
        actions(GET_ALL_ROLES_ACTION, {}).then(res => {
            setAllRoles(res)
            actions(GET_SERVICE_OPTIONS_ACTION, {})
                .then((resp: any) => { 
                    setItem(resp)
                    setItemJson(JSON.stringify(resp))
                    setIsLoading(false)
                })
        })
    }, [])

    if( !isRole([ "administrator" ]) ) return <NoMatchForm {...DEFAULT_404_PROPS} />

    if( isLoading ) {
        return <div className="layout-state flex-centered">
            <Loading />
        </div> 
    }
    const onRoles = (value: Role[]) => {
        setItem({ ...item, roles: value })
    }
    const onChange = () => {
        actions(CHANGE_SERVAICE_OPTIONS_ACTION, { item })
            .then((resp: any) => {
                delete resp.__typename 
                setItem( resp )
                setItemJson( JSON.stringify( resp ) )
            })
    }
    return <div className="short-container flex-grow-100"> 
        <FieldInput
            value={ item.roles }
            editable
            vertical={ VerticalType.VERTICAL }
            values={ allRoles }
            type={ SCALAR_TYPES.CHECKBOX }
            title={__("User roles that give the right to create new Events")}
            onChange={ onRoles }
        />
        <div className="flex-centered p-2">
            <Button
                large
                intent={ JSON.stringify(item) === itemJson ? Intent.NONE : Intent.DANGER }
                disabled={ JSON.stringify(item) === itemJson }
                onClick={ onChange }
            >
                {__("Update")}
            </Button>
        </div>
    </div>
} 

export default EventSettingForm