import LoginView from '@/modules/pe-basic-module/views/LoginView'
import NoMatchView from '@/modules/pe-basic-module/views/NoMatchView'
import getAllViews from '@/settings/getAllViews'
import { IState, IUser, useMainStore } from '@/settings/zustand'
import { DEFAULT_404_PROPS } from '@/libs/interfaces/dummies'
import { IMenuItem } from '@/libs/interfaces/layouts'
import { useEffect, useState } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { addRoute } from './addRoute'
import defaultRoutings from './defaultRoutings'

let views: any = {}
let routeItems: any[] = []

const LayoutMain = (): JSX.Element | null => { 
    const [isLoading, setIsLoading] = useState(true) 
    const user: IUser = useMainStore((state: IState) => state.user )
    const lyt = useMainStore((state: IState) => state.layout ) 
    useEffect(() => {
        routeItems = []
        let ElComponento: any, ElComponento2: any  
        views = getAllViews()
        defaultRoutings().forEach((menuGroup: IMenuItem, n: number) => {
            routeItems = addRoute(
                { ...menuGroup, i: n,  },
                n, 
                ElComponento, 
                ElComponento2, 
                user,
                views,
                routeItems
            )
        })
        // console.log( lyt.routing )
        Object.keys(lyt.routing).forEach((menuGroup: string, n: number) => {
            lyt.routing[menuGroup].forEach((m: IMenuItem, i: number) => { 
                routeItems = addRoute(
                    { ...m, i: 1000 + 1000 * n + i }, 
                    n, 
                    ElComponento, 
                    ElComponento2, 
                    user,
                    views,
                    routeItems
                )
                //console.log( m.children )
            })
        })
        const key = "404"
        routeItems = [
            ...routeItems,
            <Route path='*' element={<NoMatchView {...DEFAULT_404_PROPS} />} key={ key } />
        ]
        

        setIsLoading(false)    
    } , [ lyt ]) 
    
    // console.log( routeItems )
    return isLoading
        ?
        null
        :
        <div className=' layout-app-state overflow-y-auto ' id="pe-layout-main">
            <Routes>
                <Route path="/" element={<><Outlet /></>} >
                    {routeItems}
                    <Route path='login' element={<LoginView />} />
                    <Route path='*' element={<NoMatchView {...DEFAULT_404_PROPS} />} />
                </Route>
            </Routes> 
        </div>
}


export default LayoutMain
