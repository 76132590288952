import { ILogDatas, LOG_DANGER_TYPE } from "./interfaces"


export const LogData = (): ILogDatas => {
    return {
        not_rights_send_mail: {
            chapter: "not_rights_send_mail",
            title: "Not right to send mail",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        },
        external_create: {
            chapter: "external_create",
            title: "Try create external user",
            dangerType: LOG_DANGER_TYPE.INFO 
        }, 
        success_external_create: {
            chapter: "success_external_create",
            title: "Successfully create external user",
            dangerType: LOG_DANGER_TYPE.INFO 
        }, 
        success_external_token: {
            chapter: "success_external_token",
            title: "Successfully enter external",
            dangerType: LOG_DANGER_TYPE.INFO 
        }, 
        external_login: {
            chapter: "external_login",
            title: "Not successfully try create external",
            dangerType: LOG_DANGER_TYPE.ERROR 
        },
        wrong_password: {
            chapter: "wrong_password",
            title: "Wrong password by external",
            dangerType: LOG_DANGER_TYPE.ERROR 
        },
        is_blocked: {
            chapter: "is_blocked",
            title: "Try enter external as blocked",
            dangerType: LOG_DANGER_TYPE.INFO 
        },
        not_can_change_post: {
            chapter: "not_can_change_post",
            title: "not_can_change_post",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        error_create_taxonomy: {
            chapter: "error_create_taxonomy",
            title: "error_create_taxonomy",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        error_change_taxonomy: {
            chapter: "error_change_taxonomy",
            title: "error_change_taxonomy",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        exec_options: {
            chapter: "exec_options",
            title: "Illegal exec options",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        },
        not_rights_get_options: {
            chapter: "not_rights_get_options",
            title: "not_rights_get_options",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        },
        not_rights_change_options: {
            chapter: "not_rights_change_options",
            title: "not_rights_change_options",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        },
        unknown_discussion_type: {
            chapter: "unknown_discussion_type",
            title: "unknown_discussion_type",
            dangerType: LOG_DANGER_TYPE.WARNING
        },
        not_rights_delete: {
            chapter: "not_rights_delete",
            title: "not_rights_delete",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        not_rights_delete_post: {
            chapter: "not_rights_delete_post",
            title: "not_rights_delete_post",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        create_bulk : {
            chapter: "create_bulk",
            title: "create_bulk",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        change_bulk : {
            chapter: "change_bulk",
            title: "change_bulk",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        change_service_options_not_rights : {
            chapter: "change_service_options_not_rights",
            title: "Illegal change_service_options_not_rights",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        },
        getAllSuperAdmins_not_rights : {
            chapter: "getAllSuperAdmins_not_rights",
            title: "Illegal getAllSuperAdmins not rights",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        },
        setSuperAdmin_not_rights : {
            chapter: "setSuperAdmin_not_rights",
            title: "Illegal set SuperAdmin not rights",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        },
        set_super_admin : {
            chapter: "set_super_admin",
            title: "set SuperAdmin",
            dangerType: LOG_DANGER_TYPE.WARNING
        },
        revoke_super_admin : {
            chapter: "revoke_super_admin",
            title: "revoke SuperAdmin",
            dangerType: LOG_DANGER_TYPE.WARNING
        },
        try_register_user : {
            chapter: "try_register_user",
            title: "try_register_user",
            dangerType: LOG_DANGER_TYPE.INFO
        },
        error_register_user : {
            chapter: "error_register_user",
            title: "error_register_user",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        success_register_user : {
            chapter: "success_register_user",
            title: "success_register_user",
            dangerType: LOG_DANGER_TYPE.ERROR
        }, 
        try_edit_user : {
            chapter: "try_edit_user",
            title: "try_edit_user",
            dangerType: LOG_DANGER_TYPE.INFO
        },
        error_edit_user : {
            chapter: "error_edit_user",
            title: "error_edit_user",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        success_edit_user : {
            chapter: "success_edit_user",
            title: "success_edit_user",
            dangerType: LOG_DANGER_TYPE.INFO
        },
        edit_current_user : {
            chapter: "edit_current_user",
            title: "edit_current_user",
            dangerType: LOG_DANGER_TYPE.INFO
        },
        error_edit_current_user : {
            chapter: "error_edit_current_user",
            title: "error_edit_current_user",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        verify_user : {
            chapter: "verify_user",
            title: "verify_user",
            dangerType: LOG_DANGER_TYPE.INFO
        },
        error_verify_user : {
            chapter: "error_verify_user",
            title: "error_verify_user",
            dangerType: LOG_DANGER_TYPE.WARNING
        },
        delete_user : {
            chapter: "delete_user",
            title: "delete_user",
            dangerType: LOG_DANGER_TYPE.INFO
        },
        illegal_delete_user : {
            chapter: "illegal_delete_user",
            title: "illegal_delete_user",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        },
        illegal_delete_bulk_user : {
            chapter: "illegal_delete_bulk_user",
            title: "illegal_delete_bulk_user",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        },
        delete_bulk_user : {
            chapter: "delete_bulk_user",
            title: "delete_bulk_user",
            dangerType: LOG_DANGER_TYPE.INFO
        },
        add_user_to_blog : {
            chapter: "add_user_to_blog",
            title: "add user to blog",
            dangerType: LOG_DANGER_TYPE.INFO
        },
        un_correct_user_info : {
            chapter: "un_correct_user_info",
            title: "Not correct user_info",
            dangerType: LOG_DANGER_TYPE.WARNING
        },
        error_token : {
            chapter: "error_token",
            title: "Error token",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        token : {
            chapter: "token",
            title: "Token",
            dangerType: LOG_DANGER_TYPE.INFO
        },
        token_is_blocked : {
            chapter: "token_is_blocked",
            title: "token as blocked",
            dangerType: LOG_DANGER_TYPE.INFO
        },
        token_as_not_verify : {
            chapter: "token_as_not_verify",
            title: "token as not verify",
            dangerType: LOG_DANGER_TYPE.INFO
        },

        not_can_change_land: {
            chapter: "not_can_change_land",
            title: "Not can change land",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        },
        change_land_not_id: {
            chapter: "change_land_not_id",
            title: "Change_land_not_id",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        register_land: {
            chapter: "register_land",
            title: "Register land",
            dangerType: LOG_DANGER_TYPE.ERROR
        },
        not_can_register_land: {
            chapter: "not_can_register_land",
            title: "Not can register land",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        },
        try_register_project: {
            chapter: "try_register_project",
            title: "Try register project",
            dangerType: LOG_DANGER_TYPE.INFO
        }, 
        error_register_project: {
            chapter: "error_register_project",
            title: "Error register project",
            dangerType: LOG_DANGER_TYPE.ERROR
        }, 
        register_project: {
            chapter: "register_project",
            title: "Successfully register project",
            dangerType: LOG_DANGER_TYPE.INFO
        }, 
        correct_project_rate: {
            chapter: "correct_project_rate",
            title: "Correct project rate",
            dangerType: LOG_DANGER_TYPE.INFO
        }, 
        change_exhortation: {
            chapter: "change_exhortation",
            title: "add/change Exhortation",
            dangerType: LOG_DANGER_TYPE.INFO
        }, 
        delete_exhortation: {
            chapter: "delete_exhortation",
            title: "Delete exhortation",
            dangerType: LOG_DANGER_TYPE.INFO
        }, 
        change_project_ration: {
            chapter: "change_project_ration",
            title: "Change project ration",
            dangerType: LOG_DANGER_TYPE.INFO
        }, 
        insert_project_attachment: {
            chapter: "insert_project_attachment",
            title: "Insert project attachment",
            dangerType: LOG_DANGER_TYPE.INFO
        }, 
        register_festival: {
            chapter: "register_festival",
            title: "Register festival",
            dangerType: LOG_DANGER_TYPE.INFO
        }, 
        add_cannon_land: {
            chapter: "add_cannon_land",
            title: "Add Land for cannon test",
            dangerType: LOG_DANGER_TYPE.WARNING
        }, 
        add_cannon_users: {
            chapter: "add_cannon_users",
            title: "Add Users for cannon test",
            dangerType: LOG_DANGER_TYPE.WARNING
        }, 
        add_cannon_full: {
            chapter: "add_cannon_full",
            title: "Add all staff for cannon test",
            dangerType: LOG_DANGER_TYPE.WARNING
        }, 
        get_log_files: {
            chapter: "get_log_files",
            title: "Illegal get log files",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        }, 
        delete_log_file: {
            chapter: "delete_log_file",
            title: "Illegal delete log file",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        }, 
        delete_trash_log_files: {
            chapter: "delete_trash_log_files",
            title: "Illegal delete trash log files",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        }, 
        clean_trash_log: {
            chapter: "clean_trash_log",
            title: "Illegal clean trash log",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        }, 
        get_trash_log_files: {
            chapter: "get_trash_log_files",
            title: "Illegal get trash log files",
            dangerType: LOG_DANGER_TYPE.FORCE_MAJEURE
        }, 
        try_vk_sign_in: {
            chapter: "try_vk_sign_in",
            title: "try vk sign in",
            dangerType: LOG_DANGER_TYPE.INFO
        }, 
        error_vk_sign_in: {
            chapter: "error_vk_sign_in",
            title: "error_vk_sign_in",
            dangerType: LOG_DANGER_TYPE.INFO
        }, 
        vk_sign_in: {
            chapter: "vk_sign_in",
            title: "vk sign in",
            dangerType: LOG_DANGER_TYPE.INFO
        }, 
    }
}