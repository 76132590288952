import { Button, ButtonGroup, Dialog, Icon, IconName, Intent, MaybeElement, Popover, Position } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { __ } from "../utilities"

 
interface IProps {
    buttonIntent?: Intent
    buttonLoading?: boolean
    buttonLabel?: string | JSX.Element
    buttonLarge?:boolean
    buttonFill?:boolean
    buttonMinimal?:boolean
    buttonClassName?: string
    buttonIcon?: MaybeElement | IconName
    buttonDisabled?:boolean
    dialogClasssName?: DIALOG_SIZE
    dialogAddedClassName?: string
    dialogTitle?: string
    children?: JSX.Element
    isOpen?:boolean
    isPopover?: boolean
    popoverPosition?: Position
    popover?: string | JSX.Element
    onDesmiss?: () => void
    onConfirm: () => void
    noLabel?:string | JSX.Element
    yesLabel?:string | JSX.Element
    noLock?:boolean
    yesLock?:boolean
    isHiddenNo?: boolean
    isHiddenYes?: boolean
    buttonSmall?:boolean
    confirm?: string
}
const ConfirmButton = (props: IProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>( !!props.isOpen )
    useEffect(() => {
        setIsOpen( !!props.isOpen )
    }, [props.isOpen])
    const onOpen = () => {
        setIsOpen(!isOpen)
    }
    const onClose = () => {
        if(props.onDesmiss)
            props.onDesmiss()
        if(props.noLock) return
        setIsOpen(false)
    }
    const onConfirm = () => {
        props.onConfirm()
        if(props.yesLock) return 
        setIsOpen(false) 
    }
    const btn = props.isPopover
        ?
        <Popover
            interactionKind="hover"
            position={props.popoverPosition}
            content={props.popover}
        >
            <Button 
                intent={props.buttonIntent} 
                large={props.buttonLarge}
                disabled={props.buttonDisabled}
                fill={props.buttonFill}
                minimal={props.buttonMinimal}
                className={props.buttonClassName}
                icon={props.buttonIcon} 
                onClick={onOpen}
                loading={props.buttonLoading}
            >
                {props.buttonLabel}
            </Button>   
        </Popover>
        :
        <Button 
            intent={props.buttonIntent} 
            large={props.buttonLarge}
            disabled={props.buttonDisabled}
            fill={props.buttonFill}
            minimal={props.buttonMinimal}
            className={props.buttonClassName}
            icon={props.buttonIcon} 
            onClick={onOpen}
            loading={props.buttonLoading}
        >
            {props.buttonLabel}
        </Button>
    return <>
        { btn }
        <Dialog
            className={`${ props.confirm ? DIALOG_SIZE.MINIMAL : props.dialogClasssName} ${props.dialogAddedClassName}`}
            isOpen={ isOpen }
            onClose={ isOpen ? onClose : onOpen } 
            title={props.dialogTitle}
        > 
            <div className="d-flex flex-column flex-grow-100">
                <div className="flex-grow-100 ">
                    { 
                        props.confirm 
                            ? 
                            <div className="p-5 lead text-center">{ props.confirm } </div> 
                            : 
                            props.children 
                    } 
                </div>
                <ButtonGroup fill>
                    {
                        !props.isHiddenNo && <Button intent={Intent.DANGER} fill large onClick={onClose}>
                            { 
                                typeof props.noLabel === "string" || !props.noLabel
                                    ?
                                    __(props.noLabel || "No")
                                    :
                                    props.noLabel
                            }
                        </Button>
                    }
                    {
                        !props.isHiddenYes && <Button disabled={props.yesLock} intent={props.yesLock ? Intent.NONE : Intent.SUCCESS} fill large onClick={onConfirm}>
                            {
                                typeof props.yesLabel === "string" || !props.yesLabel
                                    ?
                                    __(props.yesLabel || "Yes")
                                    :
                                    props.yesLabel
                            }
                        </Button>
                    }
                </ButtonGroup>
            </div>            
            <Button minimal onClick={onOpen} className="position-absolute top right px-3 py-2">
                <Icon icon="cross" size={20} color="#FFFFFF"/>
            </Button>
            
        </Dialog>
    </>
} 

export default ConfirmButton