import { IFestForm, IFestival } from "@/modules/pe-fest-module/data/interfaces"
import { useFestExport } from "@/modules/pe-fest-module/data/store"
import FestSettingsForm from "../../../festAdmin/FestSettingsForm"

/* Protopia Ecosystem component */
export interface INewFestivalSettingsProps { 
   item: IFestForm
}
const NewFestivalSettings = ( {item, ...props}: INewFestivalSettingsProps ): JSX.Element => { 
    const festival:IFestival = useFestExport(( state:any ) => state.festival)
    return <div className='pe-new-festival-settings-container'>  
        <FestSettingsForm 
            festival={ festival } 
            setFestival={ (festival:IFestival) => useFestExport.setState({ festival }) }
        />
    </div>
}
export default NewFestivalSettings