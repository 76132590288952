import FieldInput, { SCALAR_TYPES } from "src/libs/scalars"
import { __ } from "src/libs/utilities"
import { ILand } from "src/modules/pe-fest-module/data/interfaces"

/* Protopia Ecosystem component */
export interface IStartMessagesEditFormProps {
    newProjectEmail: string
    newProjectMessage: string
    onValue: (value: any, field: keyof ILand) => void
}
const StartMessagesEditForm = (props: IStartMessagesEditFormProps): JSX.Element => {
    
    const onValue = (value: string, field: keyof ILand) => {
        props.onValue( value, field ) 
    }
    return <div className='pe-start-messages-edit-form-container container'>
        <div className="row">
            <div className="col-12">
                <div className="display-6 my-3">{__("New project screen message")}</div>
                <FieldInput
                    editable
                    type={SCALAR_TYPES.HTML}
                    value={props.newProjectMessage}
                    onChange={value => onValue(value, "newProjectMessage")}
                />
            </div>
            <div className="col-12">
                <div className="display-6 my-3">{__("New project screen email text")}</div>
                <FieldInput
                    editable
                    type={SCALAR_TYPES.HTML}
                    value={props.newProjectEmail}
                    onChange={value => onValue(value, "newProjectEmail")}
                />
            </div>
        </div>
    </div>
}
export default StartMessagesEditForm