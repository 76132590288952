import { __ } from '@/libs/utilities'
import { Outlet } from 'react-router'
import { NavLink } from 'react-router-dom'
import { IFestival, IProject } from '../../data/interfaces'
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from '../../data/store'
import { useUserOwner } from '../../hooks/project'
import PhaseLabel from '../PhaseLabel' 
import ProjectData from './ProjectData' 

const ProjectContainer = (props:any):JSX.Element => {
    const item: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject)

    return <div>
        <ProjectData {...props} item={item} />
        <div className="d-flex w-100 justify-content-center">
            <div className="mx-2"> 
                <PhaseLabel />
            </div>     
            <Links />                   
        </div>
        <Outlet/>
    </div>
}
 

export default ProjectContainer

const Links = () : JSX.Element[] | null => {    
    const festival:IFestival = useFestDataStore( ( state:IFestDataStor )  => state.festival )
    const item: IProject = useFestProjects( ( state: IFestProjectsProps ) => state.currentProject )  
    const isOwner: boolean = useUserOwner( item ) 
    if( !festival.isDiary  && !isOwner ) return null

    let links: any[] = []
    if(isOwner) {
        links = links.concat(
            links, 
            [
                {
                    title: "Project team",
                    route: "project-team"
                },
                {
                    title: "Project management",
                    route: "management"
                },
            ]
        )
    }
    if(festival.isDiary){
        links.splice(0, 0, {title: "Diary", route: "diary"})
    }
    if( !!links.length ) links.unshift({title: "Ratings", route: "ratings"})
    return links.map((l:any) => <NavLink
        key={l.route}
        to={l.route}
        className={ setActive }
    >
        {__(l.title)}
    </NavLink>  )
}
interface IActiveProps {
    isActive: boolean
}
const setActive = ({ isActive } : IActiveProps) => isActive ? "fest-project-route-btn text-force-contrast active-link" : " fest-project-route-btn text-force-contrast "