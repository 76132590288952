// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import useScreenSize from '@/modules/pe-basic-module/hooks/useScreenSize';
import { IMedia, WINDOW_WIDTH } from '@/libs/interfaces/layouts';
import { default as UUID } from "node-uuid";
import { useState } from 'react';
import GalleryDialog from './GalleryDialog';
import GalleryItem from './GalleryItem';
import NextButton from './NextButton';
import PrevButton from './PrevButton';
import "./style.scss";

const {  Navigation, Pagination, Scrollbar, A11y } =  require("swiper/modules")  
const {   Swiper, SwiperSlide } =  require("swiper/react")  

export interface IMediaSizes {
    [WINDOW_WIDTH.PANEL]    : number
    [WINDOW_WIDTH.LARGE]    : number
    [WINDOW_WIDTH.SCREEN]   : number
    [WINDOW_WIDTH.TABLET]   : number
    [WINDOW_WIDTH.MOBILE]   : number
    [WINDOW_WIDTH.SMALL]    : number
}

export interface IGalleryProps {
    items: ( IMedia | URL | string )[]
    itemWidth:number | string
    itemHeight?:number
    count?: number | IMediaSizes
    start?: number
    loop?:boolean
    navigation?:boolean
    pagination?:boolean
    padding?: number
}
export const Gallery = ({items, itemWidth, itemHeight, ...props}:IGalleryProps) => {
    const { dimention } = useScreenSize()
    const [ isOpen, setIsOpen ] = useState( false )
    const [ i, setI ] = useState(0)
    const [ start, setStart ] = useState( props.start || 0 )
    const data: IMedia[ ] = items.map((item : IMedia | URL | string) => {
        if( typeof item === "string" ) {
            return { 
                id: Math.random() + "", 
                title: "", 
                post_content: item, 
                thumbnail: item 
            } as IMedia
        }
        return item as IMedia
    }) || []
    const count = !!props.count
        ?
        typeof props.count === "number"
            ?
            props.count
            :
            ( props.count as IMediaSizes )[ WINDOW_WIDTH[ dimention ] ]
        :
        0 
    const to = (i:number) => {
        setI(i)
        setIsOpen( true )
    } 
    const onPrev = () => { 
        setStart( start - 1 )
    }
    const onNext = () => { 
        setStart( start + 1 )
    }
    return <div className='gallery position-relative'>
        <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={props.padding}  
        loop={props.loop} 
        loopAddBlankSlides 
        slidesPerView={count} 
        pagination={props.pagination} 
        mousewheel
        >
            {
                data.map((item, i) => {
                    return <SwiperSlide key={i}>
                        <GalleryItem
                            i={ i }
                            url={ item }
                            key={ i }
                            galleryId={ UUID.v4() }
                            gallery={ data }
                            width={ itemWidth }
                            height={ itemHeight }
                            to={ to }
                            padding={props.padding}
                        />
                    </SwiperSlide>    
                })
            } 
            { 
                (( data.length > count && start > 0 ) || props.loop) && props.navigation &&                
                <PrevButton onClick={onPrev} /> 
            }
            { 
                ( start < data.length - count  || props.loop ) && props.navigation &&  
                <NextButton onClick={onNext}/> 
            }
        </Swiper>
        <GalleryDialog
            i={ i }
            data={ data }
            setI={ to }
            isOpen={ isOpen }
            setIsOpen={ setIsOpen }
        />
    </div>
};