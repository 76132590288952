import { ID } from "@/libs/interfaces/layouts"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import { ICategory, ICritery, IMilestone } from "@/modules/pe-fest-module/data/interfaces"
import { EditableText } from "@blueprintjs/core"
import { useState } from "react"
import CriteryCategoryBtn from "./CriteryCategoryBtn"
import CriteryMilestoneBtn from "./CriteryMilestoneBtn"
import { ResourceContent } from "./DestrictCard"

/* Protopia Ecosystem component */
export interface ICriteryCardProps {
    item: ICritery 
    onDelete: ( id: ID ) => void
    onChange: ( field: string, value: any ) => void 
}
const CriteryCard = (props: ICriteryCardProps): JSX.Element => {
    const [isContentEdit, setIsContentEdit] = useState<boolean>( false ) 

    const onEditContent = (bool: boolean) => {
        setIsContentEdit(bool)
    }
    const onChangeTitle = (title: string) => {
        props.onChange("title", title)
    } 
    const onChangeContent = (content: string) => {
        props.onChange("content", content)
    } 
    const onChangeMilestone = ( milestone: IMilestone ) => {
        props.onChange("milestone", milestone?.id || "-1")
    }
    const onChangeCategory = ( category: ICategory ) => {
        props.onChange("category", category?.id || "-1")
    }

    return <div className={`pe-critery-edit-card-container`}> 
        <div className="d-flex flex-column flex-grow-100"> 
            <div className="title"> 
                <EditableText
                    className=" px-3 py-1 track-edit-title"
                    alwaysRenderInput={true} 
                    disabled={false}
                    placeholder="Edit title..."
                    selectAllOnFocus={true} 
                    value={props.item.title}
                    onChange={onChangeTitle}
                /> 
            </div>
            <ResourceContent
                content={props.item.content || ""}
                isContentEdit={isContentEdit}
                onChangeContent={onChangeContent}
                onEditContent={onEditContent}
            />            
            <div className="footer"> 
                <div className="position-relative flex-centered"> 
                    <div className="d-md-flex d-none small opacity_75 pl-2">{__("Milestone")}:</div>
                    <CriteryMilestoneBtn 
                        onChoose={ onChangeMilestone } 
                        milestone={ props.item.milestone }
                    />
                    <div className="d-md-flex d-none small opacity_75 pl-2">{__("Critery")}: </div>
                    <CriteryCategoryBtn 
                        onChoose={ onChangeCategory } 
                        category={ props.item.category }
                    />
                </div>
                <div className="position-relative flex-centered">
                    <ConfirmButton
                        buttonIcon="trash"
                        onConfirm={() => props.onDelete( props.item.id )}
                        buttonLabel={<div className="d-md-flex d-none ">{__("Delete")}</div>}
                        buttonMinimal
                    >
                        <div className="p-4">
                            {__("Really delete?")}
                        </div>
                    </ConfirmButton> 
                    <div className=" small bg-dark text-light m-1 py-1 px-2">
                    {
                        props.item.order
                    }
                    </div>   
                </div>
                
            </div>
        </div>        
    </div>
}
export default CriteryCard