import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { ICritery, IMilestone, IProject, IRating } from '@/modules/pe-fest-module/data/interfaces'
import { IFestProjectsProps, useFestProjects } from '@/modules/pe-fest-module/data/store'
import { useIsExpert } from '@/modules/pe-fest-module/hooks/project'
import CriteryRatingEngine from './CriteryRatingEngine'
import CriteryRatingView from './CriteryRatingView'

interface ICriteryRatingExpertDisplayProps {
    critery: ICritery 
}
const CriteryRatingExpertDisplay = (props: ICriteryRatingExpertDisplayProps):JSX.Element => {
  const project: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject) 


  const milestone:IMilestone  = useDataStore( (state:any) => state.PEFestMilestone )
    .filter((milestone : IMilestone) => milestone.id === props.critery.milestone?.id )[0]
  const isExpert: boolean     = useIsExpert( project, milestone, "critery rating display" )
  const ratings:IRating[] = project?.ratings?.filter(( rating:IRating ) => rating.criteryId === props.critery.id )
  
  const onOpen = (isOpen:boolean, isMax: boolean, data: any) => {

  }
  const onCheck = (data: any) => {

  }
  

  return !isExpert
    ?
    <CriteryRatingView ratings={ratings} milestone={milestone}/>
    :
    <CriteryRatingEngine 
      ratings={ratings}  
      critery={props.critery}
      onOpen={onOpen}
      onCheck={onCheck}
    />
}
 

export default CriteryRatingExpertDisplay


