import BasicState from "@/libs/basic-view"
import ChangeEmailForm from "./change-email"

class ChangeEmailView extends BasicState {
    props: any
    addRender = () => { 
        return <>
            <ChangeEmailForm {...this.props} />   
        </>
    }
}

export default ChangeEmailView 
