import { ID, Role } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { __, translitterate } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_SERVICE_OPTIONS_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { GET_FESTIVAL_SETTINGS_ACTION, SET_FESTIVAL_EXTENDS_ACTION } from "@/modules/pe-fest-module/data/actionTypes"
import { ADD_EVENT_MODE, ICategory, ICritery, IDestrict, IFestForm, IFestival, IGanre, IHoneycombs, ILand, IMilestone, IPEFestSchool, IProject, ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { Collapse } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { actions as festAction } from "../../../../data/actions"
import RatingSystemContent from "../RatingSystemContent"
import SettingContent from "../SettingContent"
import SourceContent from "../SourceContent"
import TrackEditCard from "../TrackEditCard"
import DestrictCard from "./DestrictCard" 
import ResourceContent from "./ResourceContent"
import { useFestExport } from "@/modules/pe-fest-module/data/store"
import SchoolCard from "./SchoolCard"
import ProjectCard from "./ProjectCard"
import MilestoneCard from "./MilestoneCard"
import JSZip from "jszip"
import landToJSON from "@/modules/pe-fest-module/data/utils/landToJSON"

/* Protopia Ecosystem component */
export interface IStewTabProps { }

export interface DataElement {
    id: ID
    title: string
    icon: string, 
    panel: () => JSX.Element
    isActive: () => boolean
}
export interface DataSource {
    [key: string] : DataElement
} 

const StewTab = (props: IStewTabProps): JSX.Element => { 
    const festival:IFestival    = useFestExport((state: any) => state.festival || {}) 
    const land:ILand            = useFestExport((state: any) => state.land || {}) 
    const [ sourceMode, setSourceMode ] = useState<ADD_EVENT_MODE>( ADD_EVENT_MODE.NOTHING ) 
    const [ n, setN] = useState<number>( 0 ) 
    const [ isLoading, setIsLoading ]   = useState<boolean>(false) 
    const [ roles, setRoles ]           = useState<Role[]>([]) 
    const [ isOpen, setIsOpen ]         = useState<keyof DataSource>("source")
    const [ item, setItem ]             = useState<IFestForm>({ title:"", domain:"" } as IFestForm) 
    
    
    useEffect(() => { 
        actions(GET_SERVICE_OPTIONS_ACTION, {})
            .then((resp: any) => { 
                setRoles([...resp.roles, "SuperAdmin"])
            })
        return () => { 
            useFestExport.setState({})
            useFestExport.setState({festival: {}, land: {}})
        }
    }, [])

    const onLand = ( land: ILand ) => {
        onSetLand(land)
        if(land?.id) {
            setIsLoading(true)
            Promise.all([
                festAction(SET_FESTIVAL_EXTENDS_ACTION, {id: land.id})
                    .then(( resp: any ) => {
                        console.log( resp )
                        Object.keys(resp).forEach(key => {
                            const __typename: string = Array.isArray(resp[key]) && !!resp[key].length
                                ?
                                resp[key][0].__typename
                                :
                                null
                            if(__typename){
                                onSetResource(__typename, resp[key])
                            }
                        })
                        console.log( useFestExport.getState() )
                    }),
                festAction(GET_FESTIVAL_SETTINGS_ACTION, {id: land.id})
                    .then(( resp ) => {
                        onSetFestival( resp )
                    })
            ])
            .finally(() => setIsLoading( false ))
        }
        else {

        }
    }
    
    const onSetFestival = (item: IFestival) => { 
        useFestExport.setState({festival: item})
    }
    
    const onSetLand = (item: ILand) => { 
        useFestExport.setState({land: item})
    }
 
    const onOpen = (field: keyof DataSource | "", i: number) => {
        setN(i)
        setIsOpen( isOpen === field ? "" : field )
    }
    const onSetResource = ( __typename: string, arr: any[]) => {
        useFestExport.setState({ [__typename]: arr })
    } 
    const onField = ( value: any, field: keyof IFestForm ) => {
        let d: any = {}
        if( field === "title" ) {
            const defDomain: string = translitterate()( item.title, "" ).toLowerCase() 
            if( defDomain === item.domain ) {
                d = { domain: translitterate()( value, "-" ).toLowerCase() }
            }
        }
        if(field === "finishDate" || field === "startDate") {
            value = Math.floor(value / 1000)
        } 
        const _item = { ...item, [field]: value, ...d }
        setItem(_item) 
        useFestExport.setState({item: _item})
    }
    const onDownload = () => {
        const json: any = useFestExport.getState()
        var zip = new JSZip()
        landToJSON( json ).then((res:any) => { 
            zip.file("newLand.json", res)
            zip.generateAsync({type:"base64"}).then(( base64 ) => {
                const href = "data:application/zip;base64," + base64 
                const cont = document.createElement("div")
                cont.className = "position-absolute "
                cont.style.top = "-1000px"
                cont.style.left = "-1000px"
                const element = document.createElement("a") 
                element.href = href
                element.download = "new-land-" + Date.now() + ".zip"

                // simulate link click
                document.body.appendChild(cont)
                cont.appendChild(element)
                element.click()
                cont.remove()
            })
        })
    }
    
    const data: DataSource = ({
        source: {
            id: "source",
            title: "Source",
            icon: "/assets/img/fest/source-icon.svg", 
            panel: () => <SourceContent 
                mode={sourceMode}
                onSetMode={setSourceMode}
                onLand={onLand}
            />,
            isActive: () => true
        },
        date: {
            id: "date",
            title: "Dates",
            icon: "/assets/img/fest/calendar-icon.svg", 
            panel: () => <div className="container h-100"> 
                <div className="row ">
                    <div className="col-md-6">
                        <FieldInput
                            value={item.startDate}
                            title={__("Start date")}
                            vertical={VerticalType.VERTICAL}
                            editable
                            type={SCALAR_TYPES.DATE}  
                            onChange={ value => onField( value * 1000, "startDate")}
                        />
                    </div>
                    <div className="col-md-6">
                        <FieldInput
                            value={item.finishDate}
                            title={__("Finish date")}
                            vertical={VerticalType.VERTICAL}
                            editable
                            type={SCALAR_TYPES.DATE}  
                            onChange={ value => onField( value * 1000, "finishDate")}
                        />
                    </div>
                </div>
            </div>,
            isActive: () => !!item.startDate || !!item.finishDate
        },
        setting: {
            id: "setting",
            title: "Settings",
            icon: "/assets/img/fest/settings-icon.svg", 
            panel: () => <SettingContent />,
            isActive: () => !!Object.keys( festival || {} ).length
        },
        tracks: {
            id: "tracks",
            title: "Tracks",
            icon: "/assets/img/fest/tracks-icon.svg", 
            panel: () => <ResourceContent 
                Component={ TrackEditCard } 
                dataTypeName={__("PEFestTrack")}
            />,
            isActive: () => !!(useFestExport.getState().PEFestTrack || []).length
        },
        destricts: {
            id: "destricts",
            title: "Destricts",
            icon: "/assets/img/fest/destrict-icon.svg", 
            panel: () => <ResourceContent 
                Component={ DestrictCard }   
                dataTypeName={__("PEFestDestrict")}
            />,
            isActive: () => !!(useFestExport.getState().PEFestDestrict || []).length
        },
        schools: {
            id: "schools",
            title: "Education organizations",
            icon: "/assets/img/fest/schools-icon.svg", 
            panel: () => <ResourceContent 
                Component={ SchoolCard } 
                dataTypeName={__("PEFestSchool")}
            />,
            isActive: () => !!(useFestExport.getState().PEFestSchool || []).length
        },
        projects: {
            id: "projects",
            title: "Projects",
            icon: "/assets/img/fest/projects-icon.svg", 
            panel: () => <ResourceContent 
                Component={ ProjectCard }  
                dataTypeName={__("PEFestProject")}
            />,
            isActive: () => !!(useFestExport.getState().PEFestProject || []).length
        },
        ratings: {
            id: "ratings",
            title: "Rating system",
            icon: "/assets/img/fest/rating-icon.svg", 
            panel: () => <RatingSystemContent />,
            isActive: () => !!(useFestExport.getState().PEFestCritery || []).length
        },
        cooperation: {
            id: "cooperation",
            title: "Cooperation",
            icon: "/assets/img/fest/cooperation-icon.svg", 
            panel: () => <div className="my-3">
                <div className="ml-5 my-2 d-flex">
                    <div className="pe-fest-resource-icon color-force-contrast" style={{backgroundImage:`url(/assets/img/fest/honeycombs-icon.svg)`}} /> 
                    <div className="lead">
                        {__("Honeycombs")}
                    </div>
                </div>
                <ResourceContent 
                    Component={ MilestoneCard }  
                    dataTypeName={__("PEFestHoneycombs")}
                />
            </div>,
            isActive: () => !!(useFestExport.getState().PEFestHoneycombs || []).length
        },
        ganres: {
            id: "ganres",
            title: "Ganres",
            icon: "/assets/img/fest/ganre-icon.svg", 
            panel: () => <ResourceContent 
                Component={ DestrictCard }  
                dataTypeName={__("PEFestGanre")}
            />,
            isActive: () => !!(useFestExport.getState().PEFestGanre || []).length
        } 
    }) 

    return <div className={`pe-stew-tab-container ${isLoading ? "opacity_5 untouchble" : "" }  p-md-3`}>  
        <div className=" px-md-4 d-flex flex-wrap">
            {
                Object.keys(data).map((key, i) => ( 
                    <div className="hint hint--top" data-hint={ __(data[key].title) } key={key}> 
                        <div 
                            className={`navi-btn  ${ n === i ? "active" : "" }  ${ data[key].isActive() ? "" : "opacity_5" }`}  
                            onClick={() => onOpen(key, i) }
                        >
                            <div className="icon" style={{backgroundImage:`url(${ data[key].icon })`}}>
        
                            </div>
                            <span className="navi-title">{__(data[key].title)}</span>
                        </div> 
                    </div>
                ))
            }
            <div className="hint hint--top" data-hint={ __("Download *.zip") } > 
                <div 
                    className={`navi-btn opacity_5 `}  
                    onClick={ onDownload }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/zip-file-icon.svg)`}}>

                    </div>
                    <span className="navi-title">{__("Download *.zip")}</span>
                </div> 
            </div>
        </div>
        <div className="my-3 display-6 text-center">
            { __(data[isOpen].title) } 
        </div> 
        {
            Object.keys(data).map((key, i) => ( 
                <Collapse isOpen={isOpen === key} className="w-100 " key={key}> 
                    { data[ key ].panel() } 
                </Collapse>
            ))
        } 
    </div>
}
export default StewTab