import React, { useEffect, useState } from 'react' 
import { Button, Icon } from '@blueprintjs/core'  
import { IFestStore, useFestStore } from '../data/store' 
import { DEFAULT_FILTERS } from '../data/interfaces'
import { __ } from '@/libs/utilities'
import useProjectListRoute from './hooks/useProjectListRoute'

const ClearFiltersForm = (): JSX.Element =>  
{ 
    const filters           = useFestStore((state: IFestStore) => state.filters)
    const setFilter         = useFestStore((state: IFestStore) => state.setFilter)
    const search: string    = useFestStore( (state: IFestStore) => state.search )
    const setSearch: (search: string) => void = useFestStore( (state: IFestStore) => state.setSearch )
    const [isOpen, setOpen] = useState(false ) 
    const onClose = () =>
    {
        setFilter(DEFAULT_FILTERS) 
        setSearch("")
    }
    useEffect(() => {
        setOpen( 
            filters.honeycombs[0] !== -1 || 
            filters.tracks[0] !== -1 || 
            filters.ganres[0] !== -1 ||
            filters.schools.length > 1 ||
            filters.destrict[0] !== -1 ||
            !!search
        )
    }, [filters, search])
    
    const isRoute: boolean = useProjectListRoute()
    return <div 
        className={` pe-fest-clear-filter-cont ${
            isOpen && isRoute 
                ? 
                ' open ' 
                : 
                ' closed '
        }`} 
        title={__("Clear filters")}
    >
        <Button minimal onClick={onClose} className="pe-fest-clear-filter-close-button">
            <Icon icon="cross" size={23} />
        </Button>
    </div> 
}

ClearFiltersForm.propTypes = {}

export default ClearFiltersForm