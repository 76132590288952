import { __ } from "@/libs/utilities"
import { Collapse } from "@blueprintjs/core"
import { IPEFestSchool } from "../../data/interfaces"

const getGuardAvatar = (
    item: IPEFestSchool, 
    isOpen: boolean = true, 
    random: number  = Math.random(), 
    guardian: "guardian" | "guardian2" = "guardian"
) => {  
    return item[guardian]?.id
      ?        
      <div className='d-flex align-items-center m-3 mt-auto pb-2'>
        <div className={ ` mr-3` } style={{ width: 50, minWidth: 50 }}>
          <div 
            className='my-avatar-cont'
            style={{
              backgroundImage: `url(${ item[guardian]?.avatar }?${ random })`,
              backgroundColor: '#555555',
              width:50,
              height: 50
            }}
          />
        </div> 
        <div>
          <div className=' lead text-secondary'>
            { __( "School_guardian" ) }
          </div>
          <div className='title'>
            { item[guardian].display_name }
          </div>
          <Collapse isOpen={ isOpen } className={`w-100`}>
            <div className='mt-1' dangerouslySetInnerHTML={{ __html: item[guardian]?.user_descr }} />
          </Collapse>            
        </div>
      </div> 
      :
      null
}

export default getGuardAvatar