import { ITrack } from "../../data/interfaces"

/* Protopia Ecosystem component */
export interface ITrackVKLinkProps {
   item: ITrack
}
const TrackVKLink = (props: ITrackVKLinkProps): JSX.Element => {
    const onClick = (evt:any) => {
        evt.stopPropagation()
    }
    return props.item.vk_url 
        ?
        <a 
            className='pe-track-vk-link soc vk' 
            href={props.item.vk_url} 
            target="_blank" 
            rel="noreferrer" 
            onClick={onClick}
        >
            <i className="fab fa-vk" />
        </a>
        :
        <></>
}
export default TrackVKLink