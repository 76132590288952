import UserAvatar from "@/libs/layout-app/header/login/UserAvatar"
import { __ } from "@/libs/utilities"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { Button, Card } from "@blueprintjs/core"
import actions from "../../data/actions"
import { LOGOUT_ACTION } from "../../data/actionTypes"

/* Protopia Ecosystem component */
export interface ILogoutFormProps {
   
}
const LogoutForm = (props: ILogoutFormProps): JSX.Element => {
    const user:IUser = useMainStore((state:IState) => state.user)
    const onLogout = () => {
        actions(LOGOUT_ACTION, {})
        //window.location.reload()
    }
    return <div className='layout-state-content '>
       <Card className="p-4 small-container flex-centered flex-column">
            <UserAvatar width={120} height={120} />
            <div className="my-2">
                {user.display_name}
            </div>
            <Button large onClick={onLogout}>
                {__("Log out")}
            </Button>
       </Card>
    </div>
}
export default LogoutForm