import { IUser } from "@/settings/zustand"
import { ITrack } from "./interfaces"

const defaultTracks = () : ITrack[] => {
    return [
        {
            id: -2,
            title: "Chemistry and Life",
            content: "Наука о веществах и законах превращений веществ; одна из отраслей естествознания. Важнейшими превращениями веществ, изучаемыми химией, являются химические реакции, обусловленные перестройкой электронных оболочек атомов, молекул, ионов, а также перегруппировкой атомных ядер в пространстве (состояние ядер при этом не меняется). Число химических объектов, воспроизводимых самой наукой, поистине безгранично. Это атомы, молекулы, супрамолекулы, материалы и т. д. ",
            illustration: "/assets/img/fest/track/chemistry.jpg",
            illustration_name: "chemistry.jpg",
            thumbnail: "/assets/img/fest/track/chemistry.svg",
            thumbnail_name: "chemistry.svg",
            illustration_id: "",
            order:-1,
            moderator: {} as IUser,
            moderator2: {} as IUser, 
            video_id: "",
            is_closed: false,
            post_content: "",
            count:0,
            vk_url: "",
            video_src: "vk"
        },
        {
            id: -3,
            title: "Physics is all around us",
            content: "В современном мире значение физики чрезвычайно велико. Всё то, чем отличается современное общество от общества прошлых веков, появилось в результате применения на практике физических открытий. Так, исследования в области электромагнетизма привели к появлению телефонов и позже мобильных телефонов, открытия в термодинамике позволили создать автомобиль, развитие электроники привело к появлению компьютеров. Развитие фотоники способно дать возможность создать принципиально новые — фотонные — компьютеры и другую фотонную технику, которые сменят существующую электронную технику. Развитие газодинамики привело к появлению самолётов и вертолётов.",
            illustration: "/assets/img/fest/track/phisics.jpg",
            illustration_name: "phisics.jpg",
            thumbnail: "/assets/img/fest/track/phisics.svg",
            thumbnail_name: "phisics.svg",
            illustration_id: "",
            order:-1,
            moderator: {} as IUser,
            moderator2: {} as IUser, 
            video_id: "",
            is_closed: false,
            post_content: "",
            count:0,
            vk_url: "",
            video_src: "vk"
        },
        {
            id: -4,
            title: "By parallels and meridians",
            content: "комплекс естественных и общественных наук, изучающих структуру, функционирование и эволюцию географической оболочки, взаимодействие и распределение в пространстве природных и природно-общественных геосистем и их компонентов[2]. География изучает поверхность Земли (см. науки о Земле), её природные условия, распределение на ней природных объектов (см. физическая география), населения, экономических ресурсов (см. экономическая география), это сфера территориального распространения чего-либо",
            illustration: "/assets/img/fest/track/geography.jpg",
            illustration_name: "geography.jpg",
            thumbnail: "/assets/img/fest/track/geography.svg",
            thumbnail_name: "geography.svg",
            illustration_id: "",
            order:-1,
            moderator: {} as IUser,
            moderator2: {} as IUser, 
            video_id: "",
            is_closed: false,
            post_content: "",
            count:0,
            vk_url: "",
            video_src: "vk"
        },
        {
            id: -5,
            title: "The theater begins with a hanger",
            content: "зрелищный вид искусства, представляющий собой синтез различных искусств (литературы, музыки, хореографии, вокала, изобразительного искусства и других) и обладающий собственной спецификой: отражение действительности, конфликтов, характеров, а также их трактовка и оценка, утверждение тех или иных идей здесь происходит посредством драматического действия, главным носителем которого является актёр",
            illustration: "/assets/img/fest/track/theatre.jpg",
            illustration_name: "theatre.jpg",
            thumbnail: "/assets/img/fest/track/theatre.svg",
            thumbnail_name: "theatre.svg",
            illustration_id: "",
            order:-1,
            moderator: {} as IUser,
            moderator2: {} as IUser, 
            video_id: "",
            is_closed: false,
            post_content: "",
            count:0,
            vk_url: "",
            video_src: "vk"
        },
        {
            id: -6,
            title: "Russian literature",
            content: "Необычная форма истории русской литературы стала источником многочисленных споров. Три крупных и внезапных разрыва делят ее на четыре периода — допетровский (или древнерусский), имперский, послереволюционный и постсоветский. Реформы Петра I (Великого; правил в 1682-1725 годах), который быстро вестернизировал страну, создали настолько резкий разрыв с прошлым, что в 19 веке было принято утверждать, что русская литература возникла всего столетие назад. Самый влиятельный критик 19 века, Виссарион Белинский, даже предложил точный год (1739), в котором зародилась русская литература, тем самым лишив статуса литературы все допетровские произведения.",
            illustration: "/assets/img/fest/track/literature.jpg",
            illustration_name: "literature.jpg",
            thumbnail: "/assets/img/fest/track/literature.svg",
            thumbnail_name: "literature.svg",
            illustration_id: "",
            order:-1,
            moderator: {} as IUser,
            moderator2: {} as IUser, 
            video_id: "",
            is_closed: false,
            post_content: "",
            count:0,
            vk_url: "",
            video_src: "vk",
        },
        {
            id: -7,
            title: "Всемирная история",
            content: "Процесс развития человеческого общества в целом, для которого характерны закономерности, проявляющиеся в истории всех народов. Всемирный исторический процесс начинается с появлением человеческого общества и в зависимости от общих отличительных особенностей условно делится на хронологические периоды.",
            illustration: "/assets/img/fest/track/history.jpg",
            illustration_name: "history.jpg",
            thumbnail: "/assets/img/fest/track/history.svg",
            thumbnail_name: "history.svg",
            illustration_id: "",
            order:-1,
            moderator: {} as IUser,
            moderator2: {} as IUser, 
            video_id: "",
            is_closed: false,
            post_content: "",
            count:0,
            vk_url: "",
            video_src: "vk",
        },
        {
            id: -8,
            title: "История России",
            content: "История России как страны отсчитывается с племён восточных славян, переселившихся на Восточно-Европейскую равнину в VI—VII веках[1] и впоследствии разделившихся на русских, украинцев и белорусов. История страны разделяется на несколько периодов: древнейший (догосударственный) период (до второй половины IX века н. э.), период Киевской Руси (до середины XII века или, по другой традиции, до середины XIII века), период раздробленности (до начала XVI века), период единого Русского государства (с 1547 года — царства) (конец XV века — 1721), период Российской империи (1721—1917), советский период (1917—1991) и новейшая история (с 1991)",
            illustration: "/assets/img/fest/track/rusHistory.jpg",
            illustration_name: "rusHistory.jpg",
            thumbnail: "/assets/img/fest/track/rusHistory.svg",
            thumbnail_name: "rusHistory.svg",
            illustration_id: "",
            order:-1,
            moderator: {} as IUser,
            moderator2: {} as IUser, 
            video_id: "",
            is_closed: false,
            post_content: "",
            count:0,
            vk_url: "",
            video_src: "vk",
        },
        {
            id: -9,
            title: "Спринт (лёгкая атлетика)",
            content: "Характерной особенностью спринта является функционирование организма в режиме креатин-фосфатного алактатного и анаэробного лактатного режимов энергопотребления. На коротких дистанциях кровь просто не успевает совершить цикл кровообращения. Время прохождения крови по большому кругу кровообращения составляет 26[источник не указан 3903 дня] секунд, причём ноги — это периферия кровеносной системы.",
            illustration: "/assets/img/fest/track/sprint.jpg",
            illustration_name: "sprint.jpg",
            thumbnail: "/assets/img/fest/track/sprint.svg",
            thumbnail_name: "sprint.svg",
            illustration_id: "",
            order:-1,
            moderator: {} as IUser,
            moderator2: {} as IUser, 
            video_id: "",
            is_closed: false,
            post_content: "",
            count:0,
            vk_url: "",
            video_src: "vk",
        },
        {
            id: -10,
            title: "Футбол",
            content: "Командный вид спорта, в котором целью является забить мяч в ворота соперника ногами или другими частями тела большее количество раз, чем команда соперника. В настоящее время самый популярный и массовый вид спорта в мире.",
            illustration: "/assets/img/fest/track/football.jpg",
            illustration_name: "football.jpg",
            thumbnail: "/assets/img/fest/track/football.svg",
            thumbnail_name: "football.svg",
            illustration_id: "",
            order:-1,
            moderator: {} as IUser,
            moderator2: {} as IUser, 
            video_id: "",
            is_closed: false,
            post_content: "",
            count:0,
            vk_url: "",
            video_src: "vk",
        },
        {
            id: -11,
            title: "Баскетбол",
            content: "Вид спорта, в котором две команды, каждая из которых состоит из пяти полевых игроков (замены не ограничены), играют с мячом в кольцо с сеткой (корзину) соперника и мешают другой команде завладеть мячом и забросить его в свою корзину. Корзина находится на высоте 3,05 м от паркета (10 футов)… ",
            illustration: "/assets/img/fest/track/basketball.jpg",
            illustration_name: "basketball.jpg",
            thumbnail: "/assets/img/fest/track/basketball.svg",
            thumbnail_name: "basketball.svg",
            illustration_id: "",
            order:-1,
            moderator: {} as IUser,
            moderator2: {} as IUser, 
            video_id: "",
            is_closed: false,
            post_content: "",
            count:0,
            vk_url: "",
            video_src: "vk",
        },
    ]
}
export default defaultTracks