import { ID } from '@/libs/interfaces/layouts'
import { __ } from '@/libs/utilities'
import { Button, ButtonGroup, Popover } from '@blueprintjs/core'
import { useState } from 'react'
import { attachTypes } from '../attaches/AttachTypes.entity'



interface IAttachFormProps {
    onInsertNew: (id: ID) => void
}
const AttachForm = (props: IAttachFormProps) :JSX.Element => {
    const [ current, setCurrent ] = useState( attachTypes()[ 0 ] )
    const onInsertNew = () =>
    { 
        props.onInsertNew( current.id )
    } 
    return (
        <div className='d-flex justify-content-center milestone-attach-cont'>
            <div className='d-flex flex-wrap'>
                <Button minimal className='text-dark opacity_5' onClick={onInsertNew}>
                    { __( "Insert new include by" ) } {__(current.title)}
                </Button>
                <Popover 
                    popoverClassName=' mx-md-0 mx-auto '
                    content={
                        <div className="square3 card bg-light overflow-y-auto-thin justify-content-start p-0">
                            <div className=' p-0'>
                            {
                                attachTypes().map(type => {
                                    return <Button 
                                        fill 
                                        minimal 
                                        className='py-3'
                                        onClick={ ( ) => setCurrent( type ) }
                                        key={type.id}
                                    >
                                        <small>
                                            {__(type.title)}
                                        </small>
                                    </Button>
                                })
                            }
                            </div>
                        </div>
                    }
                >
                    <Button className="pl-4 milestone-attach-change-btn text-dark" minimal>
                        {__("Change type")}
                    </Button>
                </Popover>
            </div>
        </div>
    )
}
 

export default AttachForm