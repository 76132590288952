import { Tag } from "@blueprintjs/core"
import { ICritery, IProject, IRating } from "../../data/interfaces"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { __ } from "@/libs/utilities"

interface IProps {
    name: string
    index: number
    project:IProject
    ratings: IRating[]
}
const ProjectFieldRow = (props : IProps) : JSX.Element => { 
    
    switch(props.name) {
        case "№":
            return  <div className="pe-table-cell title">{props.index + 1}</div>
        case "#":
            return  <div className="pe-table-cell">{props.project.order}</div>
        case "Track":
            return  <div className="pe-table-cell">{props.project.track?.title}</div>
        case "School":
            return  <div className="pe-table-cell">{props.project.fmru_school[0]?.title}</div>
        case "Project":
            return  <div className="pe-table-cell title">{props.project.title}</div>
        case "Rating":
            return  <Tag minimal round className="px-3 py-2 m-1">
                <div className="lead">
                    { Number( props.project.actualRating ).toFixed( 2 ) }
                </div>
            </Tag>
        case "List of ratings":
            return <div className="pe-table-cell-2">
                {
                    props.project.ratings.filter( r => r.criteryId)
                        .map(r => <RatingRow rating={r} key={r.id}/>)
                }
                
            </div>
        case "Tutor":
            return  <div className="pe-table-cell">{props.project.tutor?.display_name}</div>
        case "Project_author":
            return  <div className="pe-table-cell">{props.project.leader?.display_name}</div>
        case "Team":
            let team:JSX.Element[] =  Array(8).fill(0).map((__, i) => {
                return props.project["member" + (i + 1)]?.id
                ?
                <div className="my-1" key={i}>
                    { props.project["member" + (i + 1)].display_name }
                </div>
                :
                <></>
            })    
            return <div className="pe-table-cell small">{team}</div>
        case "leader":
            return  <div className="pe-table-cell">{props.project.leader?.display_name}</div>
        case "Exhortations":
            return  <div className="pe-table-cell ">{
                props.project.exhortations.map(ex => {
                    return <div className="pe-table-cell small py-2" key={ex.id}>
                        <div className="title">{ ex.descr }</div>
                        <div>{ ex.author.display_name }</div>
                    </div>
                })
            }</div>
        default:
            const milestoneName = props.project.milestone?.filter(m => m.milestone.title === props.name)  || []
            if( !!milestoneName.length ) {
                return <>
                {
                    milestoneName
                        .map(m => <div key={m.id} className="pe-table-cell">
                        {m.averageRating}
                        </div>)
                }
                </> 
            }
            //const criteryName = 
            return <></>
    } 
} 

export default ProjectFieldRow

interface IRatingRowProps {
    rating: IRating
}

const RatingRow = (props: IRatingRowProps): JSX.Element => {
    const criteries: ICritery[] = useDataStore((state:any) => state.PEFestCritery || []) 
    const critery: ICritery = criteries.filter(c => c.id === props.rating.criteryId )[0] || {}
    return <div className="strob03 py-2 px-1 small">
        <div className="pe-table-cell-2 pb-1">
            <span className="mr-2 opacity_5">
                {__("Critery")}:
            </span>
            <span className="text-secondary"> 
                { critery.title }
            </span>
            {
                critery.milestone && <span className="mx-1 text-secondary">
                    ({__("Milestone")} : <span className="title">{critery.milestone.title}</span>)
                </span>
            }
            {
                critery.category && <span className="mx-1 text-secondary">
                    ({__("Category")} : <span className="title">{critery.category.title}</span>)
                </span>
            }
        </div>
        <div className="pe-table-cell-2 pb-1">
            <span className="mr-2 opacity_5">
                {__("Expert")}:
            </span>
            <span className=" ">
                {props.rating.display_name}
            </span>
        </div>
        <div className="pe-table-cell-2 pb-1">            
            <span className="mr-2 opacity_5">
                {__("Commentary")}:
            </span>
            <span className=" ">
                «{props.rating.description}»
            </span>
        </div>            
        <div className="pe-table-cell-2 pb-1">
            <span className="mr-2 opacity_5">
                {__("Rating")}:
            </span>
            <span className="title">
                {props.rating.rating}
            </span>
        </div> 
    </div>
}