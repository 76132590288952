import { __ } from "@/libs/utilities";
import { Button, Icon } from "@blueprintjs/core";
import { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { IPEFestSchool } from "../../data/interfaces";
import { useDataStore } from "@/modules/pe-basic-module/data/store";

/* Protopia Ecosystem component */
export interface ISchoolsFormProps {
   
}
const SchoolsForm = (props: ISchoolsFormProps): JSX.Element => {
    const schools: IPEFestSchool[] = useDataStore((state:any) => state.PEFestSchool)

    const tableRef = useRef(null);

    const printIt = () =>
        {
            const restorePage = $('body').html(); 
            $( "fest-header" ).hide()
            $( "fest-phase-labels" ).hide()
            $( "fest-head-switcher" ).hide()
            const printPage = $('body').html()
            $('body').empty().html( printPage )
            window.print();
            $('body').html(restorePage); 
        }
    return <div className='pe-schoolsform-container w-100'>
        <div className='w-100'>
            <div className='mt-1 mb-3 w-100'>
                <div className='align-items-center w-100 d-flex justify-content-md-between justify-content-center flex-md-row flex-column'>
                <div className='flex-centered'>
                        <div className=' display-6 '>
                            { schools.length }
                        </div>
                        <Button minimal className='px-3 py-2 ml-3' onClick={printIt}>
                            <Icon icon="print" size={21} className="hint hint--top" data-hint={__("Print")} />
                        </Button> 
                        <DownloadTableExcel
                            filename="schools"
                            sheet="schools"
                            currentTableRef={ tableRef.current }
                        >
                            <Button minimal className='px-3 py-2 '>
                                <Icon 
                                    icon="th" 
                                    size={21} 
                                    className="hint hint--top pointer" 
                                    data-hint={__("Download XLS")}
                                />
                            </Button>                            
                        </DownloadTableExcel> 
                    </div>
                </div>
            </div>
        </div>
        <table 
            className='table table-striped table-bordered2 table-hover1 pe-fest-table-statistics w-100' 
            id="members-to-xls"
            ref={ tableRef }
        >
        `   <thead className="thead-dark-33 bg-secondary text-white">    
                <tr>
                    <th scope="col" style={{ width: 55 }} >
                        {__("#")}
                    </th>
                    <th scope="col" >
                        {__("School")}
                    </th>
                    <th scope="expert">
                        {__("Guardian")}
                    </th>
                    <th scope="col"  >
                        {__("Guardian 2")}
                    </th> 
                    <th scope="col"  style={{ width: 80 }}  >
                        {__("Projects count")}
                    </th> 
                </tr>                
            </thead >
            <tbody>
                {
                    schools.map((school, i) => <tr key={school.id}>
                        <td>{i + 1}</td>
                        <td>
                            {
                                school.title
                            }
                        </td>
                        <td>
                            {
                                school.guardian?.display_name
                            }
                        </td>
                        <td>
                            {
                                school.guardian2?.display_name
                            }
                        </td>
                        <td>
                            {
                                school.count
                            }
                        </td>
                    </tr>)
                }
            </tbody>`
        </table>
    </div>
}
export default SchoolsForm