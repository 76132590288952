import Feed from "@/modules/pe-basic-module/views/core/Feed"
import { __, setMaxHeightByClass } from "@/libs/utilities"
import { useEffect, useState } from "react"
import FestivalButton from "./FestivalButton"
import { clearFestival } from "../../hooks/festival" 
import switchToBlog from "@/modules/pe-basic-module/hooks/switchToBlog"
import FestivalFilter from "./FestivalFilter"
import { useSearchParams } from "react-router-dom"
import { IState, IUser, useMainStore } from "@/settings/zustand"  
import { land_group_id } from "@/libs/layouts"

const getLandsPaging = () => { 
    if( !!land_group_id() ) {
        return ` meta_relation: "AND"  metas: [ { key: "land_group_id", value: "${ land_group_id() }" },  { key: "hidden", value_bool: false }, { key: "archived", value_bool: false } ] `
    }
    return ` meta_relation: "AND"  metas: [ { key: "hidden", value_bool: false }, { key: "archived", value_bool: false } ] `
}

/* Protopia Ecosystem component */
export interface IFestivalListProps {

}
const FestivalList = (props: IFestivalListProps): JSX.Element => { 
    const [paging, setPaging] = useState<string>( getLandsPaging() )
    const [searchParams, setSearchParams] = useSearchParams()
    const user:IUser = useMainStore((state:IState) =>state.user)
    useEffect(() => { 
        setMaxHeightByClass( 'card-title' )
        setMaxHeightByClass( 'card-text' )
        clearFestival()
        switchToBlog( "-1" )
    }, []) 
    useEffect(() => {
        if( searchParams.get("filter") === "all-current" ) {
            const pagination = ` meta_relation: "OR" metas: [ { key:"viewRoles", value: ["${ user.roles.join('", ') }"], compare: "IN" } ] `
            console.log( pagination )
            setPaging( pagination )
            // setPaging(` relation: "AND" metas: [  { key: "hidden", value_bool: false }, { key:"status", value_numeric: 0, compare: ">" }, { key:"status", value_numeric:3, compare: "<" } ] `)
        }
        else if( searchParams.get("filter") === "all-before" ) {
            setPaging(` meta_relation: "AND" metas: [ { key: "hidden", value_bool: false }, { key:"status", value: "0" } ] `)
        }
        else if( searchParams.get("filter") === "all-finished" ) {
            setPaging(` meta_relation: "AND" metas: [ { key: "hidden", value_bool: false }, { key:"status", value: "3" } ] `)
        }
        else {
            setPaging( getLandsPaging() )
        }
    }, [ searchParams ]) 

    return <div className=' container h-100 flex-centered flex-column '>
        <h3 className="pt-4">
            {__("Choose Festival")}
        </h3>
        {/* <FestivalFilter /> */}
        <Feed 
            data_type="Land"
            offset={0} 
            class_name='row'
            containerClassName="container"  
            component={ FestivalButton } 
            paging={paging}
            onLoading = {( ) => {
                setMaxHeightByClass( 'card-title' )
                setMaxHeightByClass( 'card-text' ) 
            }}
            topPagiHide
            params={{
                col:3
            }}
        /> 
    </div> 
}
export default FestivalList