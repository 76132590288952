import { __ } from "@/libs/utilities"
import CategoryCard from "./add-festival-tabs/CategoryCard"
import CriteryCard from "./add-festival-tabs/CriteryCard"
import MilestoneCard from "./add-festival-tabs/MilestoneCard"
import ResourceContent from "./add-festival-tabs/ResourceContent"

/* Protopia Ecosystem component */
export interface IRatingSystemContentProps {

}
const RatingSystemContent = ( props: IRatingSystemContentProps): JSX.Element => {
    return <div className='pe-rating-system-content-container'>
        <div className="my-3">
            <div className="ml-5 my-2 d-flex">
                <div className="pe-fest-resource-icon color-force-contrast" style={{backgroundImage:`url(/assets/img/fest/milestone-icon.svg)`}} /> 
                <div className="lead">
                    {__("Milestones")}
                </div>
            </div>
            <ResourceContent 
                Component={ MilestoneCard }
                dataTypeName={__("PEFestMilestone")}
            />
        </div>
        <div className="my-3">
            <div className="ml-5 my-2 d-flex">
                <div className="pe-fest-resource-icon color-force-contrast" style={{backgroundImage:`url(/assets/img/fest/category-icon.svg)`}} /> 
                <div className="lead">
                    {__("Categories")}
                </div>
            </div>
            <ResourceContent 
                Component={ CategoryCard }
                dataTypeName={__("PEFestCategory")}
            />
        </div>
        <div className="my-3">
            <div className="ml-5 my-2 d-flex">
                <div className="pe-fest-resource-icon color-force-contrast" style={{backgroundImage:`url(/assets/img/fest/critery-icon.svg)`}} /> 
                <div className="lead">
                    {__("Criteries")}
                </div>
            </div>
            <ResourceContent 
                Component={ CriteryCard } 
                dataTypeName={__("PEFestCritery")}
            />
        </div>
    </div>
}
export default RatingSystemContent