import { DIALOG_SIZE, IPossessions, IPost, Merge } from "@/libs/interfaces/layouts"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import UserSearchEngine from "@/libs/useful/UserSearchEngine"
import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { useEffect, useState } from "react"

/* Protopia Ecosystem component */
export interface ISchoolGuardianChooseWidgetProps {
   item: IUser 
   parent: Merge<IPossessions, IPost>
   thread: string
}
const SchoolGuardianChooseWidget = ({...props}: ISchoolGuardianChooseWidgetProps): JSX.Element => {
    const landId            = useMainStore((state:IState) => state.landId )
    const [item, setItem]   = useState<IUser>(props.item)
    const [user, setUser]   = useState<IUser>(props.item)

    useEffect(() => {
        setItem( props.item )
        setUser( props.item )
    }, [ props.parent ])
    const onSelect = (user: IUser) => {
        setUser(user)
    }
    const onSetGuardian = () => {
        actions(UPDATE_SINGLE_TYPE, {landId, data_type: props.parent.__typename, item: { [props.thread]:  user.id }, id: props.parent.id })
        setItem( user )
    }
    return <>
        <ConfirmButton
            buttonLabel={ <div className="small text-center">
                <i className="fas fa-user mr-1 opacity_5 small title" />
                { item?.display_name || <span className="opacity_5">--</span> }
            </div> }
            buttonMinimal
            buttonFill
            dialogClasssName={DIALOG_SIZE.MINIMAL}
            dialogTitle={__("Appointment to the position")}
            onConfirm={onSetGuardian}
        >
            <div className="p-5">
                <UserSearchEngine 
                    user={ user }
                    landId={landId} 
                    onSelect={onSelect}
                />
            </div> 
        </ConfirmButton>
    </>
}
export default SchoolGuardianChooseWidget