import { ICategory, ICritery, IRating } from '@/modules/pe-fest-module/data/interfaces'
import { IFestDataStor, useFestDataStore, useFestProjects } from '@/modules/pe-fest-module/data/store'
import { Slider } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react' 
import CriteryCommentaryDialog from '../projectData/CriteryCommentaryDialog'
import { actions } from '@/modules/pe-fest-module/data/actions'
import { UDATE_PROJECT_RATING_ACTION } from '@/modules/pe-fest-module/data/actionTypes' 
import { distributeCurrentRating, getCatUserRatings, updateCurrentRating } from '@/modules/pe-fest-module/data/utils/updateCurrentRating'
import { useParams } from 'react-router'

interface IProps { 
    critery: ICritery
    maxRating: number
    rating: IRating
}
const ExpertUnitedSlider = (props: IProps): JSX.Element => {
  
  const {landId} = useParams()   
  const isComment: boolean = useFestDataStore( (state:IFestDataStor) => state.isComment ) 
  const setIsBlockedUpdate: Function = useFestProjects.getState().setIsBlockedUpdate 
  const category: ICategory = props.critery.category

  const [rate, changeRate] = useState<number>( props.rating?.rating )
  const [isOpen, setIsOpen] = useState( false )
  const [description, setDescription] = useState( props.rating?.description || "" )
  useEffect(() => {
    changeRate( props.rating?.rating || 0 )
    // if( props.rating?.description )
    //   setDescription( props.rating?.description )
  }, [ props.rating ])

  const changeRating = (value: number) => {
    // console.log(value)
    let rating:IRating = props.rating 
    changeRate(value)
    const maxRating : number = Math.min( props.maxRating, props.critery.max_rating, category?.max_rating )
    updateCurrentRating( value, rating ) 
    distributeCurrentRating(value, rating,  category, maxRating)    
  }
  
  const onOnlyOpen = () => {
    setIsOpen(true)
    setIsBlockedUpdate(true)
  }
  const releaseRating = (rating: number) => {
    //console.log( rating )
    if(props.maxRating === rate)
    {
      onOnlyOpen()
    }
    changeRate(rating) 
    if( isComment ) 
    {
      onOnlyOpen()
    }            
    else 
    {  
      sending(description) 
    } 
  }
  
  const onClose = () => {
    setIsOpen(false)
    setIsBlockedUpdate( false )
  }

  const onSend = (descr:string) => { 
    setDescription( descr ) 
    sending( descr )
  }
  const sending = (description: string) => { 
    const allRatings : IRating[] = getCatUserRatings( category )
    //console.log( allRatings, description )
    allRatings.forEach(( rating: IRating ) => {
      // console.log( { ...rating, description } )
      actions( UDATE_PROJECT_RATING_ACTION,  { id: rating.id, item: { ...rating, description }, landId} )
    })
}

  const stepSize: number = Math.floor( props.maxRating / 100 < 1 ? 1 : props.maxRating / 100 )
  return <div className="mr-4"> 
  <Slider
    className="united-slider"
    min={ 0 }
    max={ props.maxRating }
    stepSize={ stepSize }
    labelStepSize={ stepSize > 10 ? stepSize * 10 : 1 }
    onChange={changeRating }
    onRelease={releaseRating }
    value={ Number(rate) || 0 } 
    vertical={ false }
  />
  <CriteryCommentaryDialog
    description={ description }
    isOpen={ isOpen }
    onOpen={ onClose }
    lockWithoutComment={ props.maxRating === rate }
    onSend={onSend}
    sendLabel={"send label"}
    isHideChangeDescr={false}
  />
</div>
}

export default ExpertUnitedSlider