import { DIALOG_SIZE, ID, Role } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __, translitterate } from "@/libs/utilities"
import { component_url } from "@/libs/utilities/links"
import { CREATE_SITE_ACTION, GET_FEED_ACTION, GET_SERVICE_OPTIONS_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_FESTIVAL_SETTINGS_ACTION } from "@/modules/pe-fest-module/data/actionTypes"
import { IFestForm, IFestival, ILand } from "@/modules/pe-fest-module/data/interfaces"
import { Card, Collapse, Intent } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { actions as festAction } from "../../../data/actions"
import StewTab from "./add-festival-tabs/StewTab"
import { useFestExport } from "@/modules/pe-fest-module/data/store"

/* Protopia Ecosystem component */
export interface IAddFestivalContentProps {
    festivalSite?: ILand 
}
const AddFestivalContent = (props: IAddFestivalContentProps): JSX.Element => {
    const navigate = useNavigate()
    const [ roles, setRoles ] = useState<Role[]>([])
    const [ festival, setFestival] = useState<IFestival>({} as IFestival)   
    const [ item, setItem ] = useState<IFestForm>({ title:"", domain:"" } as IFestForm)  

    const changeFestival = ( ste_id: ID) => {
        festAction(
            GET_FESTIVAL_SETTINGS_ACTION, 
            { id: ste_id }
        )
        .then((resp: IFestival) => { 
            setFestival(resp) 
            useFestExport.setState({festival: resp})
        })
    }
    useEffect(() => {
        actions(
            GET_FEED_ACTION, 
            { 
                data_type:"PEFestTrack", 
                land_id: festival.id 
            }
        )
        actions(
            GET_FEED_ACTION, 
            { 
                data_type:"PEFestProject", 
                land_id: festival.id 
            }
        )
        actions(
            GET_FEED_ACTION, 
            { 
                data_type:"User", 
                land_id: festival.id 
            }
        )
    }, [festival]) 
    
    useEffect(() => { 
        actions(GET_SERVICE_OPTIONS_ACTION, {})
            .then((resp: any) => { 
                setRoles([...resp.roles, "SuperAdmin"])
            })
    }, [])

    //if(!isRole(roles)) return <></>
   
    const onField = ( value: any, field: keyof IFestForm ) => {
        let d: any = {}
        if( field === "title" ) {
            const defDomain: string = translitterate()( item.title, "" ).toLowerCase() 
            if( defDomain === item.domain ) {
                d = { domain: translitterate()( value, "-" ).toLowerCase() }
            }
        }
        if(field === "finishDate" || field === "startDate") {
            value = Math.floor(value / 1000)
        }
        if(field === "pattern") {
            changeFestival( value?.id )
        }
        const _item = { ...item, [field]: value, ...d }
        setItem( _item )
        useFestExport.setState({item: _item})
    }
    const onStartCreate = () => {
        actions(
            CREATE_SITE_ACTION, 
            { 
                item: {
                    ...item, 
                    startDate: item.startDate + "", 
                    finishDate: item.finishDate + ""
                } 
            } 
        )
        .then( () => navigate(component_url("FestivalAdminListView")) )
    }


    return <div className='pe-add-festival-content-container short-container'>
        <Card className="row w-100 p-0">
            <FieldInput
                value={item.title}
                vertical={VerticalType.HORIZONTAL}
                editable
                type={SCALAR_TYPES.STRING}
                title={__("Title")}
                onChange={ value => onField( value, "title")}
            />
            <div className="d-none">
                <FieldInput
                    value={item.domain}
                    vertical={VerticalType.HORIZONTAL}
                    editable
                    type={SCALAR_TYPES.STRING}
                    title={__("New Site | web address")}
                    commentary={__("Only lowercase letters (a-z) and numbers are allowed.")}
                    onChange={ value => onField( value, "domain")}
                /> 
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <StewTab />
                        {/* <AddFestivalTabs
                            festival={festival}
                            item={item}
                            on={ value => onField( value, "pattern") }
                        /> */}
                    </div>
                </div>
            </div>
            
            {/* <FieldInput
                value={item.pattern}
                vertical={VerticalType.HORIZONTAL}
                editable
                component="Land"
                paging={` metas: {key: "isPattern", value: "1" } `}
                type={SCALAR_TYPES.EXTERNAL}
                title={__("Pattern")}
                onChange={ value => onField( value, "pattern")}
            />
            <Collapse isOpen={!!festival.domain_content} className="w-100">
                <div className="pb-4 px-3 text-center w-100 descr text-force-contrast ">
                    {
                       festival.domain_content
                    }
                </div>
            </Collapse> */}
        </Card>
        <Collapse isOpen={ !!item.title && !!item.domain && !!item.pattern }>
            
        </Collapse>
        <div className="flex-centered w-100 m-4">
            <ConfirmButton
                buttonIntent={ !item.title && !item.domain ? Intent.NONE : Intent.DANGER}
                dialogClasssName={ DIALOG_SIZE.MINIMAL }
                buttonDisabled={ !item.title || !item.domain }
                buttonMinimal={  !( item.title &&  item.domain && item.pattern ) }
                buttonLabel={ __( !item.title && !item.domain ? "Insert title" : "Create new Festival" ) }
                buttonLarge
                onConfirm={onStartCreate}
            >
                <div className="p-5  flex-centered flex-column">
                    <div className="py-2 w-100 d-flex">
                        <div className="w-100 pr-3 text-right">
                            {__("Title")}:
                        </div>
                        <div className="title w-100">
                            { item.title }
                        </div>
                    </div> 
                    <div className="w-100 lead text-center">
                        { __( "Create new Festival?" ) }
                    </div> 
                </div>
            </ConfirmButton> 
        </div>
    </div>
}
export default AddFestivalContent