
import { PETabs } from "@/libs/useful"
import LoginForm from "./LoginForm"
import RegisterForm from "./RegisterForm" 
import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */
export interface ILoginRegisterMobileProps {
    extend_params?: any
}
const LoginRegisterMobile = (props: ILoginRegisterMobileProps): JSX.Element => {
    return <div className='pe-loginregistermobile-container'>
        <PETabs
            tabs={[
                {
                    id: "register",
                    title: __("Create account"),
                    element: <RegisterForm {...props} />
                },
                {
                    id: "login",
                    title: __("Sign in"),
                    element: <LoginForm {...props} />
                },
            ]}
            tabId="login"
        />
    </div>
}
export default LoginRegisterMobile