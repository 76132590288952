import { IMilestone, IProjectMilestone, IRating } from "@/modules/pe-fest-module/data/interfaces"
import { IFestProjectsProps, useFestProjects } from "@/modules/pe-fest-module/data/store"

export interface ICriteryRatingProps {
    ratings: IRating[]
    milestone?:IMilestone
}
const CriteryRatingView = (props:ICriteryRatingProps): JSX.Element => {
    const currentProjectMilestones:IProjectMilestone[] = useFestProjects((state:IFestProjectsProps) => state.currentProjectMilestones)
    let rating: number = 0
    props.ratings.forEach(( cur:IRating ) => {
        rating += cur.rating
    })
    let count: number = 1
    if(props.milestone) {
        const projectMilestone = currentProjectMilestones.filter(c =>c.id === props.milestone?.id)[0]
        count = projectMilestone
            ?
            projectMilestone.experts.length
            :
            props.ratings.filter(r => !!r.rating ).length || 1
    }
    else {
        count = props.ratings.filter(r => !!r.rating ).length || 1
    }
    if(!count) {
        count = 1
    } 

    return <div className="footer-widget">
        <div className="lead">
            {(( rating || 0 ) / count ).toFixed(2)}
        </div>
        <div>
            
        </div>
    </div>	
}

export default CriteryRatingView