import { jsonToData } from "@/libs/utilities"
import { useEffect, useState } from "react"
import LogTime from "./LogTime"
import LogError from "./LogError"
import LogData from "./LogData"
import LogUser from "./LogUser"
import { Button, Collapse } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface ILogSingleProps {
   text: string
}
const LogSingle = (props: ILogSingleProps): JSX.Element => {
    const [full, setFull] = useState<string[]>([])
    const [data, setData] = useState<string>()
    const [err, setErr] = useState<string>()
    const [user, setUser] = useState<string>()
    const [date, setDate] = useState<string>( "") 
    const [isOpen, setIsOpen] = useState<boolean>( false ) 
    useEffect(() => {
        let full = props.text.split("\r\n")
        if(!full[0]) {
            full.shift()
        }
        setFull( full )
        setDate( ( full[ 0 ] ) )
        setErr( jsonToData( full[ 1 ] ) )
        setData( jsonToData( full[ 2 ] ) )
        setUser( jsonToData( full[ 3 ] ) )

    }, [])

    if(!full) return <></>

    return <div className='pe-log-single-container my-0 pb-3 border-top border-secondary'> 
        <div className="mb-2 d-flex w-100">
            <LogTime date={date} />
        </div>
        <LogError err={err} />
        <LogData data={data} />
        <LogUser user={user} />
        <Collapse isOpen={isOpen}>
            <div className="small opacity_5 my-1 d-none1" dangerouslySetInnerHTML={{ __html: props.text.replace(/\r\n/gi, "<br>") }} />
        </Collapse>
        <Button minimal icon={isOpen ? "caret-up" : "caret-down"} onClick={() => setIsOpen(!isOpen)} />
        
    </div>
}
export default LogSingle
 