import { IMenuItem } from "@/libs/interfaces/layouts"
import MenuItemBtn from "./MenuItemBtn"
import {default as UUID} from "node-uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";  
import { changeRoutings } from "../../data/layoutsClone/updateRoutings";
import { Button } from "@blueprintjs/core";
import { moduleExists } from "@/libs/layouts";

interface IProps {
    current : string
    items: IMenuItem[]
    onVisibleChange: ( isHidden: boolean, item: IMenuItem ) => void
} 
const CurrentMenuEngine = (props: IProps) : JSX.Element => { 
    const [items, setItems] = useState<IMenuItem[]>( props.items )
    useEffect(() => {
        setItems( props.items ) 
    }, [ props.items ])  

    // a little function to help us with reordering the result
    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed); 
        return result;
    }; 

    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({ 
        userSelect: "none",
        padding: 0, 
        margin: `0 0 0 0`, 
        width: "100%",
        // change background colour if dragging
        background: isDragging ? "#45526d" : "transparent", 
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? "#293742" : "transparent",
        padding: 0, 
    })
    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        } 
        const _items: any[]= reorder(
            items,
            result.source.index,
            result.destination.index
        ); 
        setItems(_items) 
        changeRoutings( _items, props.current )
    }

    return  <DragDropContext onDragEnd={ onDragEnd }>
        <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    { items
                        .filter( item => moduleExists(item.module) )
                        .map((item, index) => (
                        <div key={item.id} className="d-flex"> 
                            <Draggable draggableId={item.id.toString()} index={index}>
                                {(provided, snapshot) => ( 
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <MenuItemBtn  
                                            item={{...item, id: item.id || UUID.v4()}} 
                                            level={0} 
                                            parentRoute={ "" }  //window.location.origin
                                            i={index}  
                                            isMayVisible
                                            isNavigate
                                            onVisibleChange={(isHidden: boolean, item: IMenuItem ) => {
                                                console.log( isHidden )
                                                props.onVisibleChange( isHidden, item )
                                            
                                            }}
                                        >
                                            <Button minimal className=" ">
                                                <i className="fas fa-ellipsis-v" />
                                            </Button>
                                        </MenuItemBtn>
                                    </div>
                                )}
                            </Draggable>
                        </div>
                    ))}
                    { provided.placeholder }
                </div>
            )}
        </Droppable>
    </DragDropContext>
}
export default CurrentMenuEngine