import { Icon } from "@blueprintjs/core"
import { IFestival } from "../../data/interfaces"
import { IFestDataStor, useFestDataStore } from "../../data/store"
import NextButton from "@/libs/useful/gallery/NextButton"
import PrevButton from "@/libs/useful/gallery/PrevButton"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { useState } from "react"

const {  Navigation, Pagination, Scrollbar, A11y } =  require("swiper/modules")  
const {   Swiper, SwiperSlide } =  require("swiper/react") 

/* Protopia Ecosystem component */
export interface IContactFormMobileProps {
   
}
const ContactFormMobile = (props: IContactFormMobileProps): JSX.Element => {
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const [ start, setStart ] = useState( 0 )

    const onPrev = () => {
        setStart( start - 1 )
    }
    const onNext = () => {
        setStart( start + 1 )
    }

    const slides = []
    const classNames= " px-4 contact-slide-mobile"
    if( festival.link1 || festival.link2 )
    { 
        const links = <div className={classNames}>
            <Icon icon={<i className="fas fa-link fa-2x mt-1 mx-2" />}  color="#FFF"/>
            {
            festival.link1
                ?
                <a href={ festival.link1} target="_blank" rel="noreferrer" className="text-light mt-2 mx-2 hover">{ festival.link1 }</a>
                :
                null
            }
            {
            festival.link2
                ?
                <a href={ festival.link2} target="_blank" rel="noreferrer" className="text-light mt-2 mx-2 hover">{festival.link2}</a>
                :
                null
            }
        </div>
        slides.push( <SwiperSlide key="links"> 
            {links}                      
        </SwiperSlide>)
    }
    if( festival.vk || festival.vk2 )
    {
        const vk = <div className={classNames}>
            <Icon icon={<i className="fab fa-vk fa-2x mt-1 mx-2 "/>} color="#FFF"/>
            {
            festival.vk
                ?
                <a href={festival.vk} target="_blank" rel="noreferrer" className="text-light mt-2 mx-2 hover">{ festival.vk }</a>
                :
                null
            }
            {
            festival.vk2
                ?
                <a href={ festival.vk2 } target="_blank" rel="noreferrer" className="text-light mt-2 mx-2 hover">{ festival.vk2 }</a>
                :
                null
            }
        </div>
        slides.push( <SwiperSlide key="vk"> 
            {vk}                      
        </SwiperSlide>)
    }
    if( festival.telegramm || festival.telegramm2 )
    {
        const telegram = <div className={classNames}>
            <Icon icon={<i className="fab fa-telegram-plane fa-2x mt-1 mx-2" />}  color="#FFF"/>
            {
            festival.telegramm
                ?
                <a href={ festival.telegramm } target="_blank" rel="noreferrer" className="text-light mt-2 mx-2 hover">{ festival.telegramm }</a>
                :
                null
            }
            {
            festival.telegramm2
                ?
                <a href={ festival.telegramm2 } target="_blank" rel="noreferrer" className="text-light mt-2 mx-2 hover">{ festival.telegramm2 }</a>
                :
                null
            }
        </div>
        
        slides.push( <SwiperSlide key="telegram"> 
            {telegram}                      
        </SwiperSlide>)
    }
    if( festival.email || festival.email2 )
    {
        const email = <div className={classNames}>
            <Icon icon={<i className="fas fa-envelope fa-2x mt-1 mx-2"/>} color="#FFF"/>
            {
            festival.email
                ?
                <span className="text-light mt-2 mx-2 hover">{ festival.email }</span>
                :
                null
            }
            {
            festival.email2
                ?
                <span className="text-light mt-2 mx-2 hover">{ festival.email2 }</span>
                :
                null
            }
        </div> 
        slides.push( <SwiperSlide key="email"> 
            {email}                      
        </SwiperSlide>)
    }
    if( festival.phone || festival.phone2 )
    {
        const phone = <div className="d-flex px-4 align-items-center">
            <Icon icon={<i className="fas fa-phone fa-2x mt-1 mx-2" />}  color="#FFF"/>
            {
            festival.phone
                ?
                <span className="text-light mt-2 mx-2 hover">{ festival.phone }</span>
                :
                null
            }
            {
            festival.phone2
                ?
                <span className="text-light mt-2 mx-2 hover">{ festival.phone2 }</span>
                :
                null
            }
        </div>        
        slides.push( <SwiperSlide key="phone"> 
            {phone}                      
        </SwiperSlide>)
    }
    return !!slides.length 
        ? 
        <Swiper
            // install Swiper modules
            modules={[ Navigation, Pagination, Scrollbar, A11y ]}
            spaceBetween={ 0 }  
            loop={true} 
            loopAddBlankSlides 
            slidesPerView={ 1 }  
            mousewheel
        >
            {slides}
            <PrevButton onClick={onPrev} />
            <NextButton onClick={onNext} />  
        </Swiper>
        :
        <></>
}
export default ContactFormMobile