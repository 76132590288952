import { ID, IDataType } from "@/libs/interfaces/layouts"
import { schema } from "@/libs/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { useEffect, useState } from "react"

/* Protopia Ecosystem component */
export interface IFieldInputGroupProps {
    landId: ID | undefined
    fields : IDataType[] | undefined
    data_type?: string | undefined
    item: any
    onChange: ( item: any ) => void
}
const FieldInputGroup = ({ landId, ...props } : IFieldInputGroupProps): JSX.Element => {
    const [fields, setFields] = useState<IDataType[]>(props.fields || [])
    const [item, setItem] = useState<any>( props.item || {} )

    useEffect(() => {
        if(props.data_type) {
            const apollo_fields = schema()[ props.data_type ]?.apollo_fields  
            if(apollo_fields) {
                setFields( Object.keys(apollo_fields).map(key => ({...apollo_fields[key], name: key}) ) )
            }
        }
        if( props.fields ) {
            setFields( props.fields )
        }
    }, [props.data_type, props.fields])

    useEffect(() => {
        setItem( props.item )
    }, [props.item])
 
    const onChange = (value: any, field: string, dop: any) => {
        // console.log(value, field, dop)
        const _item = { ...item }
        _item[field] = value
        const type = fields?.filter(( fld: IDataType) => fld.name === field )[0]
        
        switch (type?.type) {
            case SCALAR_TYPES.MEDIA: 
                _item[`${field}_name`] = dop?.name || ""
                break
            default:
                break
        }
        props.onChange( _item )
    } 

    const fieldsForm = fields
        .filter((field: IDataType) => field.name !== "admin_data")
        .filter((field: IDataType) => !field.hidden)
        .map((field: IDataType, i: number) => { 
            const type = field.type === "array" && field.kind === "type"
                ?
                SCALAR_TYPES.EXTERNAL_ARRAY
                :
                    field.component === "User"
                        ?
                        SCALAR_TYPES.USER
                        :
                        field.type
            return <div className='position-relative' key={i}> 
                <FieldInput
                    { ...field }
                    field={ field.name }
                    type={ type }
                    value={ item[field.name] }
                    key={ field.name + "_" + i }
                    onChange={ onChange }
                    landId={landId}
                />
            </div>
        })
    return <>
        { fieldsForm }
    </>
}
export default FieldInputGroup