import { Color } from '@/libs/interfaces/layouts'
import { LoaderMinimal } from '@/libs/useful/Loading'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { Tag as BTag } from '@blueprintjs/core'
import { useEffect, useState } from 'react'
import { adminData, apolloFields, getVisibleValue } from 'src/libs/layouts'
import { __ } from "src/libs/utilities" 
import actions from 'src/modules/pe-basic-module/data/actions'
import IScalarForm, { SCALAR_TYPES } from '../interface/IScalarForm'
import MultiTag from './label/MultiTag'
import Scalar from './Scalar'
import { GET_FEED_ACTION } from '@/modules/pe-basic-module/data/actionTypes'

const ExternalTags = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<ExtenalSingleEnabled {...props} />}
        desabledForm={<ExtenalSingleDesabled {...props} />}
    />
} 
export default ExternalTags 

const ExtenalSingleEnabled = ( props: IScalarForm ): JSX.Element => {
    const [ seed, ] = useState<string>( props.seed || props.component + " " + props.paging + " " +  props.landId )
    const items: any[] = useDataStore((state:any) => {
        if( props.component ){
            return state[props.component + seed] || []
        }
        return []
    }) || []
    const [isLoading, setIsLoading] = useState<boolean>(true)
    useEffect(() => {
        actions( 
            GET_FEED_ACTION, 
            { 
                data_type: props.component,
                paging: props.paging,
                land_id: props.landId,
                seed 
            } 
        )
            .then( response => {
                setIsLoading( false ) 
            })
        return () => {
            const st = {...useDataStore.getState() }
            // console.log( props.component + seed )
            st[ props.component + seed ] = []
            // console.log(st)
            useDataStore.setState( st )
        }
    }, [])
     
    // console.log( props )
    const [value, setValue] = useState( () => {
        if(props.multiple)
        {
            let v =  Array.isArray(props.value) ? [ ...props.value ] : [] 
            return v
        }
        else
        {
            return props.value
        } 
    })
    useEffect( () => {
        setValue( () => {
            let v =  Array.isArray(props.value) ? [ ...props.value ] : [] 
            return v
        })
    }, [props.value] ) 

    const onMultipleClick = (id : any) =>
    {
        // console.log( "onMultipleClick", value)
        let vv
        if(value.filter((v: any) => v.id === id.id ).length > 0)
        {
            vv = value.filter((v: any) => v.id !== id.id )
        }
        else
        {
            vv = [...value]
            vv.push(id)
        }
        setValue( vv )        
        if( props.onChange )
            props.onChange(vv,  props.field, "")
    }
    const onAll = () =>
    {
        let vv = value.length === items?.length 
            ? 
            [] 
            : 
            [ ...(items ? items?.map( v => v._id ? v._id : v ) : [] ) ]
        setValue( vv )
        if( props.onChange )
            props.onChange(vv,  props.field, "")
    }
    const values = Array.isArray([ ...(items ? items : [] ) ])
        ?
        items?.map( (v, i) =>
        {
            return miltiple({ 
                v, 
                value, 
                i, 
                onMultipleClick, 
                tags: props.tags, 
                component: props.component || "Post" 
            })
        })
        :
        [ ]
    return (
        <div className='w-100 d-flex flex-wrap py-4 '>
            {
                isLoading && <LoaderMinimal />
            }
            { values } 
            <BTag 
                intent={"primary"} 
                className="m-1 px-3 py-1 pointer" 
                minimal 
                round
                onClick={ onAll }
            > 
                { __( "All" ) }
            </BTag> 
        </div>
    )
} 
 
const ExtenalSingleDesabled = ( props: IScalarForm ): JSX.Element | JSX.Element[] => { 
    const visibled_value2 = getVisibleValue( props.component! || "Post" )
    const vv2 = visibled_value2 || SCALAR_TYPES.TITLE
    return Array.isArray(props.value) 
        ?
        props.value.map(
            (v: any, i) => {
                const labels = !!props.tags && Array.isArray( props.tags )
                    ?
                    props.tags.map(tag => {
                        if( v[tag]?.id ) {
                            const vComponent = apolloFields( props.component! || "Post" )[tag]?.component                            
                            return v[tag][getVisibleValue( vComponent || "Post" )]
                        }
                        else {
                            return v[tag]
                        }
                             //apolloFields()
                    })
                    :
                    []
                console.log( adminData( props.component! || "Post" ).fill[0] )
                const fill: Color = adminData( props.component! || "Post" ).fill[0]
                return props.tags 
                    ?
                    <MultiTag
                        labels={ labels }
                        round
                        fill={ fill }
                        onClick={() => {} }
                    />
                    :
                    <BTag round className="px-4 py-1 m-1" key={i}>
                        { !!v ? v[vv2] : "--" }
                    </BTag>
            }
        )
        :
        <></>
} 

const miltiple = (props: any) : JSX.Element | null =>
{
    const {v, value, i, tags} = props
    // console.log( props )
    const visibled_value2 = getVisibleValue( props.component! || "Post" )
    const vv2 = visibled_value2 || SCALAR_TYPES.TITLE
    // return <div className='m-2'>{vv2}</div>
    return  v?.id 
        ?
        tags
            ?
            <MultiTag
                labels={["title", "descr"]}
                onClick={() => props.onMultipleClick( v )}
            />
            :
            <BTag 
                key={ v.id + "_" + i } 
                intent={ v.intent }
                className="m-1 px-3 py-1 pointer" 
                round
                minimal={ value?.filter( (e: any) => e.id === v.id ).length === 0 }
                onClick={() => props.onMultipleClick( v )}
            >
                <div className='px-2' dangerouslySetInnerHTML={{__html: v[vv2]}} />
            </BTag>
        :
        typeof v === "string"
            ? 
            <BTag 
                key={v + "_" + i} 
                data-value={ v }  
                className="m-1 px-3 py-1 pointer" 
                round
                minimal={ value?.filter( (e: any) => e.id !== v ).length > 0 }
                onClick={() => props.onMultipleClick( v )}
            >
                <div className='pl-3' dangerouslySetInnerHTML={{__html: v }} />
            </BTag> 
            :
            null
}
 