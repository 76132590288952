import { LayoutIcon } from "@/libs/useful"
import { __, getContrastColor } from "@/libs/utilities"
import { ILand } from "@/modules/pe-fest-module/data/interfaces"
import { IEventType } from "@/modules/pe-topos-module/data/interfaces"
import { eventTypes } from "@/modules/pe-topos-module/data/mocks/eventTypes"

/* Protopia Ecosystem component */
export interface IEventTypesChooserProps {
   land: ILand
   setLand: (land:ILand) => void
}
const sz = 24
const EventTypesChooser = ({ land, setLand }: IEventTypesChooserProps): JSX.Element => { 
    

    const onEventTypes = (value: string) => { 
        let event_types = [...land.event_types]
        if( !!event_types.filter(e => e === value).length ){
            event_types = event_types.filter(e => e !== value)
        }
        else {
            event_types.push(value)
        }
        setLand({
            ...land, 
            event_types 
        })
    }
    return <> 
    {
        eventTypes().map(( et: IEventType ) => {
            const enb: boolean = !!land.event_types?.filter(e => e === et.title).length
            return <div className="d-flex mt-0" key={et.id}>
                <div 
                    className=" m-1 d-flex align-items-center rounded-pill pointer"  
                    key={ et.id } 
                    style={{ 
                        background: enb ? et.color : "#777777", 
                        height: sz + 12,
                        opacity: enb ? 1 : 0.75
                    }}
                    onClick={ () => onEventTypes( et.title ) }
                >
                    <span className="topos-placetype-label-icon" style={{ height: sz + 12, width:sz + 18 }}>
                        <LayoutIcon
                            src={ et.icon }
                            className=""
                            style={{ width:sz, height: sz }}
                        />
                    </span>
                    <span 
                        className="px-3 py-1" 
                        style={{ 
                            color: enb ? getContrastColor(et.color): "#FFFFFF" 
                        }}
                    >
                        { __( et.title ) }
                    </span>
                </div>
            </div>
        })
    }    
    </>
}
export default EventTypesChooser