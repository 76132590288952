import UserSearchEngine from '@/libs/useful/UserSearchEngine'
import { IUser } from '@/settings/zustand'
import { Tag } from '@blueprintjs/core'
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'

const UserSingle = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props }
        enabledForm={<UserSingleEnabled {...props} />}
        desabledForm={<UserSingleDisabled {...props} />}
    />
} 
export default UserSingle 

export const UserSingleEnabled = ( props: IScalarForm ): JSX.Element => { 
    const onChange = (value: IUser) => { 
        if( props.onChange )
        {
            props.onChange( value,  props.field, "" ) 
        }   
    } 

    return <UserSearchEngine 
        onSelect={onChange} 
        user={props.value} 
        notClear 
        isMulti={!!props.isMulti} 
        landId={props.landId}
        onlyThisLands={ !!props.onlyThisLands } 
    />
} 
 
const UserSingleDisabled = ( props: IScalarForm ): JSX.Element => {
    return <Tag className='px-3 py-1 m-1' round >
        {props.value?.display_name }
    </Tag>
} 
 