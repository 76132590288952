import { IState, IUser, useMainStore } from "@/settings/zustand"
import { ID } from "@/libs/interfaces/layouts"
import { Loading } from "@/libs/useful"
import { useEffect, useState } from "react"
import { Navigate, Route, Routes } from "react-router"
import { GET_FULL_STATISTICS_ACTION, GET_PROJECTS_MILESTONES, } from "../../data/actionTypes"
import { actions } from "../../data/actions"
import { IProject, IRating } from "../../data/interfaces"
import { useFestival } from "../../hooks/festival"
import StatisticForm from "../about/StatisticForm"
import FullExpertStatistics from "../statistics/FullExpertStatistics"
import FullMemberStatistics from "../statistics/FullMemberStatistics"
import SpecialStatictics from "../statistics/SpecialStatistics"
import Synthesizer from "../statistics/Synthesizer"
import SchoolsForm from "../statistics/SchoolsForm"
import { IFestStatistics, useFestStatistic } from "../../data/store"

const FestStatisticForm = ( ) : JSX.Element => { 
    const festId: ID = useMainStore((state: IState) => state.landId)
    const setSelected = useFestStatistic(( state:IFestStatistics ) => state.setSelected )
    const isLoading = useFestival(true, festId)
    const [isPojectsLoading, setIsProjectLoading] = useState( true )
    const [projects, setProjects] = useState<IProject[]>( [] )
    const [users, setUsers] = useState<IUser[]>( [] )
    const [ratings, setRatings] = useState<IRating[]>( [] )
    useEffect(() => {
        actions(GET_FULL_STATISTICS_ACTION, { land_id: festId } )
            .then(response => {
                console.log( response.data )
                setProjects( response.data.getPEFestProjects )
                setUsers( response.data.getUsers )
                setRatings( response.data.getPEFestRatings ) 
                setIsProjectLoading( false )
            })
        setSelected( [] )
    }, [])
    if( isLoading || isPojectsLoading ) return <Loading />

    return <>  
        <Routes> 
            <Route path="" element={ <StatisticForm /> } >              
                <Route index element={ <Navigate to="specials" replace /> } />   
                <Route 
                    path="all-members" 
                    element={ 
                        <FullMemberStatistics 
                            members={ projects } 
                            users={users}
                            ratings={ratings}
                        /> 
                    } 
                />
                <Route 
                    path="users" 
                    element={ 
                        <FullExpertStatistics
                            members={ projects } 
                            users={users}
                            ratings={ratings}
                        />
                    } 
                />
                <Route 
                    path="synthesizer" 
                    element={ 
                        <Synthesizer
                            members={ projects } 
                            users={users}
                            ratings={ratings}
                        />
                    } 
                />
                <Route 
                    path="specials" 
                    element={ 
                        <SpecialStatictics
                            members={ projects } 
                            users={users}
                            ratings={ratings}
                        />
                    } 
                />                                             
                <Route 
                    path="schools" 
                    element={ 
                        <SchoolsForm 
                            
                        />
                    } 
                />                                             
                <Route path="*" element={ <Navigate to="specials" replace /> } />                                             
            </Route> 
        </Routes>
    </>
} 

export default FestStatisticForm