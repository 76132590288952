import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { isRole } from '@/settings/zustand/user'
import { Button, ButtonGroup, Collapse, Popover, Position } from '@blueprintjs/core'
import { ID, WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { LayoutIcon } from '@/libs/useful'
import { __, compareArrays, sprintf } from '@/libs/utilities'
import React, { useEffect, useRef, useState } from 'react'
import { template } from 'src/libs/layouts'
import { SET_FILTERS_ACTIONS } from '../data/actionTypes'
import { syncAction } from '../data/actions'
import { IDestrict, IFestFilters, IPEFestSchool } from '../data/interfaces'
import { IFestStore, useFestStore } from '../data/store'
import { isMySchool } from '../data/utils/user'
import useClickOutside from './hooks/useClickOutside'
import useProjectListRoute from './hooks/useProjectListRoute'

const SCHOOL_FILTER_ICON_URL = "/assets/img/fest/schools.svg"

const SchoolsFilterMenu = () : JSX.Element => { 
    if(window.innerWidth < WINDOW_WIDTH.TABLET) return <></> 
    return <SchoolFilters />   
}

export const SchoolFilters = () : JSX.Element => { 
    const schools : IPEFestSchool[] = useDataStore((state:any) => state.PEFestSchool )  || []
    const filters: IFestFilters = useFestStore((state: IFestStore) => state.filters )
    const setCurrentTitle = () => {  
        if( filters.schools.length === 1 ) {
            setTitle( "all Schools" )
        }
        else if( compareArrays( [ "-1", ...schools.filter(s => isMySchool(s)).map( s => s.id ) ], filters.schools ) ) {
            setTitle( "Only mine Schools" ) 
        }
        else if( filters.schools.length === 2 ) {
            const sc = schools.filter( s => s.id === filters.schools[1] )[0] || { title: "all Schools" }
            setTitle( sc.title )
        } 
        else {
            setTitle( sprintf( __("selected %s schools"), ( filters.schools.length - 1 ).toString()  ) )
        } 
    }
    const [title, setTitle] = useState( __( "all Schools" ) )
    const [isOpen, setOpen] = useState(false)
    const [isCollapse, setCollapse] = useState(false) 
    const myRef: React.MutableRefObject<null> = useRef( null )
    const isRoute: boolean = useProjectListRoute() && !!schools.length
    
    const onOpen = (isOpen:boolean) =>
    {
        setOpen(isOpen)
    }
    let timeOut: any = -1    
    useClickOutside( myRef, onOpen, timeOut)

    useEffect( () => {
        setCurrentTitle()
    }, [ filters ] )

    
     
    const handleLoginOpen = () =>
    {
        clearTimeout( timeOut )
        onOpen(true)
    }
    const SelectSchool = (id: ID) =>
    {   
        const selectSchools =  id === -1
            ?
            [-1]
            :
            id === "mine" ? [ "-1", "mine"] : [ "-1", id ] 
        syncAction(
            SET_FILTERS_ACTIONS, 
            { 
                filters:{ 
                    schools: selectSchools[1] === "mine"
                        ?
                        [ "-1", ...schools.filter(s => isMySchool(s)).map( s => s.id ) ]
                        :
                        selectSchools
                } 
            } 
        )
        setCurrentTitle()
        clearTimeout( timeOut )
        onOpen(false)
    } 
    const onMouseLeave = () =>
    {
        clearTimeout( timeOut )
        timeOut = setTimeout(() =>
        {
            onOpen(false)
        }, 1000)
    }
    return <>   
        <div 
            className={`indicator dble lrg classic p-0 m-0 overflow-hidden ${ isRoute ? "" : " closed"}`}             
            onMouseLeave={onMouseLeave}
            ref={ myRef }
        >
            <div className={ `header-menu-element ${ template().header_menu } main-menu-filter-icon ` }  onClick={handleLoginOpen}
                title={ __( title ) }
            >
                <LayoutIcon
                    src={ SCHOOL_FILTER_ICON_URL }
                    className="header-menu-icon"
                    
                />
                <span className='header-menu-label px-2 ' title={ __( title ) }>
                    { __( title ) }
                </span>
            </div> 
            <Popover
              className="indicator-popover"
              popoverClassName="p-0" 
              position={ Position.BOTTOM_RIGHT }
              isOpen={isOpen}
              content={(
                <div className='overflow-y-auto' style={{maxHeight:"calc(100vh - 150px)"}}>
                    <ButtonGroup 
                        vertical 
                        fill 
                        minimal 
                        onMouseEnter={() =>  clearTimeout( timeOut ) }
                        style={{ minWidth: 200 }}
                    >
                        <Button 
                            className={`p-3 btn-stretch text-force-contrast ${ filters.schools.length === 1 ? " bg-danger  text-light " : "" }`} 
                            onClick={() => SelectSchool( -1 ) }
                        >
                            { __( "all Schools" ) }
                        </Button>
                        {
                            isRole([ "Tutor", "Project_author", "School_guardian" ]) 
                                ?   
                                <Button 
                                    className={`p-3 text-force-contrast btn-stretch ${ compareArrays( [ "-1", ...schools.filter(s => isMySchool(s)).map( s => s.id ) ], filters.schools ) ? " bg-danger  text-light " : "" }`}  
                                    onClick={() => SelectSchool( "mine" ) }
                                >
                                    {__("Only mine Schools")}
                                </Button> 
                                :
                                null
                        }
                        {
                            Array.isArray( schools )
                                ?
                                <>
                                    <Button 
                                        icon="chevron-down" 
                                        minimal
                                        small
                                        className={isCollapse ? "hidden" : " p-3 "} 
                                        onClick={( event: React.MouseEvent<HTMLElement, MouseEvent> ) => {
                                            setCollapse(true)
                                            onOpen(true)
                                            event.preventDefault()
                                            event.stopPropagation()
                                        }}
                                    />
                                    <Collapse isOpen={isCollapse} >
                                        <div className="d-flex flex-column">
                                        {
                                            schools.map((school:IPEFestSchool) =>
                                            {
                                                return <Button 
                                                    className={ `p-0 btn-stretch text-force-contrast ${ filters.schools.includes( school.id ) ? " bg-danger text-light " : "" }` } 
                                                    key={school.id} 
                                                    onClick={() => SelectSchool(school.id) }
                                                >
                                                    <div className='d-flex w-100'>
                                                        <span className='flex-grow-100 p-3 small '>
                                                            { school.title }
                                                        </span>
                                                        <span className ='p-3 bg-secondary-super-light small '>
                                                            { school.count || 0 }
                                                        </span>
                                                    </div>
                                                </Button>
                                                
                                            })                                        }                                    
                                        </div>
                                    </Collapse>
                                </>
                                :                        
                                <div className='p-3' >
                                    {__("no Schools")}
                                </div>   
                        }
                    </ButtonGroup>
                </div>
              )}
            >
                <div style={{ marginTop: 0 }} />
            </Popover> 
        </div> 
    </> 
} 

export default SchoolsFilterMenu



 