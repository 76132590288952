import { __ } from "@/libs/utilities"
import { ITrack } from "../../data/interfaces"
import { Collapse } from "@blueprintjs/core"

const getModeratorAvatar = (
    item: ITrack, 
    isOpen: boolean = true, 
    random: number  = Math.random(), 
    moderator: "moderator" | "moderator2" = "moderator"
) => {  
    return item[moderator]?.id
      ?        
      <div className='d-flex align-items-center m-3 mt-auto pb-2'>
        <div className={ ` mr-3` } style={{ width: 40, minWidth: 40 }}>
          <div 
            className='my-avatar-cont'
            style={{
              backgroundImage: `url(${ item[moderator]?.avatar }?${ random })`,
              backgroundColor: '#555555'
            }}
          />
        </div> 
        <div>
          <div className=' lead text-secondary'>
            { __( moderator === "moderator" ? "Track expert" : "Track moderator assistant" ) }
          </div>
          <div className='title'>
            { item[moderator].display_name }
          </div>
          <Collapse isOpen={ isOpen } className={`w-100`}>
            <div className='mt-1' dangerouslySetInnerHTML={{ __html: item[moderator]?.user_descr }} />
          </Collapse>            
        </div>
      </div> 
      :
      null
}

export default getModeratorAvatar