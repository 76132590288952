import { ICategory, ICritery, IProject, IProjectMilestone, IRating } from '@/modules/pe-fest-module/data/interfaces'
import { useIsExpert } from '@/modules/pe-fest-module/hooks/project'
import { IState, IUser, useMainStore } from '@/settings/zustand'
import chroma from 'chroma-js'
import { useState } from 'react'
import CriteryDescriptionDisplay from './CriteryDescriptionDisplay'
import CriteryRatingExpertDisplay from './CriteryRatingExpertDisplay' 
import { ID } from '@/libs/interfaces/layouts' 
import CriteryDescriptions from './CriteryDescriptions'

interface ICriteryProps {
    item: ICritery
    project:IProject
    milestone: IProjectMilestone | null
}
const Critery = (props: ICriteryProps) :JSX.Element => {
    const [ opacityClassName, ] = useState( "" ) 
    const isExpert:boolean      = useIsExpert( props.project, props.milestone?.milestone! )
    const ratings:IRating[]     = ( props.project.ratings || [] ).filter((rating:IRating) => rating.criteryId === props.item.id)
    const user: IUser           = useMainStore((state:IState) => state.user )
 

    const category: ICategory | null = props.item.category
    //console.log( props.item.title, category )
    const onOpen = () => {

    }
    const categoryStyle: any = {} 
    if( !!category?.color && /^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test( category?.color  as string ) ) { 
        //const accentuated: string       = chroma( category?.color as string ).get('hsl.l') < .55 ? "#FFFFFF"   : "#000000"
	    //const accentuated2: string      = chroma( category?.color as string ).get('hsl.l') < .77 ? "#FFFFFF33" : "#6c757d33"
	    const backgroundColor           = chroma( category?.color as string ).brighten( 2.1 ).desaturate( .25 ).alpha( .33 )   
        categoryStyle.backgroundColor   = backgroundColor
        //categoryStyle.color             = accentuated
    }
    
    return <div className="row critery-block" style={ categoryStyle }>
        <div className="col-md-7 col-12 critery_cell grey first bl-20 pb-0" >
            <div className="critery-title ml-4"> 
                <div className=" ">
                    <span className='small opacity_75 mr-1 d-none'>{ props.item.id }</span> 
                    <span className='title'>{ props.item.title }</span> 
                </div>  
                <div className="small" dangerouslySetInnerHTML={{__html: props.item.post_content}} /> 
                
                <CriteryDescriptionDisplay 
                    critery= {props.item}
                    isExpert={isExpert} 
                    description={ ratings.filter((rating: IRating) => rating.expertId === user.id )[0]?.description }
                    onOpen={ onOpen }
                />
                <div className="my-1">
                    <CriteryDescriptions ratings={ratings} />
                </div>
            </div>
        </div>
        <div className={`col-md-5 col-12 critery_cell grey pb-0_`} >
            <div className="d-flex flex-centered flex-md-row flex-column pr-2"> 
                <div className={` w-100 flex-grow-100 ${ opacityClassName }`}> 
                    <CriteryRatingExpertDisplay 
                        critery={props.item}  
                    />
                </div> 
            </div>
        </div> 
    </div>  
}

export default Critery