import { Button, ButtonGroup, Collapse, Icon, Tag } from "@blueprintjs/core"
import { LogData } from "../../data/LogData"
import { __ } from "@/libs/utilities"
import { ILogDatas, LOG_DANGER_TYPE, TIME_PERIOD } from "../../data/interfaces"
import { useEffect, useMemo, useState } from "react"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import TrashLog from "./TrashLog"
import { actions } from "../../data/actions"
import { DELETE_ALL_LOGS_ACTION } from "../../data/actionTypes"

/* Protopia Ecosystem component */
export interface ILogsFilterProps {
    onFilter: ( filter: ILogDatas, period: TIME_PERIOD ) => void
}
const LogsFilter = ({onFilter}: ILogsFilterProps): JSX.Element => {
    const logData: ILogDatas = useMemo(() => LogData(), [])
    const [selects, setSelects] = useState<ILogDatas>({})
    const [selType, setSelTypes] = useState<string[]>( Object.values(LOG_DANGER_TYPE) )
    const [period, setPeriod] = useState<TIME_PERIOD>( TIME_PERIOD.DAY )
    const [isOpen, setIsOpen] = useState<boolean>(false)
    
    useEffect(() => {
        setSelects( logData )
    }, [])
    const onClick = ( evt: React.MouseEvent<HTMLSpanElement> ) => {
        const key: string = evt.currentTarget.getAttribute("data-key")!
        let sel: any = {}
        console.log( key, Object.keys(selects).filter(s => s === key ), selects )
        if( !!Object.keys(selects).filter(s => s === key ).length ) {
            const data = {...selects}
            delete data[key]
            sel = {...data}
            console.log( "delete",sel )
        }
        else {
            sel = {
                ...selects,
                [key]: logData[key]
            }
            console.log( "add",sel )
        }
        setSelects( sel )
        onFilter( sel, period )
    }
    const onDTClick = ( evt: React.MouseEvent<HTMLSpanElement> ) => {
        const key: string = evt.currentTarget.getAttribute("data-key")!
        let sel: string[] = [] 
        if( !!selType.filter(s => s === key ).length ) {
            sel = [ ...selType ].filter(ss => ss !== key)
        }
        else {
            sel = [ ...selType, key ]
        }
        setSelTypes( sel )
        let _selected: ILogDatas = {}
        Object.keys( logData ).forEach(k => {
            if( sel.includes( logData[k].dangerType ) ) {
                _selected[ k ] = logData[ k ]
            }
        })
        setSelects( _selected )
        onFilter( _selected, period )
    }
    const onPeriodClick = ( evt: React.MouseEvent<HTMLSpanElement> ) => {
        const key: TIME_PERIOD = evt.currentTarget.getAttribute("data-key") as TIME_PERIOD
        setPeriod( key )
        onFilter( selects, key )
    }
    const onDeleteAll = () => {
        actions(DELETE_ALL_LOGS_ACTION, {})
            .then(() => onFilter( selects, period ))
    }
    const onTrashLog = () => {

    }

    return <div className='pe-logs-filter-container my-3'> 
        <div className="pb-3 d-flex flex-wrap">
            {
                Object.values(TIME_PERIOD).map( prd => <Button 
                    minimal
                    key={ prd } 
                    data-key={ prd } 
                    onClick={ onPeriodClick }
                    className={ prd !== period ? "opacity_25" : ""}
                    small
                >
                    <small>{__( prd )}</small>
                </Button>)
            }
        </div> 
        <div className={`w-100 ${ isOpen ? "d-none" : "" }`}>
            <Button icon="caret-down" minimal onClick={ () => setIsOpen(true) } >{__( "log types" )}</Button>
        </div>
        <Collapse isOpen={isOpen}>
            <div className="pb-3 d-flex flex-wrap w-100">
                {
                    Object.keys(logData).map(( key: keyof typeof logData ) => {
                        const sel = !Object.keys(selects).filter(s => s === key ).length
                        return <Tag 
                            round 
                            className={`pointer px-3 py-1 m-1 ${logData[ key ].dangerType}`} 
                            key={ key.toString() } 
                            minimal={ sel }
                            onClick={onClick}
                            data-key={ key }
                            data-sel={ `${sel ? 1 : 0}` }
                        >
                            <small>{ __( logData[ key ].title ) }</small>
                        </Tag>
                    })
                }
            </div> 
        </Collapse>
        
        <div className="pb-3 d-flex justify-content-between align-items-center">
            <div className="d-flex flex-wrap">
                {
                    Object.values(LOG_DANGER_TYPE).map( dt => <div 
                        className={`log-dang-type-btn bg-${dt} ${ !!selType.filter(st => st === dt).length ? "dt-select" : "" }`} 
                        key={dt} 
                        data-key={dt} 
                        onClick={onDTClick}
                    />)
                }
            </div> 
            <ButtonGroup minimal>
                <ConfirmButton
                    buttonSmall
                    buttonLabel={<span className="small text-force-contrast hover">{__("Delete all")}</span>}
                    dialogClasssName={DIALOG_SIZE.MINIMAL}
                    onConfirm={onDeleteAll}
                >
                    <div className="p-5 text-center">
                        { __("Are you really clear all log folder?") }
                    </div>
                </ConfirmButton>
                <ConfirmButton
                    buttonSmall
                    buttonIcon="trash"
                    dialogClasssName={DIALOG_SIZE.NORMAL}
                    dialogTitle={__("Trash")}
                    yesLabel={<div className="d-flex">
                        <Icon icon="trash" className="mr-3" />
                        {__("Clear trash?")}
                    </div>}
                    onConfirm={onTrashLog}
                    isHiddenNo
                    isHiddenYes
                >
                    <TrashLog onRefresh={() => onFilter( selects, period )} />
                </ConfirmButton>
            </ButtonGroup>

        </div> 
    </div>
}
export default LogsFilter