import { DEFAULT_404_PROPS } from "@/libs/interfaces/dummies"
import Feed from "@/modules/pe-basic-module/views/core/Feed"
import NoMatchForm from "@/modules/pe-basic-module/views/no-mathc/NoMatchForm"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { isRole } from "@/settings/zustand/user"
import { Outlet, Route, Routes } from "react-router"
import AddFestivalForm from "./AddFestivalForm"
import FestivalAdminButton from "./FestivalAdminButton"
import FestivalAdminList from "./FestivalAdminList"
import FestivalAdminSingle from "./FestivalAdminSingle"
import FestivalServerSettings from "./FestivalServerSettings"
import LandListForm from "./LandListForm"
import { land_group_id } from "@/libs/layouts"

const FestivalAdminListForm = (props:any) :JSX.Element => { 
    const user: IUser = useMainStore((state: IState) => state.user)

    const getLandsPaging = () => {        
        if(isRole(["administrator", "SuperAdmin"])) {
            if( !!land_group_id() ) {
                return ` meta_relation: "AND"  metas: [ { key: "land_group_id", value: "${ land_group_id() }" } ]`
            }
            else {
                return ` ` 
            }
        }
        else {
            return ` meta_relation: "AND"  metas: [ {key : "demiurg_id", value: "${user.id}"} ] `
        } 
    }
    return <Routes>
        <Route path={`/*`} element={<Outlet />} >
            <Route path={`/*`} element={<FestivalAdminList />} >
                <Route index
                    element={
                        <Feed 
                            data_type="Land"
                            offset={0} 
                            class_name = "list-group list-group-flush container"
                            containerClassName="container-fluid"  
                            layoutComponent={ LandListForm }
                            component={ FestivalAdminButton }
                            topPagiHide
                            paging={ getLandsPaging() }
                            params={{ }}
                        />     
                    }
                />
                <Route path="settings" element={<FestivalServerSettings />} /> 
                <Route path="add-festival" element={<AddFestivalForm />} />
                <Route path='*' element={<NoMatchForm {...DEFAULT_404_PROPS} />}  />
            </Route>
            <Route 
                path={`:landId/*`} 
                element={
                    <FestivalAdminSingle 
                        path={`${props.realRoute}`}
                    />
                } 
            />
            <Route path='*' element={<NoMatchForm {...DEFAULT_404_PROPS} />}  />
        </Route>
    </Routes> 
} 

export default FestivalAdminListForm