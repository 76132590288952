import FieldInput from "@/libs/scalars"
import { IAddFestivalTabsProps } from "../AddFestivalTabs"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { Collapse } from "@blueprintjs/core"
import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */ 
const PatternsTab = (props: IAddFestivalTabsProps) : JSX.Element => {
    return <>
        <FieldInput
            value={props.item.pattern}
            vertical={VerticalType.VERTICAL}
            editable
            component="Land"
            paging={` metas: {key: "isPattern", value: "1" } `}
            type={SCALAR_TYPES.EXTERNAL}
            title={__("Pattern")} 
            onChange={ props.on }
        />
        <Collapse isOpen={!!props.festival.domain_content} className="w-100">
            <div className="pb-4 px-3 text-center w-100 descr text-force-contrast ">
                {
                    props.festival.domain_content
                }
            </div>
        </Collapse> 
    </>
}
export default PatternsTab