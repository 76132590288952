import React from "react"  
import BasicState from "@/libs/basic-view"
import { __ } from "@/libs/utilities"
import LoginRegisterForm from "./profile/LoginRegisterForm"


class LoginView extends BasicState {
  props: any
  addRender()
  {
    return <div className="container h-100  flex-centered flex-grow-100">
      <div className="row justify-content-center w-100">
        <LoginRegisterForm {...this.props} />
      </div>
    </div>
  }
}
export default LoginView 
