import { useAppoloStore } from "@/settings/zustand"
import { FEST_ADMIN_STATUSES, IFestival } from "../../data/interfaces"
import FestivalStatusForm from "./FestivalStatusForm"
import gql from "graphql-tag"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { actions } from "../../data/actions"
import { GET_FESTIVAL_SETTINGS_ACTION } from "../../data/actionTypes"

/* Protopia Ecosystem component */
export interface IEventCarouselPhaseFormProps { 

}
const EventCarouselPhaseForm = ({ ...props }: IEventCarouselPhaseFormProps): JSX.Element => {
    const {landId} = useParams()
    const [festival, setFestival] = useState<IFestival>( {} as IFestival )
    const [isLoad, setIsLoad] = useState<boolean>(true)
    useEffect(() => { 
        actions(GET_FESTIVAL_SETTINGS_ACTION, {id: landId})
            .then(
                response => { 
                    setIsLoad(false)
                    setFestival(response) 
                }
            ) 
    }, [])

    const onPhase = ( status: FEST_ADMIN_STATUSES ) => {
        setFestival({ ...festival, status })
        setIsLoad(true)
        const land_id = landId 
            ?
            ` land_id:"${landId}" `
            :
            ``
        const mutation: string =  `
            mutation changePEFestival {
                changePEFestival (
                    input: { 
                        status: ${ status }
                    }
                    ${ land_id }
                ) {
                    status
                }
            }`
        const apolloClient: any = useAppoloStore.getState().client
        apolloClient.mutate({
            mutation : gql`${mutation}`
        })
        .then((resp: any) => {
            setIsLoad(false)
            console.log( resp.data.changePEFestival.status ) 
        })
    }

    return <div className=" event-carousel-phase-form-container " >
        <FestivalStatusForm festival={festival} onPhase={onPhase} />  
    </div>
}
export default EventCarouselPhaseForm