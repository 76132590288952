import { ID } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __, wordTrim } from "@/libs/utilities"
import { IDestrict } from "@/modules/pe-fest-module/data/interfaces"
import { EditableText } from "@blueprintjs/core"
import { useEffect, useRef, useState } from "react"

/* Protopia Ecosystem component */
export interface IDestrictProps {
    item: IDestrict 
    onDelete: ( id: ID ) => void
    onChange: ( field: string, value: any, name?: string ) => void
}
const DestrictCard = (props: IDestrictProps): JSX.Element => { 
    const [isContentEdit, setIsContentEdit] = useState<boolean>( false ) 
 
    const onEditContent = (bool: boolean) => {
        setIsContentEdit(bool)
    }
    const onChangeTitle = (title: string) => {
        props.onChange("title", title)
    }
    const onThumbnail = (thumbnail: string, name:string="") => {
        props.onChange( "thumbnail", thumbnail, name )
    } 
    const onChangeContent = (content: string) => {
        props.onChange("content", content)
    }
    return <div className={`pe-track-edit-card-container `}>
        <div className="thumb position-relative" > 
            <ResourceIllustration 
                illustration={props.item?.thumbnail || ""}
                onChange={onThumbnail}
            /> 
            <div className="position-absolute bottom right small bg-dark text-light m-2 py-1 px-2">
                {
                    props.item.order
                }
            </div>
        </div>
        <div className="d-flex flex-column flex-grow-100">
            <div className="title"> 
                <EditableText
                    className=" px-3 py-1 track-edit-title"
                    alwaysRenderInput={true} 
                    disabled={false}
                    placeholder="Edit title..."
                    selectAllOnFocus={true} 
                    value={props.item.title}
                    onChange={onChangeTitle}
                /> 
            </div>
            <ResourceContent
                content={props.item.content}
                isContentEdit={isContentEdit}
                onChangeContent={onChangeContent}
                onEditContent={onEditContent}
            />            
            <div className="footer"> 
                <ConfirmButton
                    buttonIcon="trash"
                    onConfirm={() => props.onDelete( props.item.id )}
                    buttonLabel={<div className="d-md-flex d-none ">{__("Delete")}</div>}
                    buttonMinimal
                >
                    <div className="p-4">
                        {__("Really delete?")}
                    </div>
                </ConfirmButton> 
            </div>
        </div>        
    </div>
}
export default DestrictCard
 
export interface IResourceContentProps {
    isContentEdit: boolean
    content: string
    onChangeContent: (content: string) => void
    onEditContent : (bool:boolean) => void
}
export const ResourceContent = ({
    content, 
    isContentEdit,
    onChangeContent, 
    onEditContent,
    ...props
}: IResourceContentProps): JSX.Element => {
    const myRef = useRef( null )

    const onMouseLeaveHandler = (e: Event) => {
        const domNode: any = myRef.current;
        if (!domNode || !domNode.contains(e.target)) {
            //height = 0 
            onEditContent( false )
        } 
    }
    useEffect(() => {
        document.body.addEventListener("click", onMouseLeaveHandler)
        return () => document.body.removeEventListener("click", onMouseLeaveHandler)
    }, [])

    return <div 
        className="content position-relative " 
        onClick={ () =>  onEditContent( true ) } 
        ref={ myRef }
    >
        <EditableText
            className="track-content-edit w-100 "
            alwaysRenderInput={true} 
            //disabled={ !isContentEdit }
            multiline
            placeholder="Edit content..." 
            value={ isContentEdit && content ? content : wordTrim( content || "", 50 ) }
            minLines={5}
            maxLines={5}
            onChange={onChangeContent} 
        /> 
    </div> 
} 

/* Protopia Ecosystem component */
export interface ITrackIllustrationProps {
    illustration:string
    onChange: (illustration: string, illustrationName: string) => void
    width?: number | string
    style?: any
}
export const ResourceIllustration = (props: ITrackIllustrationProps): JSX.Element => { 
    const [thumbnail, setThumbnail] = useState( props.illustration )
    const onChange = (value: string, field: string , data: any) => {
        // console.log( data, value )
        setThumbnail (value )
        props.onChange(value, data.name )
    } 
    return <>
        <FieldInput
            type={SCALAR_TYPES.MEDIA}
            isURLHide
            isFileNameHide
            editable
            height={props.width || "100%"}
            width={props.width || "100%"}
            value={thumbnail}
            onChange={onChange}
            notClear
            rowClassName={" m-0 h-100 " }
            style={props.style}
        /> 
    </>
} 