import { useMainStore } from "@/settings/zustand";
import { LayoutDummy, RoutingDummy } from "../interfaces/dummies";
import { IAppData, ILayoutData, IRoutingData, LayoutsTags } from "../interfaces/layouts";
import { initRoutes } from "./routing"; 
import routingConfig from "../../config/routing.json"

/*
 *  PE Application layout configs
*/
let layout :        ILayoutData   = LayoutDummy();
let routing :       IRoutingData  = RoutingDummy();
let app :           IAppData      = layout.app as IAppData;
let widgetAreas :   any           = {};
let template :      any           = {};
let folders :       any           = {};
let modules :       any           = {};


const getLayout = () => { 
  return useMainStore.getState().layout
}

export const layoutInit = ( layoutSource:any ): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    let widgets         = {} 
    let extentions      = {} 
    let views           = {} 
    let schema          = {} 
    let promises: any[] = []
    Object.keys(layoutSource.modules).forEach((module:string) => {
      const promise:Promise<any> = import( `src/modules/${module}/layouts.json` )
      promises.push(promise)
      promise.then(( response ) => { 
        widgets         = { ...widgets,     ...response.widgets }
        extentions      = { ...extentions,  ...response.extentions }
        views           = { ...views,       ...response.views }
        schema          = { ...schema,      ...response.schema }
      })
    })
    Promise.all(promises).then(() => {
      const routing = initRoutes( routingConfig as IRoutingData ) 
      useMainStore.setState({ 
        layout: { 
          ...layoutSource,
          widgets,
          extentions,
          views,
          schema,
          routing 
        } 
      }) 
      resolve(true)  
    })    
  })
  
};

const Layouts = (): ILayoutData => {
  const layout: ILayoutData = getLayout() 
  if (!layout.app || !layout.template || !layout.routing || !layout.modules || !layout.schema) {
    throw new Error("PE Application need layout config file. See more: ");
  } 
  let l: ILayoutData = { ...layout }
  return l
};

export * from "./app";
export { app_layouts__, app_url, assertion_token__, gitlab_private_token, google_analytics__, initConfig, link_type__, server_url__, sourse_url__, upload_url__, yandex_map_api_key__ } from "./config";
export { initModules } from "./initModules";
export { geoDefaultPosition, geoPosition, yandex_map_api_key, zoom } from "./map";
export * from "./menu";
export * from "./modules";
export * from "./routing";
export * from "./schema/index";
export * from "./template";
export default Layouts;
export const updateRoutes = ( menu: any ) => {
  if (typeof menu === "object") { 
    const layout: ILayoutData = getLayout()
    routing = { 
      ...layout.routing,
      ...menu
    };
  }
};
export const updateApp = ( _app : any ) => {
  if (typeof _app === "object") {
    const layout: ILayoutData = getLayout() 
    app = { ...layout.app,
      ..._app
    };
  }
};
export const updateWidgets = (_widgets : any ) => {
  //console.log(_widgets) 
  if (typeof _widgets === "object") {
    const layout: ILayoutData = getLayout()
    widgetAreas = { ...layout['widget-area'],
      ..._widgets
    };
  }
};
export const updateFolder = (_folder: any) => {
  if (typeof _folder === "object") {
    const layout: ILayoutData = getLayout()
    folders = { ...layout.folders,
      ..._folder
    };
  }

  console.log(folders);
};
export const updateTemplate = (_template:any) => { 
  if (typeof _template === "object") {
    const layout: ILayoutData = getLayout()
    template = { ...layout.template,
      ..._template
    };
  }
};
export const updateLayouts = (sectionName:LayoutsTags, newData: any) => {
  const layout: ILayoutData = getLayout()
  layout[sectionName] = newData;
};
