import React, { Fragment, useRef, useState } from 'react'
import { Button, Collapse, Icon, Intent, Tag } from '@blueprintjs/core'

import Moment from 'react-moment'
import { ICritery, IFestival, IProject, IRating, ITrack } from '../../data/interfaces'
import { IUser } from '@/settings/zustand'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import TrackLabel from '../projectList/TrackLabel'
import { __, sprintf } from '@/libs/utilities'
import FieldInput from '@/libs/scalars'
import { VerticalType } from '@/libs/scalars/interface/IScalarForm'
import MemberLabel from './MemberLabel'
import { getModeratorRoles } from '../../data/utils/getModeratorRoles'
import { isRole, isUserRole } from '@/settings/zustand/user'
import { DownloadTableExcel } from 'react-export-table-to-excel'

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useDataStore } from '@/modules/pe-basic-module/data/store'

interface IProps {
    members: IProject[]
    users: IUser[]
    ratings: IRating[]
}
const ORDER_SORT = "order"
const ALPHABET_SORT = "alphabet"
const SORT_ORDER_ASC = "asc"
const SORT_ORDER_DESC = "desc" 

const FullExpertStatistics = (props:IProps) :JSX.Element => {
    const festival:IFestival = useFestDataStore((state:IFestDataStor) => state.festival )
    const criteries: ICritery[] = useDataStore((state:any) => state.PEFestCritery || [])
    const tracks: ITrack[] = useDataStore((state:any) => state.PEFestTrack || [])
    const [ isRolesOpen,  setRolesOpen  ]   = useState( false )
    const [rolesFilter, setRolesFilter] = useState(["subscriber", "contributor", ...festival.availableRoles!, "administrator", "SuperAdmin"])
    const [sotrField, setSotrField]         = useState( ALPHABET_SORT )
    const [sortOrder, setSortOrder]         = useState( SORT_ORDER_ASC )
    const [tableData, setTabeData]          = useState<any>({})

    const tableRef = useRef(null);

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"; 
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData: any, fileName: string) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const onRolesFilter = (value:[]) =>
    { 
        setRolesFilter( value || [] )
    }
    const onSortOrder = () =>
    {
        setSortOrder( () => sortOrder === SORT_ORDER_ASC ? SORT_ORDER_DESC : SORT_ORDER_ASC )

    }     
    const printIt = () =>
    {
        const restorepage = $('body').html(); 
        $( "fest-header" ).hide()
        $( "fest-phase-labels" ).hide()
        $( "fest-head-switcher" ).hide()
        const printPage = $('body').html()
        $('body').empty().html( printPage )
        window.print();
        $('body').html(restorepage); 
    }
    
    const sortMembers = (a:IUser, b:IUser ) =>
    {
        switch(sotrField)
        {
            case ALPHABET_SORT: 
                const nameA = a.display_name!.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                const nameB = b.display_name!.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                if (nameA < nameB) {
                    return sortOrder === SORT_ORDER_ASC ? -1 : 1
                }
                if (nameA > nameB) {
                    return sortOrder === SORT_ORDER_ASC ? 1 : -1
                }
                return 0
            case ORDER_SORT:
                return sortOrder === SORT_ORDER_ASC
                    ?
                    Number( a.id ) - Number( b.id )
                    :
                    Number( b.id ) - Number( a.id ) 
        }
        return 1
    }

    let usersData = props.users
        .filter(
            user => {
                if(user.roles?.length === 0 )
                {
                    return true
                }
                return rolesFilter.filter(rf => {
                    return user.roles?.filter(uf => {
                        return uf === rf
                    }).length > 0
                }).length > 0
            }
        )
    usersData.sort( sortMembers ) 
    let xls: any[] = []
    const users = usersData.map((user: IUser, i: number) => {
            const myTracks = !!user.roles?.filter(r => r === "Track_moderator").length
                ?
                tracks.filter(track => {
                    const isModerator1 = track.moderator?.id === user.id
                    const isModerator2 = track.moderator2?.id === user.id
                    return isModerator1 || isModerator2
                })
                .map(track => <div className='row dat ' key={track.ID}>
                    <div className='col-md-2 layout-label'>{__("Track_moderator")}:</div>
                    <TrackLabel track={ track } className="title d-flex align-items-center text-nowrap my-2 col-md-7 layout-data"/> 
                </div>)
                :
                null
            const myMembers = !!user.roles?.filter(r => r === "Tutor").length
                ?
                props.members   
                    .filter(member => member.tutor?.id === user.id)
                    .map(member => <div className='row dat ' key={member.id}>
                        <div className='col-md-2 layout-label'>{__("Tutor of project")}:</div>
                        <MemberLabel className="col-md-7 layout-data" member={member} />
                    </div>)
                :
                null
            console.log( props.ratings )
            const ratings = props.ratings
                .filter(rating => {
                    return rating.expertId === user.id
                })
                .map((rating, i) => {
                    return <Fragment key={rating.id}>
                    {
                        i === 0
                            ?
                            <tr className="bg-ight font-weight-light font-italic small">
                                <th scope="col" style={{width:55}} >
                                    {__("#")}
                                </th>
                                <th scope="user" >
                                    {__("time")}
                                </th>
                                <th scope="project" >
                                    {__("Project")}
                                </th>
                                <th scope="critery" >
                                    {__("Critery")}
                                </th>
                                <th scope="rates">
                                    {__("Rates")}
                                </th> 
                                <th scope="commentary">
                                    {__("Commentary")}
                                </th> 
                            </tr> 
                            :
                            null
                    }
                        <tr key={rating.id}>
                            <td>

                            </td>
                            <td>
                                <Moment locale="ru" format="D.MM.YYYY HH:mm">
                                    {rating.unixtime! * 1000}
                                </Moment>
                            </td>
                            <td>
                                {
                                    props.members
                                        .filter( member => {
                                            return member.id === rating.memberId 
                                        })
                                        .map(member => <div key={member.id} className="title" >{member.title}</div>)
                                }
                            </td>
                            <td>
                                {
                                    criteries
                                        .filter( critery => {
                                            return critery.id === rating.criteryId 
                                        })
                                        .map(critery => <div key={critery.id} className="title" >{critery.title}</div>)
                                }
                            </td>
                            <td>
                                <div className='lead title'>{rating.rating}</div>
                            </td>
                            <td>
                                <div className=' description  font-italic'>{rating.description}</div>
                            </td>
                        </tr>
                    </Fragment>
                })
            xls.push({
                "#" : i+1,
                [__("User")] : user.display_name,
                [__("Roles")] : user.roles.map(r => __( r )).join(", ")
            })
            return <Fragment key={ user.id }>
                <tr className='bg-light-strobe  border-top border-secondary border-2'>
                    <td>{i+1}</td>
                    <td colSpan={12}>
                        <div className='lead title py-2 text-md-left text-center max-width-100'>{ user.display_name }</div>
                        <div className=' '> { user.user_email } </div>
                        <div className=' '>
                            { 
                                user.roles.map( role => {
                                    const isModeratorRole:boolean = !!getModeratorRoles().filter(r => r === role).length
                                    return <Tag 
                                        round 
                                        className='px-4 m-1' 
                                        minimal={ !isModeratorRole } 
                                        intent={ !isModeratorRole ? Intent.NONE : Intent.SUCCESS }
                                        key={role}
                                    >
                                        {__(role)}
                                    </Tag>
                                }) 
                            }
                        </div>
                        <div className=' '> { myTracks } </div>
                        <div className=''> { myMembers } </div>
                    </td>
                </tr>
                {ratings}
            </Fragment>
        })
    return ( 
        <div className='w-100'>
            <div className='w-100'>
                <div className='mt-1 mb-3 w-100'>
                    <div className='align-items-center w-100 d-flex justify-content-md-between justify-content-center flex-md-row flex-column'>
                        <div className='flex-centered'>
                            <div className='flex-centered descr text-uppercase text-secondary mr-2'>
                                {__("Filters")}:
                            </div>
                            <Button intent={Intent.SUCCESS} minimal={ !isRolesOpen } onClick={() => setRolesOpen( !isRolesOpen ) }>
                                {__("Roles")} | { rolesFilter.length }
                            </Button> 
                        </div>
                        <div className='d-flex flex-md-row flex-column'>
                            <div className=' flex-centered ml-4 mr-2 descr text-uppercase text-secondary '>
                                {__("Do sort")}:
                            </div> 
                            <Button 
                                intent={Intent.SUCCESS} 
                                minimal={sotrField !== ORDER_SORT}
                                onClick={() => setSotrField(ORDER_SORT)}
                            >
                                {__("By Member's order")}
                            </Button>
                            <Button 
                                intent={Intent.SUCCESS} 
                                minimal={sotrField !== ALPHABET_SORT}
                                onClick={() => setSotrField(ALPHABET_SORT)}
                            >
                                {__("By alphabet")}
                            </Button> 
                            <Button 
                                intent={Intent.SUCCESS} 
                                minimal  
                                onClick={ onSortOrder }
                            >
                                <Icon 
                                    icon={ sortOrder === SORT_ORDER_ASC 
                                            ?
                                            "sort-asc"
                                            :
                                            "sort-desc"
                                    } 
                                    size={20} 
                                />
                            </Button>
                        </div> 

                        <div className='flex-centered'>
                            <div className=' display-6 '>{users.length}</div>
                            <Button minimal className='px-3 py-2 ml-3' onClick={printIt}>
                                <Icon icon="print" size={21} className="hint hint--top" data-hint={__("Print")} />
                            </Button> 
                            {/* <DownloadTableExcel
                                filename="users table"
                                sheet="users"
                                currentTableRef={ tableRef.current }
                            >
                                <Button minimal className='px-3 py-2 '>
                                    <Icon 
                                        icon="th" 
                                        size={21} 
                                        className="hint hint--top pointer" 
                                        data-hint={__("Download XLS")}
                                    />
                                </Button>                            
                            </DownloadTableExcel>  */}
                            <Button minimal className='px-3 py-2 ' onClick={ (e) => exportToCSV(xls, sprintf(__("%s : List of users"), festival.title || __("Festival") )) }>
                                <Icon 
                                    icon="th" 
                                    size={21} 
                                    className="hint hint--top pointer" 
                                    data-hint={__("Download XLS")}
                                />
                            </Button>   
                            
 
                        </div> 
                    </div>
                </div>
                <Collapse isOpen={ isRolesOpen} className=" light-colored px-4">
                    <FieldInput
                        type="tags" 
                        multiple
                        vertical={VerticalType.VERTICAL}
                        editable
                        values={
                            ["subscriber", "contributor", ...festival.availableRoles!, "administrator", "SuperAdmin"].map(role => {
                                return { _id: role, title: __(role), intent: "success" }
                            })
                        }
                        value={rolesFilter}
                        onChange={onRolesFilter}
                        on={onRolesFilter}
                    />
                </Collapse> 
            </div>
            <table 
                className='table table-striped table-hover1 pe-fest-table-statistics w-100' 
                id="users-to-xls" 
                ref={ tableRef }
            >
                <tbody>
                    {users}
                </tbody>
            </table>
        </div>
    )
}

export default FullExpertStatistics