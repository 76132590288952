import { DIALOG_SIZE, ID } from "@/libs/interfaces/layouts"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import UserSearchEngine from "@/libs/useful/UserSearchEngine"
import { __, } from "@/libs/utilities"
import { ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { IUser } from "@/settings/zustand"
import { Button, EditableText, Icon, Popover, Position } from "@blueprintjs/core"
import { useState } from "react"
import { ResourceContent, ResourceIllustration } from "./add-festival-tabs/DestrictCard"

/* Protopia Ecosystem component */
export interface ITrackEditCardProps {
    item: ITrack 
    onDelete: ( id: ID ) => void 
    onChange: ( field: keyof ITrack, value: any, name?: string ) => void
}
const TrackEditCard = (props: ITrackEditCardProps): JSX.Element => { 
    const [isContentEdit, setIsContentEdit] = useState<boolean>( false ) 
    
    const onEditContent = (bool: boolean) => {
        setIsContentEdit(bool)
    }
    const onChangeTitle = (title: string) => {
        props.onChange("title", title)
    }
    const onThumbnail = (thumbnail: string, name:string="" ) => {
        props.onChange( "thumbnail", thumbnail, name )
    } 
    const onIllustration = (illustration: string, name:string="" ) => { 
        props.onChange( "illustration", illustration, name )
        // props.onChange( "illustrationName", name )
    }
    const onChangeContent = (content: string) => {
        props.onChange("content", content)
    }
    return <div className={`pe-track-edit-card-container `}>
        <div className="thumb position-relative" > 
            <ResourceIllustration 
                illustration={props.item?.illustration || ""}
                onChange={onIllustration}
            /> 
            <div className="position-absolute bottom right small bg-dark text-light m-2 py-1 px-2">
                {
                    props.item.order
                }
            </div>
            <div className="position-absolute m-2 rounded-circle bg-light " style={{width: 70, height: 70, padding: 17 }}>
                <ResourceIllustration 
                    illustration={props.item.thumbnail || ""}
                    onChange={onThumbnail}
                    width={35}
                    style={{border: "none"}}
                />
            </div>
        </div>
        <div className="d-flex flex-column flex-grow-100">
            <div className="title"> 
                <EditableText
                    className=" px-3 py-1 track-edit-title"
                    alwaysRenderInput={true} 
                    disabled={false}
                    placeholder="Edit title..."
                    selectAllOnFocus={true} 
                    value={props.item.title}
                    onChange={onChangeTitle}
                /> 
            </div>
            <ResourceContent
                content={props.item.content}
                isContentEdit={isContentEdit}
                onChangeContent={onChangeContent}
                onEditContent={onEditContent}
            />            
            <div className="footer"> 
                <ConfirmButton
                    buttonIcon="trash"
                    onConfirm={( ) => props.onDelete( props.item.id )}
                    buttonLabel={<div className="d-md-flex d-none ">{__("Delete")}</div>}
                    buttonMinimal
                    dialogClasssName={DIALOG_SIZE.MINIMAL}
                >
                    <div className="p-4">
                        {__("Really delete?")}
                    </div>
                </ConfirmButton> 
            </div>
        </div>        
    </div> 
    // const [available, setAvailable ] = useState<boolean>( !!props.item.moderator?.id && !!props.item.moderator2?.id )
    // const [isContentEdit, setIsContentEdit] = useState<boolean>( false )
    // const festival: IFestival = useFestExport((state : any) => state.festival)
    // useEffect(() => {
    //     setAvailable( !!props.item.moderator?.id && !!props.item.moderator2?.id )
    // }, [props.item.moderator, props.item.moderator2])
    // const onTrackExpert = (user: IUser) => {
    //     props.onChange("moderator", user)
    // } 
    // const onTrackExpert2 = (user: IUser) => {
    //     props.onChange("moderator2", user)
    // } 
    // const onEditContent = (bool: boolean) => {
    //     setIsContentEdit(bool)
    // }
    // const onChangeTitle = (title: string) => {
    //     props.onChange("title", title)
    // }
    // const onThumbnail = (thumbnail: string, name:string="" ) => {
    //     props.onChange( "thumbnail", thumbnail )
    //     props.onChange( "thumbnailName", name )
    // }
    // const onIllustration = (illustration: string, name:string="" ) => {
    //     // console.log( illustration, name )
    //     props.onChange( "illustration", illustration )
    //     props.onChange( "illustrationName", name )
    // }
    // const onChangeContent = (content: string) => {
    //     props.onChange("content", content)
    // }
    // return <div className={`pe-track-edit-card-container ${ available ? "" : " no-evailabled"}`}>
    //     <div className="thumb position-relative" > 
    //         <ResourceIllustration 
    //             illustration={props.item.illustration || ""}
    //             onChange={onIllustration}
    //         /> 
    //         <div className="position-absolute m-2 rounded-circle bg-light p-2" style={{width: 50, height: 50}}>
    //             <ResourceIllustration 
    //                 illustration={props.item.thumbnail || ""}
    //                 onChange={onThumbnail}
    //                 width={50}
    //             />
    //         </div>
    //         <div className="position-absolute bottom right small bg-dark text-light m-2 py-1 px-2">
    //             {
    //                 props.item.order
    //             }
    //         </div>
    //     </div>
    //     <div className="d-flex flex-column flex-grow-100">
    //         <div className="title"> 
    //             <EditableText
    //                 className=" px-3 py-1 track-edit-title"
    //                 alwaysRenderInput={true} 
    //                 disabled={false}
    //                 placeholder="Edit title..."
    //                 selectAllOnFocus={true} 
    //                 value={props.item.title}
    //                 onChange={onChangeTitle}
    //             />  
    //         </div>
    //         <ResourceContent
    //             content={props.item.illustration + " \n\n " + props.item.thumbnail }
    //             isContentEdit={isContentEdit}
    //             onChangeContent={onChangeContent}
    //             onEditContent={onEditContent}
    //         /> 
    //         <div className="footer">
    //             <TrackExpert 
    //                 user={props.item.moderator}  
    //                 onChange={onTrackExpert}
    //                 title={ __("Track expert") }
    //                 festID={festival?.id || "-1"}
    //             />
    //             <TrackExpert 
    //                 user={props.item.moderator2} 
    //                 onChange={onTrackExpert2}
    //                 title={ __("Track moderator assistant") }
    //                 festID={festival?.id || "-1"}
    //             /> 
    //             <ConfirmButton
    //                 buttonIcon="trash"
    //                 onConfirm={props.onDelete}
    //                 buttonLabel={<div className="d-md-flex d-none ">{__("Delete")}</div>}
    //                 buttonMinimal
    //             >
    //                 <div className="p-4">
    //                     {__("Really delete?")}
    //                 </div>
    //             </ConfirmButton> 
    //         </div>
    //     </div>        
    // </div>
}
export default TrackEditCard

interface ITrackExpertProps {
    user: IUser | null
    festID : ID
    onChange : (user: IUser) => void
    title: string
}
export const TrackExpert = (props: ITrackExpertProps): JSX.Element => {
    return <Popover
        position={Position.TOP}
        content={<div className="p-3">
            <UserSearchEngine 
                user={props.user}
                onSelect={props.onChange} 
                minWidth={340}
                landId={props.festID}
            />
        </div>}
    >  
        <Button minimal > 
        {
            props.user?.id 
                ? 
                <div className="d-flex align-items-center">
                    <img src={props.user.avatar} height={27} alt="ava" className="user-ava"/>
                    <div className="ml-0" >
                        {props.user.display_name}
                    </div>
                </div>  
                : 
                <div className="d-flex align-items-center">
                    <Icon icon="person" className="mr-2" size={20}/>
                    { props.title }
                </div>
        }
        </Button>
    </Popover>
}  
