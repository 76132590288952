import React from 'react' 
import { getVisibleValue, schema } from 'src/libs/layouts'
import { SCALAR_TYPES } from '@/libs/scalars'
import { Tag } from '@blueprintjs/core'
import { __ } from '@/libs/utilities'

const DataTableCellFooter = (props: any) :JSX.Element => {
    const apollo_fields = schema()[props.data_type].apollo_fields
    let fieldNames: any = { }
    const tags = Object.keys( apollo_fields )
        .filter( (ee: any)  => {
            return apollo_fields[ee].field_footer === props.thread 
        }) 
        .map( (ee: any) => { 
            const fName: string = apollo_fields[ee].title
            if( !fieldNames[ fName ] ) fieldNames[ fName ] = []
            fieldNames[ fName ] = ( props.item[ ee ] )  
            if([ "string", "number" ].includes( apollo_fields[ee].type )) {
                return props.item[ ee ] && <div className='d-flex flex-wrap small align-items-center mr-3' key={ee}>
                    <div className='pr-1  '>
                        { __( fName ) }
                    </div>  
                    <Tag 
                        round 
                        minimal
                        className='m-1 px-3 pointer'  
                        title={ props.item[ ee ] }
                    >
                        <div className='small text-wrap title' style={{ color: apollo_fields[ee].tag_footer_color }}>
                        { 
                            props.item[ ee ]   
                        }
                        </div>
                    </Tag>
                </div>
            }
            else {
                return <CellFooterTags
                    key={ee}
                    fName={fName} 
                    list={ 
                        Array.isArray( props.item[ ee ] ) 
                            ? 
                            props.item[ ee ] 
                            : 
                            [ 
                                props.item[ ee ] && 
                                props.item[ ee ][ getVisibleValue( apollo_fields[ee].component ) ] 
                            ] 
                    } 
                    bg={ apollo_fields[ee].tag_footer_color }
                    component={ apollo_fields[ee].component } 
                />
            } 
        })
    
    return <div  className=' mt-2 '> 
        { tags }
    </div> 
} 

export default DataTableCellFooter

const CellFooterTags = (props:any): JSX.Element | null => { 
    // return <>..{JSON.stringify( props.list )}..</>

    return  !!props.list.filter((l: any) => l !== null ).length 
        ?
        <div className='d-flex flex-wrap small align-items-center mr-3'>
            <div className='pr-1  '>
                { __(props.fName) }
            </div>  
            {
                props.list
                    .filter(( tag: any ) => !!tag )
                    .map( (   tag: any ) => <CellFooterTagType 
                        key={tag} 
                        tag={tag} 
                        component={props.component} 
                        bg={props.bg} 
                    /> )
            }
        </div>
        :
        null 
}

const CellFooterTagType = (props:any): JSX.Element => {
    //return <>.. {JSON.stringify( props )} ..</>
    switch(props.component) {
        case SCALAR_TYPES.STRING:
            return <Tag 
                round 
                className='m-1 px-3 pointer' 
                style={{ backgroundColor: props.bg, maxWidth:290 }}
                title={ props.tag }
            >
                {props.tag}
            </Tag>
        default:
            return <Tag 
                round 
                className='m-1 px-3 pointer' 
                style={{ backgroundColor: props.bg, maxWidth:290 }}
                title={ props.tag }
            >
                {
                    !!props.tag?.__typename
                        ?
                        props.tag.title
                        :
                        props.tag?.toString() 
                }
            </Tag>

    }
}