import FieldInput from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { Button } from "@blueprintjs/core"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IDataTableSearchPanelProps {
   search:string
   onSearch: ( search:string ) => void
}
const DataTableSearchPanel = ({search, onSearch}: IDataTableSearchPanelProps): JSX.Element => {
    const [s, onS] = useState<string>("")
    return <div className='d-flex'>
        <FieldInput
            vertical={ VerticalType.VERTICAL }
            value={ s }
            onChange={ v => onS(v) }
            editable
            onClear={ () => {
                onS( "" )
                onSearch( "" ) 
            }}
        />
        <Button
            minimal
            className="ml-2"
            icon="search" 
            onClick={ () => onSearch( s ) }
        /> 
    </div>
}
export default DataTableSearchPanel