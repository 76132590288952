import { IUser } from "src/settings/zustand" 
import { useMainStore } from "src/settings/zustand"
import { IDestrict, IGanre, IHoneycombs, IPEFestSchool, IProject, ITrack } from "../interfaces"
import { ID } from "@/libs/interfaces/layouts"
import { useFestMyProjects } from "../store"
import { isRole } from "@/settings/zustand/user"
import { useDataStore } from "@/modules/pe-basic-module/data/store"

function isOwnerTrack( track: ITrack): boolean {
    if(!isRole("Track_moderator")) return false
    const user: IUser = useMainStore.getState().user
    return user.id === track?.moderator?.id || user.id === track?.moderator2?.id 
}

function isMyTrack (track:ITrack) {
    const myProjects: IProject[] = useFestMyProjects.getState().myProjects
    return myProjects.some(( p:IProject ) => {
        return p.track?.id === track.id
    })
}
function isMyDestrict (destrict: IDestrict): boolean {
    const myProjects: IProject[] = useFestMyProjects.getState().myProjects
    const schools: IPEFestSchool[] = useDataStore.getState().PEFestSchool || []
    return !!myProjects.filter(( p:IProject ) => {
        return p.fmru_school && !!p.fmru_school.filter( s => { 
            // console.log( schools.filter( ss => ss.id === s.id ) )
            return schools.filter( ss => ss.id === s.id )[0]?.destrict?.map( d => d.id ).includes( destrict.id )
        }).length            
    }).length
}
function isMySchool (school: IPEFestSchool):boolean {
    const myProjects: IProject[] = useFestMyProjects.getState().myProjects
    return myProjects.some(( p:IProject ) => {
        return p.fmru_school.filter(s => s.id === school.id)
    })
}


function isMyHoneycombs ( honeycombs:IHoneycombs ) {
    const myProjects: IProject[] = useFestMyProjects.getState().myProjects
    return myProjects.some(( p:IProject ) => {
        return p.honeycombs?.id === honeycombs.id
    })
}

function isOwnerHoneycombs( honeycombs: ID): boolean {
    const user: IUser = useMainStore.getState().user
    //return user.id === honeycombs.id
    return true
}

function isOwnerGanre( ganre: IGanre): boolean {
    const user: IUser = useMainStore.getState().user
    return user.id === ganre.id
}

export { isOwnerTrack, isOwnerHoneycombs, isOwnerGanre, isMyTrack, isMyDestrict, isMySchool, isMyHoneycombs }