import { useParams } from "react-router"
import { useFestival } from "../../hooks/festival"
import { ClipboardCopyBtn, Loading } from "@/libs/useful"
import { useDataStore } from "@/modules/pe-basic-module/data/store" 
import { ICategory, ICritery, IGanre, IMilestone } from "../../data/interfaces"
import { __ } from "@/libs/utilities"
import { Callout, Tag } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface IRateSystemFormProps {
    parentMode?: boolean
}
const RateSystemForm = (props: IRateSystemFormProps): JSX.Element => {
    const params = useParams() 
	const {landId} = params 
    const isLoading = useFestival(!props.parentMode, landId)
    const PEFestMilestone :IMilestone[] = useDataStore((state:any) => state.PEFestMilestone) || []
    const PEFestCategory:ICategory[]    = useDataStore((state:any) => state.PEFestCategory) || []
    const PEFestCritery:ICritery[]      = useDataStore((state:any) => state.PEFestCritery) || []
    const PEFestGanre:IGanre[]          = useDataStore((state:any) => state.PEFestGanre) || []
    if(isLoading) return <Loading />
 
    return <div className='pe-rate-systemform-container short-container mt-5 '>
        
        <div className="display-6 my-2">{__("Categories")}</div>
        <div className="mb-5">
        {
            !!PEFestCategory.length 
                ?
                PEFestCategory
                .map(cat => {
                    return <div className="row strobe-1 pb-1" key={cat.id}>
                    <div className="col-md-1 lead">
                        {cat.order }
                    </div>
                        <div className="col-md-11">
                            <h4>
                                {cat.title}
                            </h4>
                        </div>
                    </div>
                })
                :
                <Callout className="p-4">{__("No elements exists")}</Callout>
        }    
        </div>
        <div className="display-6 my-2">{__("Milestones")}</div>
        <div className="mb-5">
        {
            !!PEFestMilestone.length 
                ?
                PEFestMilestone
                .map(cat => {
                    return <div className="row strobe1 pb-1" key={cat.id}>
                        <div className="col-md-1 lead">
                            {cat.order }
                        </div>
                        <div className="col-md-11 ">
                            <h4>
                                {cat.title}
                            </h4>
                            <div className="">
                                {cat.post_content}
                            </div>
                        </div>
                    </div>
                })
                :
                <Callout className="p-4">{__("No elements exists")}</Callout>
        }    
        </div>
        <div className="display-6 my-2">{__("Criteries")}</div>
        <div className="mb-5">
            {
                !!PEFestCritery.length
                    ?
                    PEFestCritery
                    // .sort( (a:ICritery, b: ICritery) : number => {
                    //     return a.milestone?.order < b.milestone?.order 
                    //         ?
                    //         -1
                    //         :
                    //         a.milestone?.order === b.milestone?.order
                    //             ?
                    //             0
                    //             :
                    //             1 
                    // })
                    // .sort( (a:ICritery, b: ICritery) : number => {
                    //     return a.order < b.order 
                    //         ?
                    //         -1
                    //         :
                    //         a.order === b.order
                    //             ?
                    //             0
                    //             :
                    //             1 
                    // })
                    .map(cr => {
                        return <div className="row strobe" key={cr.id}>
                            <div className="col-md-1 lead">
                                {cr.order }
                            </div>
                            <div className="col-md-7 p-2">
                                <h4>
                                    {cr.title}
                                </h4>
                                <div className="">
                                    {cr.post_content}
                                </div>
                            </div>
                            <div className="col-md-4 p-2">
                                <div className="mb-2">
                                {
                                    cr.milestone && <Tag minimal round className="m-1 px-3">{ cr.milestone.title }</Tag>
                                }
                                </div> 
                                <div className="mb-2">
                                {
                                    cr.category && <Tag style={{ backgroundColor: cr.category?.color.toString() }} round className="m-1 px-3">{ cr.milestone.title }</Tag>
                                }
                                </div> 
                            </div>
                        </div>
                    })
                    :
                    <Callout className="p-4">{__("No elements exists")}</Callout>
            }
        </div>
        <div className="my-4 w-100 flex-centered">
            <ClipboardCopyBtn
                type="button"
                data={{
                    PEFestCategory,
                    PEFestGanre,
                    PEFestMilestone,
                    PEFestCritery,
                }}
                label={ __("Copy rate system data to clipboard").toUpperCase() }
            />
        </div>
    </div>
}
export default RateSystemForm