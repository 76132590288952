import { WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import useScreenSize from "../../hooks/useScreenSize"
import LoginRegisterScreen from "./LoginRegisterScreen"
import LoginRegisterMobile from "./LoginRegisterMobile"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import LogoutForm from "./LogoutForm"

/* Protopia Ecosystem component */
export interface ILoginRegisterFormProps {
    extend_params?: any
}
const LoginRegisterForm = (props: ILoginRegisterFormProps): JSX.Element => {
    const {width} = useScreenSize()
    const user:IUser = useMainStore((state:IState) => state.user)
    return !user.id
        ?
        width > WINDOW_WIDTH.TABLET
            ?
            <LoginRegisterScreen {...props} />
            :
            <LoginRegisterMobile {...props}/>
        :
        <LogoutForm />
}
export default LoginRegisterForm