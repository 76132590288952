import { IDictionary } from '@/libs/interfaces/layouts'
import { layoutInit } from '@/libs/layouts'
import { initDictionary } from '@/libs/utilities'
import { useMainStore } from '@/settings/zustand'
import { BULK_DOUBLE_ACTION, DELETE_ALL_LOGS_ACTION, DELETE_LOG_FILE_ACTION, ERASE_TRASH_LOG_FILE_ACTION, GET_LOG_FILES, GET_OPTIONS_ACTION, GET_SIGN_TYPE, GET_TRASH_FILES_ACTION, PREVIEW_LAYOUT_ACTION, RESTORE_LOG_FILE_ACTION, SET_SIGN_TYPE, UPDATE_DICTIONARY_ACTION, UPDATE_OPTIONS_ACTION, UPDATE_SINGLE_TYPE } from './actionTypes'
import asyncControllers from './controller'
import { IAdminStateProps, SIGN_TYPE } from './interfaces'
import { useAdminStore } from './store'

export const actions = (action: string, data: any): Promise<any> => {
    let promise: Promise<any> = new Promise<any>((resolve) => resolve(null))
    let signType: SIGN_TYPE
    switch (action) {
        case SET_SIGN_TYPE:
            promise = new Promise((resolve) => {
                signType = data || localStorage.getItem("cabinet_sign_type") as SIGN_TYPE
                useAdminStore.getState().setSignType(signType)
                localStorage.setItem("cabinet_sign_type", signType)
                resolve(signType)
            })
            return promise
        case UPDATE_SINGLE_TYPE:
            promise = new Promise((resolve) => {
                asyncControllers(action, data).then(response => {
                    resolve(data)
                })
            })
            return promise
        case GET_SIGN_TYPE:
            promise = new Promise((resolve) => {
                signType = useAdminStore((state: IAdminStateProps) => state.signType)
                localStorage.setItem("cabinet_sign_type", signType)
                resolve(signType)
            })
            return promise
        case GET_OPTIONS_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => {
                        console.log( response )
                        resolve( response.data.getOptions )
                    },
                    (error: any) => { 
                        reject( error )
                    }
                )
            })
            return promise
        case PREVIEW_LAYOUT_ACTION: 
            promise = new Promise((resolve, reject) => { 
                //useMainStore.setState({layout: data})
				layoutInit( data )
                resolve(true)
            })
            return promise
        case UPDATE_DICTIONARY_ACTION: 
            promise = new Promise((resolve, reject) => { 
                // TODO:: update by controller 
                asyncControllers( action, data ).then(                    
                    (response : any) => {
                        //console.log( response.data.changeDictionary.dictionary )
                        try {
                            const dictionary: IDictionary = JSON.parse(response.data.changeDictionary.dictionary)
                            useMainStore.setState({ dictionary })
                            useMainStore.setState({ dictionary_version: useMainStore.getState().dictionary_version + 1 })
                            initDictionary("ru-RU", {})
                        }
                        catch(e) {}
                        finally {
                            resolve( response.data.changeOptions )
                        }                       
                        
                    },
                    (error: any) => { 
                        reject( error )
                    }
                )
                resolve( data.dictionary )
            })
            return promise
        case UPDATE_OPTIONS_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers( action, data ).then(                    
                    (response : any) => {
                        console.log( response )
                        resolve( response.data.changeOptions )
                    },
                    (error: any) => { 
                        reject( error )
                    }
                )
            })
            return promise
        case GET_LOG_FILES:
            promise = new Promise((resolve, reject) => { 
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        resolve( response.data.getLogFiles )
                    },
                    (error: any) => { 
                        reject( error )
                    }
                )
            })
            return promise
        case GET_TRASH_FILES_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        resolve( response.data.getTrashLogFiles )
                    },
                    (error: any) => { 
                        reject( error )
                    }
                )
            })
            return promise
        case DELETE_LOG_FILE_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        resolve( response.data.getTrashLogFiles )
                    },
                    (error: any) => { 
                        reject( error )
                    }
                )
            })
            return promise
        case RESTORE_LOG_FILE_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        resolve( response.data.restoreLogFile )
                    },
                    (error: any) => { 
                        reject( error )
                    }
                )
            })
            return promise
        case ERASE_TRASH_LOG_FILE_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        resolve( response.data.deleteTrashLogFile )
                    },
                    (error: any) => { 
                        reject( error )
                    }
                )
            })
            return promise
        case DELETE_ALL_LOGS_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        resolve( response.data.deleteAllLogFiles )
                    },
                    (error: any) => { 
                        reject( error )
                    }
                )
            })
            return promise
        case BULK_DOUBLE_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        resolve( response.data[`duplicateBulk${data.data_type}`])
                    },
                    (error: any) => { 
                        reject( error )
                    }
                )
            })
            return promise
        default:
            return promise
    }

} 