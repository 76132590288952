import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { AppToaster } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { useFestExport } from "@/modules/pe-fest-module/data/store"
import { Button, Intent } from "@blueprintjs/core"
import JSZip from 'jszip'
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IPELandZipProps {
     
}
const PELandZip = (props: IPELandZipProps): JSX.Element => {
    const [file, setFile] = useState<any>()
    const onFile = (value:string, name: string, file: any) => { 
        setFile(file)
    }
    const onUnzip = () => {
        /* https://stuk.github.io/jszip/documentation/examples/read-local-file-api.html */
        var new_zip = new JSZip()
        new_zip.loadAsync(file)
            .then((zipped) => {
                zipped.file("data.json")?.async("string")
                    .then((text: string) => {
                        const json: any = JSON.parse( text )
                        console.log( json )
                        Object.keys(json).forEach(key => {
                            if(Array.isArray(json[key])) {
                                useFestExport.setState({
                                    [key] : json[key]
                                })
                            }
                            else {
                                useFestExport.setState({
                                    [key] : json[key].map( (m: any, i: number) => ({ ...m, order: i+1, id: m.ID})) 
                                })
                            }
                        })
                    })
                    .catch(err => {
                        console.log( err )
                        AppToaster.show({
                            message: <>
                                <div className="lead">{__("Invalid file content format")}</div>
                                <div className="mt-1">{__("Check the correctness of the recorded data or recreate the *peland file")}</div>
                            </>,
                            className: "p-3 flex-centered", 
                            intent: Intent.DANGER,
                            timeout: 10000
                        })
                    })
            }) 
            .catch(err => {
                console.error( err )
                AppToaster.show({
                    message: __("Invalid file format"),
                    className: "p-5 lead", 
                    intent: Intent.DANGER 
                })
            })
    }
    return <div className='pe-peland-zip-container'>
       <div className="p-4 w-100">
            <FieldInput
                type={ SCALAR_TYPES.UPLOAD }
                value={ file }
                editable 
                accept=".peland"
                vertical={ VerticalType.HORIZONTAL }
                title={ __( "get *.peland scenario file" ) }
                limitSize={ 60000 }
                onChange={ onFile }
                // onClear={ () => setFile(null) }
            />     
        </div>
        <div className="w-100 flex-centered">
            <Button large minimal intent={Intent.DANGER} onClick={onUnzip}>
                {__("Import source")}
            </Button>
        </div>
    </div>
}
export default PELandZip