import { __ } from "@/libs/utilities"
import { Button } from "@blueprintjs/core"
import { actions } from "../../data/actions"
import { ERASE_TRASH_LOG_FILE_ACTION, RESTORE_LOG_FILE_ACTION } from "../../data/actionTypes"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { DIALOG_SIZE } from "@/libs/interfaces/layouts"

/* Protopia Ecosystem component */
export interface ITrashLogItemProps {
   item: any
   onRefresh: () => void
}
const TrashLogItem = (props: ITrashLogItemProps): JSX.Element => {
    const  onRestore = () => {
        actions(RESTORE_LOG_FILE_ACTION, {file_name: props.item.file_name})
            .then(() => props.onRefresh())
    }
    const  onErase = () => {
        actions(ERASE_TRASH_LOG_FILE_ACTION, {file_name: props.item.file_name})
        .then(() => props.onRefresh())
    }
    return <div className='container p-2 d-flex'>
        <div className="row w-100" >
            <div className="col-md-7">
                { props.item.file_name }
            </div>
            <div className="col-md-5 d-flex flex-wrap justify-content-end">
                <Button minimal icon="redo" onClick={onRestore}>
                    {__("Restore")}
                </Button>
                <ConfirmButton 
                    buttonMinimal 
                    buttonIcon="eraser"
                    buttonLabel={__("Erase")}
                    dialogClasssName={DIALOG_SIZE.MINIMAL}
                    onConfirm={onErase}
                >
                    <div className="p-5 text-center">
                        {__("Are you want delete log finally?")}
                    </div>
                </ConfirmButton>
            </div>
        </div>
    </div>
}
export default TrashLogItem