import { IDestrict } from "@/modules/pe-fest-module/data/interfaces"

/* Protopia Ecosystem component */
export interface ISchoolDestrictProps {
   item: IDestrict | undefined
}
const SchoolDestrict = (props: ISchoolDestrictProps): JSX.Element => {
    console.log( props.item )
    return props.item 
        ? 
        <div className=' p-3 w-100 d-flex align-items-center '>
            <div className={ ` mr-3` } style={{ width: 50, minWidth: 50 }}>
                <div 
                    className='my-avatar-cont'
                    style={{
                        backgroundImage: `url(${ props.item.thumbnail } `,
                        backgroundColor: '#555555',
                        width:50,
                        height: 50
                    }}
                />
            </div> 
            <div className="title">
                {props.item.title}
            </div>
        </div>
        :
        <></>
}
export default SchoolDestrict