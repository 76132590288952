import { DIALOG_SIZE, WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import { AppToaster } from "@/libs/useful"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __, sprintf } from "@/libs/utilities"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import { ADD_EVENT_MODE, ILand } from "@/modules/pe-fest-module/data/interfaces"
import { useFestExport } from "@/modules/pe-fest-module/data/store"
import { Button, ButtonGroup, Collapse, Intent } from "@blueprintjs/core"
import { useState } from "react"
import PatternFestivalChooser from "./add-festival-tabs/PatternFestivalChooser"
import PELandZip from "./PELandZip"

/* Protopia Ecosystem component */
export interface ISourceContentProps {
    mode: ADD_EVENT_MODE
    onSetMode : (mode: ADD_EVENT_MODE) => void
    onLand: (land:ILand) => void 
}
 

const SourceContent = ({mode, onSetMode, onLand,  ...props }: ISourceContentProps): JSX.Element => { 
    const {width} = useScreenSize()
    const land:ILand            = useFestExport((state: any) => state.land) 
    const [selectLand, setSelectLand] = useState<ILand>( {id:-1} as ILand )
    const onClean = () => {
        useFestExport.getState().clear()         
        AppToaster.show({
            message: __("Clear Event data successfully")
        })
    }
    const onChooseLand = (land: ILand) => {
        setSelectLand(land)
        AppToaster.show({
            message: sprintf(__("Chose %s. Import source now"), land.name)
        })
    }
    const onChooseCurrent = () => { 
        onLand( selectLand )
    }
    const fill = width < WINDOW_WIDTH.TABLET
    return <div className='pe-source-content-container w-100'>
        <div className="lead text-center my-3">
            {__("Choose source for create new Event")}
        </div>
        <ButtonGroup className="w-100 flex-centered flex-md-row flex-column" >
            <Button fill={ fill } className="m-0 rounded-md-pill px-3" minimal={mode !== ADD_EVENT_MODE.NOTHING} onClick={()=> onSetMode(ADD_EVENT_MODE.NOTHING)}>
                { __("pure") }
            </Button> 
            <Button fill={ fill } className="m-0 rounded-md-pill px-3" minimal={mode !== ADD_EVENT_MODE.LAND} onClick={()=> onSetMode(ADD_EVENT_MODE.LAND)}>
                { __("from this Landscape") }
            </Button> 
            <Button fill={ fill } className="m-0 rounded-md-pill px-3" minimal={mode !== ADD_EVENT_MODE.FILE} onClick={()=> onSetMode(ADD_EVENT_MODE.FILE)}>
                { __("from file") }
            </Button> 
        </ButtonGroup>
        <Collapse isOpen={mode === ADD_EVENT_MODE.NOTHING} className="w-100 "> 
            <div className="w-100 flex-centered py-4">
                <ConfirmButton
                    buttonLarge
                    buttonLabel={__("Clean all data")}
                    buttonIntent={Intent.DANGER}
                    buttonClassName="px-4 py-3"
                    onConfirm={onClean}
                    dialogClasssName={DIALOG_SIZE.MINIMAL}
                >
                    <div className="p-4">
                        {__("Really clear all data?")}
                    </div>
                </ConfirmButton> 
            </div>
        </Collapse>
        <Collapse isOpen={mode === ADD_EVENT_MODE.LAND} className="w-100 "> 
            <div className="py-4">
                <PatternFestivalChooser onChoose={ onChooseLand } festival={ land }  />
                <div className={`w-100 flex-centered ${ !!selectLand && selectLand?.id !== -1 ? "" : "d-none" }`}>
                    <Button large minimal intent={Intent.DANGER} onClick={ onChooseCurrent }>
                        {__("Import source")}
                    </Button>
                </div>
            </div> 
        </Collapse>
        <Collapse isOpen={mode === ADD_EVENT_MODE.FILE} className="w-100 "> 
            <div className="py-4">
                <PELandZip />
            </div>
        </Collapse> 
    </div>
}
export default SourceContent