import { IUser } from "@/settings/zustand"
import { __, wordTrim } from "@/libs/utilities"
import { Button, EditableText } from "@blueprintjs/core"
import ConfirmButton from "@/libs/useful/ConfirmButton"

/* Protopia Ecosystem component */
export interface IUserEditCardProps {
    item: IUser
    onDelete?: () => void
}
const UserEditCard = (props: IUserEditCardProps): JSX.Element => {
    return <div className='pe-project-edit-card-container'>
        <div 
            className="thumb"
            style={{ backgroundImage:`url(${props.item.avatar})` }}
        >
            
        </div>
        <div className="d-flex flex-column flex-grow-100">
            <div className="title"> 
                <EditableText
                    className=" px-3 py-1 w-100"
                    alwaysRenderInput={false} 
                    disabled
                    placeholder="Edit title..."
                    selectAllOnFocus={false} 
                    value={props.item.display_name}
                /> 
            </div>
            <div 
                className="content" 
                dangerouslySetInnerHTML={{__html: wordTrim( props.item?.post_content || "", 50 ) }}
            />
            <ConfirmButton
                buttonIcon="trash"
                onConfirm={ () => props.onDelete && props.onDelete() }
                buttonLabel={<div className="d-md-flex d-none ">{__("Delete")}</div>}
                buttonMinimal
            >
                <div className="p-4">
                    {__("Really delete?")}
                </div>
            </ConfirmButton> 
        </div> 
    </div>
}
export default UserEditCard