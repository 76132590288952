import { __ } from "@/libs/utilities"
import { ILand } from "@/modules/pe-fest-module/data/interfaces"
import { useFestExport } from "@/modules/pe-fest-module/data/store"
import FestivalSiteGalleryForm from "../../FestivalSiteGalleryForm"


/* Protopia Ecosystem component */
export interface IEventTabProps { 
    
}
const EventSettingsTab  = (props: IEventTabProps): JSX.Element => {
    const land: ILand = useFestExport((state: any) => state.land )   
    const setLand = ( _land: ILand ) => {
        useFestExport.setState({ land: _land })
    }

    const setGallery = (value: string[], names: string[] ) => {
        setLand({
            ...land,
            images: value,
            imageNames: names
        })
    }
    
    return <div className="row mt-4">
        
        <div className="lead mb-3 mt-4 col-12">{__("Set gallery")}</div>
        <div className="col-12">
            <FestivalSiteGalleryForm item={land} onChange={setGallery} />
        </div>
    </div>
}
export default EventSettingsTab