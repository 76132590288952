import { DIALOG_SIZE, ID } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import { IMilestone } from "@/modules/pe-fest-module/data/interfaces"
import { useFestExport } from "@/modules/pe-fest-module/data/store"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface ICriteryMilestoneBtnProps { 
    milestone: IMilestone
    onChoose: (item: IMilestone) => void
}
const CriteryMilestoneBtn = ({onChoose,  milestone}: ICriteryMilestoneBtnProps): JSX.Element => { 
    const allMilestones: IMilestone[]   = useFestExport((state: any) => state.milestones) || []
    //const allCategories: ICategory[]    = useFestExport((state: any) => state.categories) || [] 

    const [value, setValue] = useState<IMilestone>( milestone )
    
    const onValue = (value: ID ) => {
        console.log( value )
        setValue(allMilestones.filter(m => m.id === value)[0])
    }
    return <div className='pe-critery-milestone-btn-container'>
        <ConfirmButton
            buttonLabel={
                <div className="flex-centered hint hint--top" data-hint={__("Milestone")}>
                    <div className="d-flex d-md-none pe-fest-resource-icon color-force-contrast" style={{backgroundImage:`url(/assets/img/fest/milestone-icon.svg)`}} /> 
                    {
                        value?.title ? value.title : "---"
                    }
                </div>
            }
            buttonMinimal
            dialogClasssName={DIALOG_SIZE.MINIMAL}
            onConfirm={() => onChoose( value ) }
        >
            <div className="p-4">
                <FieldInput
                    editable
                    value={value}
                    vertical={VerticalType.VERTICAL}
                    onChange={onValue}
                    type={SCALAR_TYPES.TAGS}
                    component="PEFestMilestone"
                    values={ allMilestones.map(m => ({...m, _id: m.ID })) }
                />
            </div>
        </ConfirmButton>
    </div>
}
export default CriteryMilestoneBtn