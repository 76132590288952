import { IProject } from "../interfaces"

interface IMemberByCriteryProps {
    members: IProject[]
}
const MemberRatingByCriteries =  ( props: any )=> {

    // const average_rating = props.members?.reduce(( prev: number, current: any ) => {
    //     return prev + current.average_critery_rating
    // }, 0)

    // return average_rating
    return 0
}
export default MemberRatingByCriteries
