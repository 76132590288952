import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { __ } from "@/libs/utilities"
import { ILand } from "@/modules/pe-fest-module/data/interfaces"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IImportFromLandFormProps { 
   onSelect: ( lands: ILand ) => void 
}
const ImportFromLandForm = ({ onSelect }: IImportFromLandFormProps): JSX.Element => {
    const [select, setSelect] = useState<ILand>( {} as ILand )
    const onChange = ( value: ILand ) => {
        setSelect( value )
        onSelect(value )
    }
    return <div className='pe-import-from-land-form-container p-5'>
        <FieldInput
            type={SCALAR_TYPES.EXTERNAL}
            editable
            value={select}
            component="Land"
            onChange={onChange}
            vertical={VerticalType.VERTICAL}
            title={__("Choose Event to import all Destricts")}
            seed="import-from-land"
            placeholder={__("Choose Land")}
        />
    </div>
}
export default ImportFromLandForm