import { IFestival, IProject } from "@/modules/pe-fest-module/data/interfaces"
import { __, wordTrim } from "@/libs/utilities"
import { Button, EditableText } from "@blueprintjs/core"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { ResourceContent, ResourceIllustration } from "./add-festival-tabs/DestrictCard"
import { useState } from "react"
import { TrackExpert } from "./TrackEditCard"
import { useFestExport } from "@/modules/pe-fest-module/data/store"
import { IUser } from "@/settings/zustand"

/* Protopia Ecosystem component */
export interface IProjectEditCardProps {
    item: IProject
    onDelete: () => void
    onChange: ( field: string, value: any, name?: string  ) => void
}
const ProjectEditCard = (props: IProjectEditCardProps): JSX.Element => {
    const festival: IFestival = useFestExport((state : any) => state.festival)
    const [isContentEdit, setIsContentEdit] = useState<boolean>( false ) 

    const onEditContent = (bool: boolean) => {
        setIsContentEdit(bool)
    }
    const onThumbnail = (thumbnail: string, name:string="") => {
        props.onChange( "thumbnail", thumbnail, name )
    } 
    const onChangeTitle = (title: string) => {
        props.onChange("title", title)
    } 
    const onChangeContent = (content: string) => {
        props.onChange("content", content)
    }
    const onOwner = (user: IUser, field: string) => {
        props.onChange(field, user)
    }

    return <div className='pe-project-edit-card-container'>
        <div className="thumb position-relative" >
            <ResourceIllustration
                illustration={props.item?.thumbnail || ""}
                onChange={onThumbnail}
            />
            <div className="position-absolute bottom right small bg-dark text-light m-2 py-1 px-2">
                {
                    props.item.order
                }
            </div> 
        </div>
        <div className="d-flex flex-column flex-grow-100">
            <div className="title"> 
                <EditableText
                    className=" px-3 py-1 w-100"
                    alwaysRenderInput={false} 
                    disabled
                    placeholder="Edit title..."
                    selectAllOnFocus={false} 
                    value={props.item.title}
                    onChange={onChangeTitle}
                /> 
            </div>
            <ResourceContent
                content={props.item.content || ""}
                isContentEdit={isContentEdit}
                onChangeContent={onChangeContent}
                onEditContent={onEditContent}
            />
            <div className="footer"> 
                <TrackExpert 
                    user={ props.item.tutor }  
                    onChange={(user:IUser) => onOwner(user, "tutor")}
                    title={ __("Tutor") }
                    festID={festival?.id || "-1"}
                />
                <TrackExpert 
                    user={ props.item.leader }  
                    onChange={(user:IUser) => onOwner(user, "leader")}
                    title={ __("Leader") }
                    festID={festival?.id || "-1"}
                /> 
                <ConfirmButton
                    buttonIcon="trash"
                    onConfirm={ props.onDelete }
                    buttonLabel={<div className="d-md-flex d-none ">{__("Delete")}</div>}
                    buttonMinimal
                >
                    <div className="p-4">
                        {__("Really delete?")}
                    </div>
                </ConfirmButton> 
            </div>
        </div> 
    </div>
}
export default ProjectEditCard