import ProjectTeam from "../team"

/* Protopia Ecosystem component */
export interface IProjectMobileTeamProps {

}
const ProjectMobileTeam = (props: IProjectMobileTeamProps): JSX.Element => { 
    return <div className='pe-project-mobile-team-container'>
        <ProjectTeam />
    </div>
}
export default ProjectMobileTeam