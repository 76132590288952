import { __ } from "@/libs/utilities"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { Tag } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface IMyRolesLabelProps {
   
}
const MyRolesLabel = (props: IMyRolesLabelProps): JSX.Element => {
    const user:IUser = useMainStore((state:IState) => state.user)
    return user?.id
        ?
        <div className='pe-my-roles-label-container'>
        <span className="mr-2 small">{__("My roles")}:</span>
       {
        user.roles.map(role => {
            return <Tag key={role} round className="mr-1 mb-1 pointer pe-pumpkit-mirk">
                {__(role)}
            </Tag>
        })
       }
    </div>
    :
    <></>
}
export default MyRolesLabel