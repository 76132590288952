import { ID, IPost } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { Callout } from "@blueprintjs/core"
import { DragDropContext, Draggable, DraggableStateSnapshot, Droppable } from "react-beautiful-dnd"

/* Protopia Ecosystem component */
export interface IResourceDragProps<T> { 
    resources: T[]
    Component: any
    setResources: (resources: T[]) => void 
    dataTypeName: string
}
function ResourceDrag<T extends IPost>({  resources, setResources, Component, dataTypeName }: IResourceDragProps<T>): JSX.Element  {
    
    // a little function to help us with reordering the result
    const reorder = (list: T[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed); 
        return result;
    }; 

    const getItemStyle = (isDragging: boolean, draggableStyle: T) => ({ 
        userSelect: "none",
        padding: 0, 
        margin: `0 0 0 0`, 
        width: "100%",
        color: "#FFFFFF!important",
        // change background colour if dragging
        background: isDragging ? "#45526d" : "transparent", 
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? "var(--drop-select-color)" : "transparent",
        padding: 0, 
    })
    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        } 
        const _items: T[] = reorder(
            resources,
            result.source.index,
            result.destination.index
        );   
        setResources( _items.map((item, i) => ({...item, order: i + 1 })) )
    }
    const onDelete = (id: ID) => {
        setResources( resources.filter(t => t.id !== id) )
    }
    const onChange = ( id: ID, field: string, value: any, name?: string ) => {
        let n = 0;
        const d = [...resources]
        d.forEach((d, i) => {
            if( d.id === id ) {
                n = i 
            } 
        }) 
        d[ n ] = {
            ...resources[ n ],
            [field] : value
        }
        if(!!name) {
            d[ n ] = {
                ...d[ n ],
                [field + "Name"] : name
            }
        }
        console.log( n, d[ n ] )
        setResources( d )
    }

    return <>
        {
        !!resources.length
            ?
            <div>
            <DragDropContext onDragEnd={ onDragEnd }>
                <Droppable 
                    droppableId="droppable"
                    direction="vertical" 
                >
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                ...getListStyle(snapshot.isDraggingOver),
                                padding: "0"
                            }}
                        >
                            { resources.map((item, index) => (
                                <div key={item.id} className="d-flex mb-3"> 
                                    <Draggable 
                                        draggableId={item.id.toString()} 
                                        index={index}
                                    >
                                        {(provided: any , snapshot: DraggableStateSnapshot) => ( 
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps?.style
                                                )}
                                            >
                                                <Component
                                                    item={item } 
                                                    dataTypeName={dataTypeName}
                                                    onChange={( field: string, value: any, name?:string ) => onChange(item.id, field, value, name )} 
                                                    onDelete={onDelete}
                                                />
                                                </div>
                                            )}
                                        </Draggable>
                                    </div>
                                ))}
                                { provided.placeholder }
                            </div>
                        )}
                    </Droppable>
                </DragDropContext> 
            </div> 
            :
            <Callout className="p-5 lead text-center">
                {__("No elements exists")}
            </Callout>
    }
    </>
}
export default ResourceDrag