import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router";
import { AppToaster } from "src/libs/useful";
import { ILand, ISettingsPageFormProps } from '../../../pe-fest-module/data/interfaces';
import ClearMaster from "../../../pe-fest-module/views/festAdmin/inheritance/ClearMaster";
 
import CopyMaster from '../../../pe-fest-module/views/festAdmin/inheritance/CopyMaster';
import { Button, Card, Dialog, Icon, Intent } from '@blueprintjs/core';
import { DIALOG_SIZE } from 'src/libs/interfaces/layouts';
import { __ } from 'src/libs/utilities';
import { component_url } from 'src/libs/utilities/links';
import { DELETE_SITE_ACTION } from 'src/modules/pe-basic-module/data/actionTypes';
import { default as mainActions } from "src/modules/pe-basic-module/data/actions";
import { CLEAR_FESTIVAL_PRESCEPTIONS_ACTION, COPY_LAND_ACTION, EXTEND_LAND_ACTION, SNAPSHOT_LAND_ACTION } from '../../../pe-fest-module/data/actionTypes';
import { actions } from '../../../pe-fest-module/data/actions'; 
import { ReactComponent as Clear } from "../../assets/img/clear.svg";
import { ReactComponent as CopyFest } from "../../assets/img/copyFest.svg";
import { ReactComponent as DeleteFest } from "../../assets/img/deleteFest.svg";
import { ReactComponent as Snapshot } from "../../assets/img/snapshot.svg";
import { ReactComponent as ExpandLand } from "../../assets/img/expand-landscape.svg";
import ConfirmButton from 'src/libs/useful/ConfirmButton';
import addCSSClass from 'src/libs/layout-app/utils/addCSSClass';
import ExpandMaster, { IExpands, STATUS } from 'src/modules/pe-fest-module/views/festAdmin/inheritance/ExpandMaster';


const InheritanceForm = (props: ISettingsPageFormProps) : JSX.Element => {
    const {landId} = useParams() 
    const [isCopyOpen, setCopyOpen] = useState(false)
    const [isClearOpen, setClearOpen] = useState(false)
    const [isDeleteOpen, setDeleteOpen] = useState(false)    
    const [isClearDoing, setIsClearDoing] = useState(false) 
    const [isExpandDoing, setIsExpandDoing] = useState(false) 
    const [status, setStatus] = useState<STATUS>(STATUS.UPLOAD) 
    const [upload, setUpload] = useState<string>("") 
    const [expands, setExpands] = useState<IExpands>({} as IExpands) 
    const navigate = useNavigate()
    
    const iconSize = 50
    
    useEffect(() => {
        addCSSClass(
            "inheritance", 
            `.expand-intent { 
                background-color: #165A36!important; 
            } 
            .create-intent { 
                background-color: #0C5174!important; 
            }
            .clear-intent {
                background-color: #D0B090!important;
            }
        `)
    }, [])

    const onClearOpen = () =>
	{
        setClearOpen(!isClearOpen) 
	}
	const onDeleteOpen = () =>
	{
        setDeleteOpen(!isDeleteOpen) 
	}
    const onCopyOpen = () => {
        setCopyOpen(false)
    }
    const onStartClean = (data: any) =>
    {
        setIsClearDoing(true)  
        setClearOpen( false )  
        actions(CLEAR_FESTIVAL_PRESCEPTIONS_ACTION, {...data, land_id: landId } ) 
            .then(( response: any ) => {
                console.log( response )  
                setIsClearDoing(false)            
                AppToaster.show({
                    message: __("Successful update"),
                    intent: Intent.SUCCESS
                })
            })
    }
	const onDelete = () => { 
        mainActions( DELETE_SITE_ACTION, { land_id: landId } )
            .then((res: boolean) => {
                AppToaster.show({
                    message: __( res ? "Site remove Successful." : "Unknown error" ),
                    intent: res ? Intent.SUCCESS : Intent.DANGER,
                    timeout: 10000
                })
                if( res ) {
                    navigate(component_url( "FestivalAdminListView" ))
                }
            })
    } 
    const onStartSnapshot = ( ) => {
        actions(
            SNAPSHOT_LAND_ACTION, 
            { land_id: landId }
        ) 
        .then((resp) => {
            console.log( resp )
            // anchor link
            const cont = document.createElement("div")
            cont.className = "position-absolute "
            cont.style.top = "-1000px"
            cont.style.left = "-1000px"
            const element = document.createElement("a") 
            element.href = resp
            element.download = "100ideas-" + Date.now() + ".txt"

            // simulate link click
            document.body.appendChild(cont)
            cont.appendChild(element)
            element.click()
            cont.remove()
        })
    }
    const onStartCopy = ( data: ILand ) => {
        console.log(data)
        actions(COPY_LAND_ACTION, data).then( r => setCopyOpen( false ) )
        
    }
    const onStatus = (status: STATUS) => {
        setStatus(status)
    }
    const onExpand = (_expands: IExpands, _upload: string) =>  {
        setExpands(_expands)
        setUpload(_upload)
    }
    const onStartExpand = () => {
        setIsExpandDoing( true )
        console.log( [ expands, upload ] )
        actions(
            EXTEND_LAND_ACTION, 
            {
                land_id: landId,
                extends: expands, 
                peland: upload
            }
        )
        .then(res => {
            console.log( res )
        })
        .finally(() => {
            setIsExpandDoing( false )
        })
    }
    return (
        <div> 
            <div className='d-flex justify-content-between align-items-center'>
                <div className='display-5 mb-3'>{ __(props.title || "") }</div> 
            </div>
            <div className="p-5 card w-100 mb-2 m-0">
                <p className="display-6 font-italic">
                    { __( "Be careful my friend! This is Chernobyl. A place that smells of death." ) }
                </p>
                <p className="mt-2 title text-center">
                    { __( "Virgil" ) }
                </p>
            </div>
            <div className='row'>
                <div className='col-12 '>
                    <Card className="p-4 mb-2">
                        <div className="lead mb-2">{__("Snapshot Land data")}</div>
                        <div className="flex-centered w-100">
                            <div className=" bg-light mr-3 ">
                                <Snapshot width={iconSize} height={iconSize} className="m-4" />
                            </div>
                            <div className="w-100">
                                <ConfirmButton
                                    onConfirm={ () => onStartSnapshot()}                                    
                                    buttonIntent={Intent.SUCCESS}
                                    buttonClassName="lead p-4" 
                                    buttonFill
                                    dialogClasssName={DIALOG_SIZE.MINIMAL}
                                    buttonLabel = { <div className='d-flex p-3'>
                                        <Icon 
                                            icon={ "camera" }
                                            className={ "mr-3" }
                                        />
                                        {__("Copy Land data to the *.peland format file for the purpose of archiving data and subsequent transfer to another Landscape")}
                                    </div>}
                                >
                                    <div className="p-5">
                                        {__("Download snapshot right now?")}
                                    </div>
                                </ConfirmButton>
                            </div>                 
                        </div>
                    </Card>	
                    <Card className="p-4 mb-2">
                        <div className="lead mb-2">{__("Expand saved Land")}</div>
                        <div className="flex-centered w-100">
                            <div className=" bg-light mr-3 ">
                                <ExpandLand width={iconSize + 10} height={iconSize + 10} className="m-4" />
                            </div>
                            <div className="w-100">
                                <ConfirmButton
                                    buttonLoading={ isExpandDoing }
                                    onConfirm={ () => onStartExpand()}
                                    buttonClassName="lead p-4 expand-intent text-light " 
                                    buttonFill
                                    dialogClasssName={DIALOG_SIZE.FULL}
                                    buttonLabel = { <div className='d-flex p-3'>
                                        <Icon 
                                            icon={ "inheritance" }
                                            className={ "mr-3" }
                                        />
                                        {__("Expand Land from local *.peland file. The data will be added to the existing ones.")}
                                    </div>}
                                    yesLock={ status === STATUS.UPLOAD }
                                    yesLabel={ __( status !== STATUS.UPLOAD ? "Expand" : "--") }
                                    noLabel={ __( "Close" ) }
                                    onDesmiss={() => {
                                        onStatus( STATUS.UPLOAD )
                                    }}
                                >
                                    <div className="pr-0 mr-15 ml-0 w-100">
                                        <ExpandMaster 
                                            onStatus={ onStatus } 
                                            status={ status }
                                            onExpand={ onExpand }
                                        /> 
                                    </div>
                                </ConfirmButton>
                            </div>                 
                        </div>
                    </Card>	
                    <Card className="p-4 mb-2">
                        <div className="lead mb-2">{__("Duplicate this Land")}</div>
                        <div className="flex-centered w-100">
                            <div className=" bg-light mr-3 ">
                                <CopyFest width={iconSize + 20} height={iconSize + 20} className="m-3" />
                            </div>
                            <div className="w-100">
                                <Button 
                                    className="lead p-4 create-intent hover text-light" 
                                    fill
                                    onClick={ () => setCopyOpen( !isCopyOpen ) }            
                                >
                                    <div className='d-flex p-3'>
                                        <Icon 
                                            icon={ "duplicate" }
                                            className={ "mr-3" }
                                            color='#FFF'
                                        />
                                        {__("Copy Festival Master")}
                                    </div>
                                </Button>
                            </div>                 
                        </div>
                    </Card>	
                    
                    <Card className="p-4 mb-2">
                        <div className="lead mb-2">{__("Clean Land data")}</div>
                        <div className="flex-centered w-100">
                            <div className=" bg-light mr-3 ">
                                <Clear width={iconSize} height={iconSize} className="m-4" />
                            </div>
                            <div className="w-100">
                                <Button 
                                    className="lead p-4 clear-intent" 
                                    fill
                                    onClick={onClearOpen}            
                                >
                                    <div className='d-flex p-3'>
                                        <Icon 
                                            icon={ isClearDoing ? "repeat" : "filter-remove" }
                                            className={ isClearDoing ? "fa fa-spin mr-3 opacity_75": "mr-3 opacity_75"}
                                            color='#111111'
                                        />
                                        {__("Start clean Master")}
                                    </div>
                                    
                                </Button>
                            </div>                 
                        </div>
                    </Card>	
                    <Card className="p-4 mb-2">
                        <div className="lead mb-2">{__("Delete the festival and its data permanently")}</div>
                        <div className="flex-centered w-100">
                            <div className=" bg-light mr-3 ">
                                <DeleteFest width={iconSize} height={iconSize} className="m-4" />
                            </div>
                            <div className="w-100">
                                <Button
                                    intent={Intent.DANGER}
                                    className="lead p-4"
                                    fill
                                    onClick={ onDeleteOpen }
                                >
                                    <div className='d-flex p-3'>
                                        <Icon icon="trash" className="mr-3"/>
                                        {__("Delete now")}
                                    </div>
                                </Button> 
                            </div>
                        </div>
                    </Card>
                    <Dialog
                        isOpen={ isDeleteOpen}
                        onClose={ onDeleteOpen}
                        title={__("Delete the festival and its data permanently")}
                        className={DIALOG_SIZE.MINIMAL}
                    >
                        <div className="p-4">
                            <Button
                                intent={Intent.DANGER}
                                className="lead p-4"
                                fill
                                onClick={ onDelete }
                            >
                                {__("Delete now")}
                            </Button>
                        </div>	
                    </Dialog>
                    <Dialog
                        isOpen={ isClearOpen}
                        onClose={ onClearOpen} 
                        className={DIALOG_SIZE.NORMAL}
                    >
                        <div className="p-0">
                            <div className="p-4">
                                <ClearMaster 
                                    onStartClean={onStartClean}
                                />
                            </div>
                            
                        </div>	
                    </Dialog>
                    
                    {/* <Dialog
                        isOpen={ isSnapshotOpen}
                        onClose={ () => setSnapshotOpen(false) } 
                        className={DIALOG_SIZE.NORMAL}
                    >
                        <div className="p-0">
                            <div className="p-4">
                                <SnapShotMaster 
                                    onStartSnapshot={onStartSnapshot}
                                />
                            </div>
                            
                        </div>	
                    </Dialog> */}
                    <Dialog
                        isOpen={ isCopyOpen}
                        onClose={ onCopyOpen } 
                        className={DIALOG_SIZE.NORMAL}
                    >
                        <div className="p-0">
                            <div className="p-4">
                                <CopyMaster 
                                    onStartCopy={onStartCopy} 
                                />
                            </div>
                            
                        </div>	
                    </Dialog>
                </div>
            </div>
        </div>
    )
} 

export default InheritanceForm