import { GET_SERVICE_OPTIONS_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import actions from "@/modules/pe-basic-module/data/actions"
import { isRole } from "@/settings/zustand/user" 
import { Role, WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"

/* Protopia Ecosystem component */
export interface IFestAdminMenuProps {

}
const FestAdminMenu = (props: IFestAdminMenuProps): JSX.Element => {
    const [ roles, setRoles ] = useState<Role[]>([])
    const {width} = useScreenSize()
    useEffect(() => { 
        actions(GET_SERVICE_OPTIONS_ACTION, {})
            .then((resp: any) => {
                setRoles([ ...resp.roles, "SuperAdmin" ])
            })
    }, [])

    const fill = width > WINDOW_WIDTH.TABLET
    return isRole( roles ) 
        ?
        <div className='pe-fest-admin-menu-container container flex-centered pb-4'>
            <div className="btn-group flex-wrap flex-centered flex-md-row flex-column w-md-auto w-100">
                <NavLink to="" className={`btn btn-sm btn-outline-secondary pl-4 pr-3 ${fill ? 'rounded-pill-0 rounded-pill-3' : 'btn-block'}`} >
                    {__("All Sites")} 
                </NavLink> 
                {
                    isRole( ["administrator", "SuperAdmin"] ) &&
                    <NavLink to="settings" className={`btn btn-sm btn-outline-secondary px-3 ${fill ? '' : 'btn-block'}`} >
                        {__("Settings")} 
                    </NavLink>
                } 
                <NavLink to="add-festival" className={`btn btn-sm btn-outline-secondary pr-4 pl-3 ${fill ? 'rounded-pill-1 rounded-pill-2' : 'btn-block'}`} >
                    {__("Create new Event")} 
                </NavLink>
            </div>
        </div>
        :
        <></>
}
export default FestAdminMenu