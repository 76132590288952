import { ID } from "@/libs/interfaces/layouts"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import FieldInputGroup from "@/modules/pe-admin-module/views/single/FieldInputGroup"
import { ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, useFestDataStore } from "@/modules/pe-fest-module/data/store"
import { useIsTrackModerator } from "@/modules/pe-fest-module/hooks/track"
import { isRole } from "@/settings/zustand/user"
import { Intent } from "@blueprintjs/core"
import { useEffect, useState } from "react"

/* Protopia Ecosystem component */
export interface ITrackEditorProps {
   item: ITrack | null

}
const TrackEditor = (props: ITrackEditorProps): JSX.Element => {
    const landId: ID        = useFestDataStore((state: IFestDataStor) => state.festId)
    const [item, setItem]   = useState<ITrack>(props.item ? props.item : {} as ITrack)
    const isModerator : boolean = useIsTrackModerator(item)

    useEffect(() => {
        console.log( props.item )
        setItem({...item, ...props.item})
    }, [ props.item ])

    const onChangeItem = (value: any ) => {
       
        setItem({...item, ...value})
    }
    const editTrack = ( ) => {
        console.log( item )
    }

    return isModerator || isRole([ "administrator", "SuperAdmin" ])
        ?
        <ConfirmButton
            buttonIntent={Intent.DANGER}
            buttonMinimal
            buttonIcon="edit"
            onConfirm={editTrack}
            buttonClassName="ml-3"
            dialogTitle={__("Edit Track")}
        >
            <div className="p-4 overflow-y-auto" style={{maxHeight: "calc(100vh - 200px)"}}>
                <FieldInputGroup
                    data_type="PEFestTrack"
                    item={item} 
                    landId={landId}
                    onChange={ onChangeItem }
                    fields={undefined}
                />
            </div>
        </ConfirmButton>
        :
        <></> 
}
export default TrackEditor