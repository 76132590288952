import { Link } from 'react-router-dom'
import { ICardProjectProps } from '../../data/interfaces'
import getProjectLink from '../../data/utils/getProjectLink'
import MemberRatingDisplay from './MemberRatingDisplay'

const LineProject = ( props: ICardProjectProps ): JSX.Element => {  
  const url = getProjectLink(props.item.id)

  return <div className="ml-2 w-100 grey2 position-relative overflow-hidden stroke-a-dark"> 
    <Link to={url} className="row hover">  
      <div  className="col-lg-11 col-10 d-flex  py-2 align-items-center" >  
        <div className="px-4">{ props.item.order } </div>  
        <div>
          <h6  className={`display-7 m-0 flex-grow-100 text-center text-lg-left ${!props.item.is_verified ? "opacity_5" : ""} `} >
            { props.item.title }
          </h6>
        </div>       
                          
      </div> 
      <div  className="col-lg-1 col-2 d-flex flex-column justify-content-center align-items-center align-items-lg-left px-3" >            
        <MemberRatingDisplay 
          correct_member_rate={props.item.correctMemberRate}
          countedRating={props.item.r2}
          className={"text-dark "}
        />         
      </div>    
    </Link> 
  </div>
} 

export default LineProject