import LandGroupForm from "./LandGroupForm"

/* Protopia Ecosystem component */
export interface ILandListFormProps {
   children: JSX.Element
}
const LandListForm = (props: ILandListFormProps): JSX.Element => {
    return <div className='pe-land-list-form-container'>
        <div>
            <LandGroupForm />
        </div>
        <ul>
            {props.children}
        </ul>
    </div>
}
export default LandListForm