import { ReactNode } from "react"
import getDownloadFile from "../utilities/getDownloadFile"
import { URL } from "../interfaces/layouts"

/* Protopia Ecosystem component */
export interface IDownloadBtnProps {
   className?: string, 
   style?: any
   src: URL
   children?: ReactNode | ReactNode[]
   label?: string
}
const DownloadBtn = ( { className, style, src, children, label }: IDownloadBtnProps ): JSX.Element => {
    const onClick = (evt:any) => { 
        evt.preventDefault()
        console.log( src )
        // getDownloadFile( src )
    }
    return <a 
        className={ `${className ? className : ""} hover pointer ` } 
        style={ style}
        download
        // onClick={ onClick }
        href={ src }
        target="_blank" 
        rel="noreferrer"
    >
        {
            children
                ? 
                children
                :
                <div> {label} </div>
        }
    </a>
}
export default DownloadBtn