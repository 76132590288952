 
import { __,  } from "@/libs/utilities"
import { Button } from "@blueprintjs/core"
import { useEffect, useState } from "react"  
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_SUPER_ADMIN, SET_SUPER_ADMIN } from "@/modules/pe-basic-module/data/actionTypes"
import { isRole } from "@/settings/zustand/user"


const SettingsForm = ( ) :JSX.Element => { 
    const pageCount: number = useMainStore(( state: IState ) => state.pageCount)
    const setPageCount: any = useMainStore((state: IState ) => state.setPageCount)
    const [superAdmins, setSuperAdmins] = useState<IUser[]>([])

    useEffect(() => {
        actions(GET_SUPER_ADMIN, {}).then(resp => setSuperAdmins(resp))
    }, [])
    
    const onSuperAdmin = (user: IUser, i: number) => {
        actions(
            SET_SUPER_ADMIN, 
            {
                user_id: user.id, 
                is_add : user.id !== -1
            })
            .finally(() => {
                actions(GET_SUPER_ADMIN, {}).then(resp => setSuperAdmins(resp))
            })
    }
    const onAddSuperAdmin = () => {
        setSuperAdmins([ 
            ...superAdmins, 
            { id: -1 } as IUser 
        ])

    }
    return  <div className="short-container"> 
        <FieldInput
            editable
            type={SCALAR_TYPES.SLIDER}
            min={3}
            max={103}
            stepSize={1}
            labelStepSize={20}
            vertical={VerticalType.VERTICAL}
            title={__("Count of element per page in feeds")}
            value={pageCount}
            onChange={ ( value: number ) => setPageCount( value ) }
        /> 
        {
            isRole(["SuperAdmin"]) && 
            <>
                <div className="col-12 layout-label-vert p-0">
                    {__("Super Administrators")}
                </div>
                {
                superAdmins.map((sa, i) => <FieldInput
                        editable
                        key={i}
                        type={SCALAR_TYPES.USER} 
                        vertical={VerticalType.VERTICAL}
                        value={sa}
                        onChange={ ( value: IUser ) => onSuperAdmin( value, i ) }
                        onClear={ () => onSuperAdmin( {id: -1} as IUser, i ) }
                        isMulti
                        onlyThisLands
                    /> ) 
                }    
                <div>
                    <Button minimal icon="plus" onClick={onAddSuperAdmin} >
                        {__("Add SuperAdmin")}
                    </Button>    
                </div>     
            </>
        }       
    </div>
} 
export default SettingsForm