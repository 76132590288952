import { __ } from "@/libs/utilities"
import _cases from "@/libs/utilities/__cases"

/* Protopia Ecosystem component */
export interface IAllProjectsCountLabelProps {
    fullCount : number
}
const AllProjectsCountLabel = (props: IAllProjectsCountLabelProps): JSX.Element => {
    return <div className='pe-all-projects-count-label-container pl-4 pr-2 small'>
       {props.fullCount} { __( _cases( props.fullCount, "projects" ) ) }
    </div>
}
export default AllProjectsCountLabel