import { IFestival, IProject } from "@/modules/pe-fest-module/data/interfaces"
import { __ } from "@/libs/utilities"
import { Button, Callout } from "@blueprintjs/core"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import ProjectEditCard from "./ProjectEditCard"
import { ID } from "@/libs/interfaces/layouts"

/* Protopia Ecosystem component */
export interface IProjectsContentProps {
    festival: IFestival
    projects: IProject[]
    setProjects: (projects: IProject[]) => void
    onRestore: () => void
}
const ProjectsContent = ({projects, ...props}: IProjectsContentProps): JSX.Element => {    
    // a little function to help us with reordering the result
    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed); 
        return result;
    };

    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({ 
        userSelect: "none",
        padding: 0, 
        margin: `0 0 0 0`, 
        width: "100%",
        color: "#FFFFFF!important",
        // change background colour if dragging
        background: isDragging ? "#45526d" : "transparent", 
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? "var(--drop-select-color)" : "transparent",
        padding: 0, 
    })
    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        } 
        const _items: IProject[] = reorder(
            projects,
            result.source.index,
            result.destination.index
        );   
        props.setProjects( _items.map((item, i) => ({...item, order: i+1 })) )
    } 
    const onClear = () => {
        props.setProjects( [] )
    }
    const onDeletePropject = ( id:ID ) => {
        props.setProjects( projects.filter( p => p.id !== id) )
    }

    return <div className='pe-projects-content-container'>
        {
            !!projects.length
                ?
                <div>
                    <DragDropContext onDragEnd={ onDragEnd }>
                        <Droppable 
                            droppableId="droppable"
                            direction="vertical" 
                        >
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        ...getListStyle(snapshot.isDraggingOver),
                                        padding: "20px 20px 0 20px"
                                    }}
                                >
                                    { projects.map((item, index) => (
                                        <div key={item.id} className="d-flex mb-3"> 
                                            <Draggable 
                                                draggableId={item.id.toString()} 
                                                index={index}
                                            >
                                                {(provided, snapshot) => ( 
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <ProjectEditCard 
                                                            item={item} 
                                                            onDelete={ () => onDeletePropject(item.id)}
                                                            onChange={ () => {} }
                                                            key={item.id} 
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        </div>
                                    ))}
                                    { provided.placeholder }
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext> 
                </div> 
                :
                <Callout className="p-5 lead text-center">
                    {__("No elements exists")}
                </Callout>
        }
        <div className="d-flex justify-content-end w-100">
            <Button icon="cross" minimal onClick={onClear}>
                {__("Clear all")}
            </Button> 
            <Button icon="reset" minimal onClick={props.onRestore}>
                {__("Restore")}
            </Button> 
        </div>
    </div>
}
export default ProjectsContent