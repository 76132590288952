import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __, sprintf } from "@/libs/utilities"
import "../../assets/style.scss"
import LogFileTitle from "./LogFileTitle"
import LogSingle from "./LogSingle"
import { actions } from "../../data/actions"
import { DELETE_LOG_FILE_ACTION } from "../../data/actionTypes"

/* Protopia Ecosystem component */
export interface ILogFileFormProps {
   logFile: any
   onReload : () => void
}
const LogFileForm = (props: ILogFileFormProps): JSX.Element => {  
    const onDelete = () => {
        actions( DELETE_LOG_FILE_ACTION, { file_name: props.logFile.file_name.toString() } )
            .then(res => {props.onReload()})
    }
    return <div className='pe-log-file-form-container'>
        <div className="d-flex align-items-center">
            <LogFileTitle title={ props.logFile.file_name } />
            <ConfirmButton
                dialogClasssName={ DIALOG_SIZE.MINIMAL }
                buttonMinimal
                buttonSmall
                buttonIcon="trash"
                buttonClassName="ml-5"
                onConfirm={ onDelete }
            >  
                <div className="p-5">
                    { sprintf( __( "Really delete log «%s»?" ), props.logFile.file_name.toString() ) }
                </div>
            </ConfirmButton>
        </div>
        {
            props.logFile.text.split("\r\n\r\n").map( ( log: string, i: number ) => {
                return log ? <LogSingle text={log} key={i} /> : null
            })
        }
        {/* <div dangerouslySetInnerHTML={{ __html: props.logFile.text.replace(/\r\n/gi, "<br>") }} /> */}
    </div>
}
export default LogFileForm