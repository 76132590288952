import actions from "@/modules/pe-basic-module/data/actions"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { useEffect, useState } from "react"
import { GET_FEED_ACTION } from "../../data/actionTypes"
import SingleLandGroup from "./SingleLandGroup"
import { DIALOG_SIZE, ILandGroup, IPostInput } from "@/libs/interfaces/layouts"
import { Icon } from "@blueprintjs/core"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { __ } from "@/libs/utilities"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import FieldInput from "@/libs/scalars"
import { land_group_id } from "@/libs/layouts"

const DEFAULT_LAND_GROUP: IPostInput = {
    title: __("New Land group"),
    post_content: ""
}
/* Protopia Ecosystem component */
export interface ILandGroupFormProps {
   
}
const LandGroupForm = (props: ILandGroupFormProps): JSX.Element => {
    const PELandGroup : ILandGroup[] = useDataStore( (state: any) => state.PELandGroup || [] ) 
    const [newPELandGroup, setNewPELandGroup] = useState<IPostInput>( DEFAULT_LAND_GROUP )
    useEffect(() => {
        actions(GET_FEED_ACTION, { data_type: "PELandGroup" })
    }, [])

    const onAdd = () => {
        console.log( newPELandGroup )
        actions(
            UPDATE_SINGLE_TYPE, 
            { 
                data_type: "PELandGroup", 
                item: newPELandGroup
            }
        )
    }
    const onNewLG = (value: any, field: string) => {
        setNewPELandGroup({
            ...newPELandGroup,
            [field]: value
        })
    }
    return !!land_group_id()
        ?
        <></>
        :
        <div className='flex-centered '>
            {
                PELandGroup.map(lg => <SingleLandGroup item={lg} key={lg.id} />)
            }
            <ConfirmButton
                onConfirm={onAdd}
                buttonLabel={ <Icon icon="plus" /> }
                buttonMinimal
                dialogClasssName={DIALOG_SIZE.MINIMAL}
                dialogTitle={__( "New Land group settings" )}
                yesLabel={ __( "Insert new Lands group" ) }
                noLabel={ __( "Close" ) }
            >
                <div className="p-4">
                    <FieldInput
                        onChange={ value => onNewLG(value, "title") }
                        editable
                        value={ newPELandGroup.title }
                        title={__("Title")}
                    />
                </div>
            </ConfirmButton>
            
        </div>
}
export default LandGroupForm