import React, { useState } from 'react' 
import FieldInput from '@/libs/scalars'
import { __ } from '@/libs/utilities'
import { Button, Intent } from '@blueprintjs/core'
import { SCALAR_TYPES, VerticalType } from '@/libs/scalars/interface/IScalarForm'
import { PEFestClearPrescription } from '@/modules/pe-fest-module/data/interfaces'

interface ICleanMasterProps {
    onStartClean: ( data: any ) => void
}

const ClearMaster = (props: ICleanMasterProps) : JSX.Element => {
    const[raitingData, setRatingData] = useState(PEFestClearPrescription.IGNORE)
    const[ratingSystem, setRatingSystem] = useState(PEFestClearPrescription.IGNORE)
    const[options, setOptions] = useState(PEFestClearPrescription.IGNORE) 
    const[projects, setProjects] = useState(PEFestClearPrescription.IGNORE) 
    const[logs, setLogs] = useState(PEFestClearPrescription.IGNORE)
    const[users, setUsers] = useState(PEFestClearPrescription.IGNORE)
    const[schools, setSchools] = useState(PEFestClearPrescription.IGNORE)
    const[destricts, setDestricts] = useState(PEFestClearPrescription.IGNORE)
    const[messages, setMessages] = useState(PEFestClearPrescription.IGNORE)
    const changeRatingData = (value: PEFestClearPrescription) =>
    {
        setRatingData(value)
    }
    const changeUsersData = (value: PEFestClearPrescription) =>
    {
        setUsers(value)
    }
    const changeRatingSystem = (value: PEFestClearPrescription) =>
    {
        setRatingSystem(value)
    }
    const changeOptions = (value: PEFestClearPrescription) =>
    {
        setOptions(value)
    }
    const changeProjects = (value: PEFestClearPrescription) =>
    {
        setProjects(value)
    }
    const changeSchools = (value: PEFestClearPrescription) =>
    {
        setSchools(value)
    }
    const changeDestricts = (value: PEFestClearPrescription) =>
    {
        setDestricts(value)
    }
    const changeMessages = (value: PEFestClearPrescription) =>
    {
        setMessages(value)
    }
    const changeLogs = (value: PEFestClearPrescription) =>
    {
        setLogs(value)
    }
    const onStartClean = () =>
    {
        const data = {
            raitingData, ratingSystem, options, projects, users, destricts, schools, logs, messages
        }
        props.onStartClean(data)
    }
    return <div>
        <div className='display-6 mb-3'>
            {__("Clean festival data")}
        </div>
        <FieldInput 
            title={__("Clean all rating data")} 
            type={SCALAR_TYPES.TAGS}
            ID="raitingData"
            id="raitingData"
            commentary={__("Include all expert's rates, commentaries, all expert statistics")}
            value={ raitingData }
            values={[
                { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                { _id:PEFestClearPrescription.CLEAR, title:__("Clean") },
               // {_id:"resore",  title:"Restore to default"}
            ]}
            default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
            editable
            onChange={changeRatingData}
        />
        <FieldInput  
            title={__("Clear all rating system")}
            type={SCALAR_TYPES.TAGS}
            ID="ratingSystem"
            id="ratingSystem"
            commentary={__("Include all categories, criteries, milestones and ganres")}
            value={ ratingSystem }
            values={[
                { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                { _id:PEFestClearPrescription.CLEAR, title:__("Clean") },
                //{_id:"resore",  title:"Restore to default"}
            ]}
            default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
            editable
            onChange={changeRatingSystem}
        />
        <FieldInput 
            title={__("Clear all projects system")}
            type={SCALAR_TYPES.TAGS}
            ID="projects"
            id="projects"
            commentary={__("Include all projects, tracks and honeycombs. All projects includes and extened fields data")}
            value={ projects }
            values={[
                { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                { _id:PEFestClearPrescription.CLEAR, title:__("Clean") },
                //{_id:"resore",  title:"Restore to default"}
            ]}
            default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
            editable
            onChange={changeProjects}
        /> 
        <FieldInput 
            title={__("Clear all Education Organizations")}
            type={SCALAR_TYPES.TAGS}
            ID="schools"
            id="schools" 
            value={ schools }
            values={[
                { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                { _id:PEFestClearPrescription.CLEAR, title:__("Clean") }, 
            ]}
            default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
            editable
            onChange={changeSchools}
        /> 
        <FieldInput 
            title={__("Clear all Destricts")}
            type={SCALAR_TYPES.TAGS}
            ID="destricts"
            id="destricts" 
            value={ destricts }
            values={[
                { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                { _id:PEFestClearPrescription.CLEAR, title:__("Clean") }, 
            ]}
            default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
            editable
            onChange={changeDestricts}
        /> 
        <FieldInput 
            title={__("Clear all Messages")}
            type={SCALAR_TYPES.TAGS}
            ID="messages"
            id="messages" 
            value={ messages }
            values={[
                { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                { _id:PEFestClearPrescription.CLEAR, title:__("Clean") }, 
            ]}
            default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
            editable
            onChange={changeMessages}
        /> 
        <FieldInput 
            title={__("Include all users")}
            type={SCALAR_TYPES.TAGS}
            ID="users"
            id="users"
            commentary={__("")}
            value={ users }
            values={[
                { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                { _id:PEFestClearPrescription.CLEAR, title:__("Clean") },
                //{_id:"resore",  title:"Restore to default"}
            ]}
            default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
            editable
            onChange={changeUsersData}
        />
        <FieldInput 
            title={__("Clear all logs")}
            type={SCALAR_TYPES.TAGS}
            ID="logs"
            id="logs"
            commentary={__("")}
            value={ logs }
            values={[
                { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                { _id:PEFestClearPrescription.CLEAR, title:__("Clean") },
            ]}
            default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
            editable
            onChange={changeLogs}
        />
        <div className='mt-4'>
            <Button
                intent={Intent.DANGER}
                className="lead p-4"
                fill
                onClick={ onStartClean }
            >
                {__("Start clean now")}
            </Button>
        </div>
    </div> 
}

ClearMaster.propTypes = {}

export default ClearMaster