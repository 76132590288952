import { Button, Intent, Tab, TabId, Tabs, Tag } from "@blueprintjs/core"
import IScalarForm from "../interface/IScalarForm"
import Scalar from "./Scalar"
import { __ } from "@/libs/utilities"
import { MediaChooser } from "@/libs/useful"
import { useEffect, useState } from "react"
import FontAwesome from "../utils/FontAwesome"

enum ICON_SIZE {
    "fa-1x" = "fa-1x",
    "fa-2x" = "fa-2x",
    "fa-3x" = "fa-3x"
}


/* Protopia Ecosystem component */
export interface IIconProps {
   
}
const Icon = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<IconEnabled {...props} />}
        desabledForm={<IconDisabled {...props} />}
    />
}
export default Icon

const IconEnabled = ( props: IScalarForm ): JSX.Element => {
    const [value, setValue ] = useState<string>(props.value)
    const [navBarID, setNavBarID ] = useState<TabId>("")
    const [size, setSize] = useState<ICON_SIZE>(ICON_SIZE["fa-1x"])
    useEffect(() => {
      setValue(props.value)
    }, [props.value] )
    const onValue = ( value: string ) => {
        setValue( value )
    }
    const onSize = (size: string | number) => {
        const sz = "fa-" + size +"x" as ICON_SIZE
        setSize( ICON_SIZE[ sz ] )
        onChangeIcon()
      }
    const onChangeIcon = () => { 
        setTimeout(() => props.onChange ? props.onChange( value + size, null, "" ): null , 200)    
    }
    const getIcon = () => { 
        return (
          <div className={`d-flex w-100 ${ props.className}`} style={{height:199 }}> 
            <FontAwesome value={value} onChange={ onValue } /> 
            <Button 
              minimal 
              intent={size === ICON_SIZE["fa-1x"] ? Intent.DANGER : Intent.NONE} 
              large={true} 
              className="ml-auto font-weight-bold " 
              onClick={() => onSize(1)}
            >
              1x
            </Button>
            <Button 
              minimal 
              intent={size === ICON_SIZE["fa-2x"] ? Intent.DANGER : Intent.NONE} 
              large={true} 
              className=" font-weight-bold " 
              onClick={() => onSize(2)}
            >
              2x
            </Button>
            <Button 
              minimal 
              intent={size === ICON_SIZE["fa-3x"] ? Intent.DANGER : Intent.NONE} 
              large={true} 
              className=" font-weight-bold " 
              onClick={() => onSize(3)}
            >
              3x
            </Button> 
          </div>
        )
    }

    return <div style={{height:220, width:"100%"}}>
      <Tabs
        animate={false}
        selectedTabId={ navBarID}
        onChange={ setNavBarID }
        vertical
        className="w-100 icon-scalar-container"
      >
        <Tab
          id="image"
          title={<div className="d-flex flex-column align-items-center">
            <i className="far fa-image "/>
            <div className="super-small">
              {__("image")}
            </div>
          </div>}
          className="w-100"
          panel={
            <>MEDIA</>
            // <MediaChooser
            //   url={value}
            //   onChange={onImage}
            //   id=""
            //   isUploadHide={ props.isUploadHide }
            //   isURLHide={ props.isURLHide }
            // />
          }
        />
        <Tab
          id="icon"
          title={<div className="d-flex flex-column align-items-center">
            <i className="fas fa-asterisk "/>
            <div className="super-small">
              {__("icon")}
            </div>
          </div>}
          className="w-100"
          panel={ getIcon() }
        />
      </Tabs>      
    </div>
}


const IconDisabled = ( props: IScalarForm ): JSX.Element => {
    return (
        <div className={` ${props.className}`}>
          <i className="fas fa-at" />
          <div className="px-0 my-2">
            {
              props.value
                ? (
                  <Tag minimal>
                    {`${ props.value } `}
                  </Tag>
                )
                : null
            }
          </div>
        </div>
      )
}