import React from 'react' 
import IScalarForm, { IScalarObject } from '../interface/IScalarForm'
import { Button, Popover, Position } from '@blueprintjs/core'
import { __ } from 'src/libs/utilities'
import SubMenu from '../SubMenu'

const Scalar = (props: (IScalarForm & IScalarObject) ) : JSX.Element => {
  const {
    title, description, commentary, autoFocus, 
    value, 
    vertical, field, prefix, postfix, 
    editable, notClear, subMenus, onSubMenu,
    enabledForm, desabledForm
  } = props

  const className = props.className ? props.className : "my-3 form-control input dark"

  const onClear = () => {
    if(props.onClear) {
      props.onClear( props.field || "" )
      return
    }
    if( props.onChange ) {
      props.onChange( "", props.field, "" ) 
    } 
  }
  const onChange = (evt: any) => {
    if( props.onChange ) { 
      props.onChange( evt.target.value, props.field, "" )
    } 
  }
  const col1 = title || description
    ? 
    vertical
      ? 
      "col-12 layout-label-vert  p-0"
      : 
      "col-md-3  layout-label"
    : 
    " hidden "
  const col2 = title || description
    ?
    vertical
      ? 
      "col-12 layout-data-vert  p-0"
      : 
      "col-md-9 layout-data p-0"
    : 
    " col-12 layout-data-vert  p-0"
  const descr = description
    ? 
    <Popover
      position={Position.RIGHT}
      interactionKind="hover"
      content={<div className="p-4 square" dangerouslySetInnerHTML={{ __html: __(description as string) }} />}
    >
      <Button minimal icon="help" />
    </Popover>      
    : 
    null
  const enabled = () : React.ReactElement  => {
    return <input
      autoFocus={ autoFocus }
      placeholder={props.placeholder} 
      type="text"
      pattern={ props.accept }
      className={`${ className ? className : "form-control input dark"} flex-grow-100 `}
      value={value || ""}
      onChange={ onChange }
    /> 
  }
  const disabled = () : React.ReactElement  => {
    return <div className={`text-overflow-ellipsis opacity_75 my-2 ${ className }`} dangerouslySetInnerHTML={{ __html: ( value || "" ).toString()}} />
  }
  return <div className={`row dat strob01 ${props.rowClassName}`} data-row={ field }>
    <div className={col1}>
      {
        typeof title == "string"
          ?
          __(title as string)
          :
          title
      }
      {descr} 
    </div>
    <div className={col2}>
      <div className="d-flex w-100"> 
        {
          prefix ? <span className="prefix">{prefix}</span> : null
        }
        <div className="d-flex flex-grow-100 max-width-100">
        {
          editable 
            ? 
            enabledForm  ? enabledForm : enabled() 
            : 
            desabledForm ? desabledForm : disabled()
        }
        </div>
        {
          postfix ? <span className="postfix">{postfix}</span> : null
        }            
        {
          editable && !notClear
            ? 
            <Button
              className="right"
              icon="cross"
              minimal
              onClick={ onClear }
            />
            :
            null
        }
      </div>
      <SubMenu
        subMenus={subMenus}
        on={onSubMenu}
      />
    </div>
    {
      commentary
        ?
        <div className="scalar-commentary px-3 col-md-9 offset-md-3" dangerouslySetInnerHTML={{ __html: __(commentary as string) }} />
        :
        null
    }

  </div>
} 

export default Scalar