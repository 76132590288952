import { useEffect, useState } from "react"
import { actions } from "../../data/actions"
import { GET_TRASH_FILES_ACTION } from "../../data/actionTypes"
import TrashLogItem from "./TrashLogItem"

/* Protopia Ecosystem component */
export interface ITrashLogProps {
    onRefresh : () => void
}
const TrashLog = (props: ITrashLogProps): JSX.Element => {
    const [logFiles, setLogFiles] = useState<any[]>([])
    useEffect(() => {
        onRefresh()
    }, [])
    const onRefresh = () => {
        actions(GET_TRASH_FILES_ACTION, {})
            .then(res => {
                setLogFiles(res)
                props.onRefresh()
            })
    }
    return <div className='pe-trash-log-container overflow-y-auto'>
        {
            logFiles.map(lf => <TrashLogItem item={ lf } key={ lf.file_name } onRefresh={ onRefresh } />)
        }
    </div>
}
export default TrashLog