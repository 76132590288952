import { DIALOG_SIZE, ID } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import { IDestrict } from "@/modules/pe-fest-module/data/interfaces"
import { useFestExport } from "@/modules/pe-fest-module/data/store"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface ICriteryMilestoneBtnProps { 
    destrict: IDestrict[]
    onChoose: (item: IDestrict) => void
}
const SchoolDestrictBtn = ({onChoose,  destrict}: ICriteryMilestoneBtnProps): JSX.Element => { 
    const allMilestones: IDestrict[]    = useFestExport((state: any) => state.destricts) || [] 
    const [value, setValue]             = useState<IDestrict>( destrict[0] ) 
    const onValue = (value: ID ) => {
        console.log( value )
        setValue(allMilestones.filter(m => m.id === value)[0])
    }
    return <div className='pe-school-destrict-btn-container'>
        <ConfirmButton
            buttonLabel={
                <div className="flex-centered hint hint--top" data-hint={__("Destrict")}>
                    <div className="d-flex d-md-none pe-fest-resource-icon color-force-contrast" style={{backgroundImage:`url(/assets/img/fest/destrict-icon.svg)`}} /> 
                    {
                        value?.title ? value.title : "---"
                    }
                </div>
            }
            buttonMinimal
            dialogClasssName={DIALOG_SIZE.MINIMAL}
            onConfirm={() => onChoose( value ) }
        >
            <div className="p-4">
                <FieldInput
                    editable
                    value={value}
                    vertical={VerticalType.VERTICAL}
                    onChange={onValue}
                    type={SCALAR_TYPES.TAGS}
                    component="PEFestDestrict"
                    values={ allMilestones.map(m => ({...m, _id: (m.id || m.ID) })) }
                />
            </div>
        </ConfirmButton>
    </div>
}
export default SchoolDestrictBtn