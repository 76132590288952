
/* Protopia Ecosystem component */
export interface ILogTimeProps {
    date: string
}
const LogTime = ({date}: ILogTimeProps): JSX.Element => {
    return <span className="bg-dark text-light px-2 py-1 small">
        {date.toString()}
        {/* { String(date.getHours()).padStart(2, '0') }:{ String(date.getMinutes()).padStart(2, '0') }:{ String(date.getSeconds()).padStart(2, '0') } */}
    </span> 
}
export default LogTime