export const REFRESH_JWT_TOKEN = refresh_token => {
    return `mutation
    {
        refresh(input: { refresh_token: "${refresh_token}"} ) 
        {
            access_token
            refresh_token
            expires_in
        }
    }`
} 
export const GET_SPECIAL = () =>
{
    return `query getSpecial 
    {  
        getSpecial 
        {
            is_load_menus
            is_load_settings
            is_load_data_type
        }
    }`
}
