import { useEffect, useState } from "react"
import actions from "../../data/actions"
import { VERIFY_EMAIL_ACTION } from "../../data/actionTypes" 
import { useParams } from "react-router"
import { Loading } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { Link } from "react-router-dom"

enum STATES {
    START       = "START",
    SUCCESS     = "SUCCESS",
    NO_SUCCESS  = "NO_SUCCESS"

} 
/* Protopia Ecosystem component */
export interface IChangeEmailFormProps {
   
}
const ChangeEmailForm = (props: IChangeEmailFormProps): JSX.Element => { 
    const params = useParams()
    const [status, setStatus] = useState<STATES>(STATES.START)
    useEffect(() => {
        console.log( params.id, params.code ); 
        actions(
            VERIFY_EMAIL_ACTION, 
            {
                id:     params.id,
                code:   params.code
            }
        )
        .then(() => setStatus(STATES.SUCCESS))
        .catch(() => setStatus(STATES.NO_SUCCESS))
    }, [])
    let children = <></>
    switch(status ) {
        case STATES.START:
            children = <><Loading /></>
            break
        case STATES.SUCCESS:
            children = <div className="small-container">
                <div className="alert p-5 text-center">
                <div className="lead text-uppercase">
                        { __( "Your E-mail changed successfully" ) }
                    </div>
                    <div className="pt-4">
                        <Link to="/" className=" btn btn-danger px-5 py-3">
                        {
                            __("Return to main page")
                        }
                        </Link>
                    </div>
                </div>
            </div>
            break
        case STATES.NO_SUCCESS:
            children = <div className="small-container">
                <div className="alert alert-danger p-5 text-center ">
                    <div className="lead text-uppercase">
                        {__("No verified user and no change email")}
                    </div>
                    <div className="pt-4">
                        <Link to="/" className=" btn btn-danger px-5 py-3">
                        {
                            __("Return to main page")
                        }
                        </Link>
                    </div>
                </div>
            </div>
            break
    }
    return <div className='pe-change-email-form-container container flex-centered'>
       {children}
    </div>
}
export default ChangeEmailForm