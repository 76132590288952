export const GET_FESTIVAL = ( id=-1, fields: string[]=[] ) => !!fields.length
  ?
  `getPEFestival(id:"${id}") {
    ${fields}
  }`
  :
  `getPEFestival(id:"${id}") {
    id
    title
    content
    description
    domain_type
    domain_type_id 
    clientUrl 
    reglamentTitle 
    reglament
    refreshRate 
    maxMemberId
    memberCardType
    memberPerPage
    horoRaiting
    enabledRules
    isRegisterUser
    isExpertsCriteries
    isHideNotVerifiedProjects
    isIndependentGradeCritery
    isProjectsVerify
    isOwnerRate
    isGanres
    enabledReglament
    isRegisterProject
    isCreateAuthorWithProject
    isShowMap
    isShowReglament
    isShowLaureates
    isShowSchools
    isShowTracks
    isDiary
    isChangeCardType
    maxRating 
    displayProjectResult
    startDate
    finishDate
    maxGanres
    memberCardHeight
    memberCount
    ratingType
    roles
    availableRoles
    status
    clientUrl
    logotype
    icon
    defaultProjectThumbnail
    defaultThumbnail
    link1
    link2
    email
    email2
    vk
    vk2
    telegramm
    telegramm2
    phone
    phone2    
    extendedFields {
      id
      count
      name
      description
      values
      type
      yandex_form_field
      require
      hide_in_page
      hide_in_card
      hide_in_projects
      highlighted
    }
    vk_app_id
    newProjectMessage
    domain_content
    version
    countOfResults
    color
    projectMembersCount
    archived
  }`