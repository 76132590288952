import { IMenuItem } from "@/libs/interfaces/layouts"
import { LayoutIcon } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { Popover, Position } from "@blueprintjs/core"
import { NavLink } from "react-router-dom"

/* Protopia Ecosystem component */
export interface ILoginPanelUnderMenuBtnProps {
    i: number
    item: IMenuItem
}
const LoginPanelUnderMenuBtn = ({i, item}: ILoginPanelUnderMenuBtnProps): JSX.Element => {
    return Array.isArray(item.children) && !!item.children.length
        ?
        <Popover 
            interactionKind="hover"
            key={i}
            className=""
            modifiers={{ arrow: { enabled: false } }}
            position={ Position.LEFT_TOP }
            content={
                <ul className="unsign m-0 p-0 d-flex flex-column">
                {
                    item.children.map((mItem, n) => {
                        return <LoginPanelUnderMenuBtn
                            i={n}
                            item={mItem}
                        />
                    })
                }
                </ul>
            }
        >
           <li 
                className="lmenu position-relative podmenu p-0"
                key={i}
            > 
                <i className="fas fa-caret-left pl-3" />
                <NavLink                       
                    to={item.realRoute}
                    className="menu-element w-100"
                >
                    <LayoutIcon
                        isSVG
                        src={item.icon}
                        className="personal-menu__icon mr-3"
                    />
                    <div className=' '>{ __(item.title) }</div>
                    
                </NavLink>
            </li> 
        </Popover>
        
        :
        <li 
            className="lmenu position-relative podmenu py-0 pl-3 pr-0"  
            key={i}
        >
            <NavLink                       
                to={item.realRoute}
                className="menu-element w-100 "
            >
                <LayoutIcon
                    isSVG
                    src={item.icon}
                    className="personal-menu__icon mr-3"
                />
                <div className=' '>{ __(item.title) }</div>
                
            </NavLink>
        </li>
}
export default LoginPanelUnderMenuBtn