import React, { useState } from 'react' 
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'
import { Button, Dialog } from '@blueprintjs/core' 
import MediaChooser from '../../useful/MediaChooser'
 
const Media =  ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<MediaEnabled {...props} />}
        desabledForm={<MediaDesabled {...props} />}
    /> 
} 
export default Media

export const MediaEnabled = (props: IScalarForm) :JSX.Element => {
    const { field, value, tab, style, id, accept, isUploadHide, isURLHide, hideLib, isFileNameHide, limitSize, height, width } = props 
    const onMediaChange = (fileData: string, file: any ) => {
        if( props.onChange) props.onChange( fileData, props.field, file )
    }
    return <div className="my-0 w-100">
      <MediaChooser
        prefix={`_${ field ? field: "" }${ id ? id : "" }`}
        url={value || ""}
        id={ id ? "" + id : "" } 
        padding={0}
        height={ height || 120 }
        width={ width }
        tab={ tab }
        hideLib={ hideLib }
        accept={ accept }
        isUploadHide={ isUploadHide }
        isURLHide={ isURLHide }
        isFileNameHide={isFileNameHide}
        onChange={ onMediaChange }
        server_url={ "" }
        limitSize={limitSize || 2000000}
        style={style} 
        isHideClearBtn={props.notClear}
      />
    </div>
}
export const MediaDesabled = (props: IScalarForm) :JSX.Element => {
    return props.onChange 
      ?
      <MediaEditableDisabled {...props} />
      :
      <MediaNotEditDisabled {...props} />
}

const MediaNotEditDisabled = (props: IScalarForm) :JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
    const { value } = props
    const {width, height} = props

    const onOpen = ( evt: any ) : void => {
      evt.preventDefault()
      evt.stopPropagation()
      if(value)   setIsOpen(!isOpen)
    }

    const img: JSX.Element = value
      ? 
      <div
        style={{
            backgroundImage: `url(${value})`,
            backgroundSize: "cover",
            width: width ? width : 160,
            height: height ? height : 160,
            opacity: 0.8,
            margin: 6,
            cursor:"pointer"
        }}
        className="media-input"
        onClick={ onOpen }
      />
      :
      <div
        style={{
          width: width ? width : 160,
          height: height ? height : 160,
          margin: 6,
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          opacity: 0.5,
        }}
        className="media-input border border-secondary"
      >
        <i className="far fa-image fa-3x" />
      </div>

    return <div className="px-0 my-1 media-input container">
      <div className='position-relative' > 
        { img }
      </div>
      <Dialog
        isOpen={isOpen}
        onClose={onOpen}
        className="full flex-centered transparent"
      >
        <div className=" position-relative ">
          <img src={value} alt="" style={{ maxHeight:"90vh", width: "100%", maxWidth:800 }} />
          <div className="position-absolute top right m-2">
            <Button onClick={onOpen} icon="cross"></Button>
          </div>
        </div>
      </Dialog>
    </div>
}
const MediaEditableDisabled = (props: IScalarForm) :JSX.Element => {
  const { field, value, style, id, accept, hideLib, limitSize, height, width } = props 
    const onMediaChange = (fileData: string, file: any ) => {
        if( props.onChange) props.onChange( fileData, file, "" )
    }
    return <div className="my-0 w-100">
      <MediaChooser
        prefix={`_${ field ? field: "" }${ id ? id : "" }`}
        url={value || ""}
        id={ id ? "" + id : "" } 
        padding={0}
        height={ height || 150 }
        width={ width }
        tab={ "ld" }
        hideLib={ hideLib }
        accept={ accept }  
        onChange={ onMediaChange }
        server_url={ "" }
        limitSize={limitSize || 2000000}
        style={style} 
        isHideClearBtn 
        isFileNameHide
        isURLHide
        isUploadByBtn
      />
    </div>
}