import { extentions, moduleExists, widgets } from "@/libs/layouts"
import { IUser } from "@/settings/zustand"
import { Fragment, useEffect, useState } from "react"

/* Protopia Ecosystem component */
export interface IThreadWidgetProps {
    widget: string
    value: IUser
    thread: string
    apollo_fields: any
    data_type: string
    className?: string
    item: any
}
export interface IWidget {

}
const ThreadWidget = ({widget, thread, value, item, ...props}: IThreadWidgetProps): JSX.Element => {
    const [widgetObj, setWidgetObj] = useState<JSX.Element>(<Fragment/>)
    useEffect(() => {
        const widgetData: any = widgets()[ widget ] ||   extentions()[ widget ] || {}
        if( moduleExists( widgetData.module ) ) { 
            const promise:Promise<any> = import( `src/modules/${widgetData.module}/widgets/${ widget }` )
            promise.then(
                response => {
                    console.log( response.default )
                    const ElWidget = response.default 
                    setWidgetObj( <ElWidget item={value} thread={thread} parent={item} /> )
                },
                error => console.error(`No exists widget ${ widget }`)
            )
        }
    }, [])

    return <div className='pe-thread-widget-container'> 
        {widgetObj}
    </div>
}
export default ThreadWidget