import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */
export interface ILogDataProps {
    data: any
}
const LogData = ({data}: ILogDataProps): JSX.Element => {
    return !!data
        ?
        <div className="w-100 bg-secondary-cloudy px-2 py-3">
            <div className="lead">{__("Data")}:</div>
            <DataRow data={data} />
            {/* {JSON.stringify(data)} */}
        </div>
        :
        <></>
}
export default LogData

export const DataRow = ({data}: ILogDataProps): JSX.Element | JSX.Element[] => {
    return !!data && typeof data === "object" && typeof data !== "undefined" 
        ? 
        Object.keys(data).map(key => {
            return <div className="container mb-1">
                <div className="d-flex w-100 my-1">
                    <div className="w_120 text-break">
                        {key || "--"}
                    </div>
                    <div className="flex-grow-100 text-break">
                        <DataRow data={data[key]} />
                    </div>
                </div>
            </div>
        })
        :
        <>{ dataToString(data) }</>
}

export const dataToString = (data: string | number | boolean | undefined | null) => {
    switch(typeof data) {
        case "string":
            const str = data.substring(0, 222)
            return str + (str !== data ? "..." : "")
        case "number":
        case "boolean":
        case "undefined":
        default:
            return data
    }
}