import { ID } from "@/libs/interfaces/layouts"
import { useState } from "react"
import Moment from "react-moment"

/* Protopia Ecosystem component */
export interface ILogFileTitleProps {
   title: string
}
const LogFileTitle = (props: ILogFileTitleProps): JSX.Element => {
    const [titleData, ] = useState<ILogTitle>(getLogTitleData( props.title ))

    return <div className='pe-log-file-title-container'> 
      [Land {titleData.land_id.toString()}] ::  
      [<Moment locale="ru" format="D MMMM YYYY" parse={"D MM YYYY"}>
        {titleData.date}
      </Moment>]
    </div>
}
export default LogFileTitle

export interface ILogTitle {
   land_id: ID,
   chapter: string,
   date: string
}
export const getLogTitleData = (title: string) => {
   if(!title)  return {land_id: "", chapter:"", date: ""}
   const titleArr = title.split(".")
   return {
      land_id: titleArr[0],
      chapter: titleArr[1],
      date: titleArr[2]
   }
}