import { imgToDataURL } from "@/libs/utilities"

export default async function landToJSON (json: any) : Promise<string> {
    json.Land = {...json.land}
    json.PEFestFestival = {...json.festival}
    delete json.clear
    delete json.item
    delete json.land
    delete json.festival

    // land
    // json.Land = {
    //     ...json.Land,
    //     ...urlToBlob("icon", json.Land.icon),
    //     ...urlToBlob("defaultThumbnail", json.Land.defaultThumbnail),
    // }  
    return JSON.stringify( await json )
}

const urlToBlob = async ( field: string, url: string): Promise<any> => {
    if( !url ) return null
    if( url.lastIndexOf("http") === 0 ) {
        const [...parts] = url.split('/')
        const fileName = parts.pop()

        let dataURL = ""
        try {
            dataURL = await imgToDataURL(url)
        }
        catch(err: any){ } 
        const dt = dataURL
        ?
        {
            [field+"Name"]:  fileName,
            [field] : dataURL
        }
        :
        "" 
        return dt
    }
    if( url.indexOf("data:image/") ) {
        return new Promise( resolve => {
            resolve({[field] : url})
        } ) 
    }
}