import { IFestival, IProject } from "@/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from "@/modules/pe-fest-module/data/store"
import { useUserOwner } from "@/modules/pe-fest-module/hooks/project"
import { __ } from "@/libs/utilities"
import { component_url } from "@/libs/utilities/links"
import { Link } from "react-router-dom"

const { useSwiper } =  require("swiper/react")  

/* Protopia Ecosystem component */
export interface IProjectMobileNavyProps { 
    activeIndex: number
    setActiveIndex : ( slideId: number ) => void
}
const ProjectMobileNavy = (props: IProjectMobileNavyProps): JSX.Element => {
    const festival: IFestival   = useFestDataStore((state: IFestDataStor) => state.festival) || {}  
    const item: IProject        = useFestProjects( (state:IFestProjectsProps) => state.currentProject ) || {}
    const isUserOwner: boolean  = useUserOwner( item, festival.id )
    const listLink = `${component_url("FestivalView")}fest/${festival.id}/members` 
    const swiper = useSwiper()
    
    const slideTo = ( slideId: number) => {
        swiper.slideTo( slideId )
        props.setActiveIndex( slideId ) 
        // swiper.setActiveIndex(slideId)
    }
    return <div className='pe-project-mobile-navy-container' >
        <div 
            className={`navi-btn settings  ${ props.activeIndex === 0 ? "active" : "" }`}  
            onClick={() => slideTo(0) }
        >
            <div className="icon" style={{backgroundImage:`url(/assets/img/fest/project-settings.svg)`}}>

            </div>
            <span className="navi-title">{__("Info")}</span>
        </div>
        <div 
            className={`navi-btn rating  ${ props.activeIndex === 1 ? "active" : "" }`}  
            onClick={() => slideTo(1) }
        >
            <div className="icon" style={{backgroundImage:`url(/assets/img/fest/project-rating.svg)`}}>

            </div>
            <span className="navi-title">{__("Rait")}</span>
        </div>
        <div 
            className={`navi-btn descriptions  ${ props.activeIndex === 2 ? "active" : "" }`}  
            onClick={() => slideTo(2) }
        >
            <div className="icon" style={{backgroundImage:`url(/assets/img/fest/project-experts-messages.svg)`}}>

            </div>
            <span className="navi-title">{__("Exhortations")}</span>
        </div>
        {
            isUserOwner && <>
                <div 
                    className={`navi-btn team  ${ props.activeIndex === 3 ? "active" : "" }`}  
                    onClick={() => slideTo(3) }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/project-team.svg)`}}>

                    </div>
                    <span className="navi-title">{__("Team")}</span>
                </div>
                <div 
                    className={`navi-btn manage  ${ props.activeIndex === 4 ? "active" : "" }`}  
                    onClick={() => slideTo(4) }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/project-edit.svg)`}}>

                    </div>
                    <span className="navi-title">{__("Manage")}</span>
                </div>
            </>
        }
        
        <Link to={listLink} className={`navi-btn active`} >
            <div className="icon" style={{backgroundImage:`url(/assets/img/fest/share-square.svg)`}}>

            </div>
        </Link> {/* */}
    </div>
}
export default ProjectMobileNavy