
export const CLEAR_FESTIVAL_PRESCEPTIONS = ( data: any ) => {
    const gql: string =  `mutation clearPEFestivalData($land_id: ID) {
        clearPEFestivalData ( 
            land_id: $land_id
            input : {
                logs: ${data.logs}
                options: ${data.options}
                projects: ${data.projects}
                ratingSystem: ${data.ratingSystem}
                raitingData: ${data.raitingData}
                schools: ${data.schools}
                destricts: ${data.destricts}
                messages: ${data.messages}
            }
        ) {
            logs
            options
            projects
            ratingSystem
            raitingData
            schools
            destricts
            messages
        }
    }`
    return gql
}