import { APOLLO_FIELD_FILTER_TYPE, IAppoloField, ID, IMetaFilter, IPaging, ITax, ITaxonomyFilter, Role } from '@/libs/interfaces/layouts'
import { __ } from '@/libs/utilities'
import { Button, ButtonGroup, Collapse, Intent } from '@blueprintjs/core'
import { useEffect, useState } from 'react'
import { apolloFields } from 'src/libs/layouts'
import { IState, useMainStore } from '../../../../settings/zustand'
import { IAdminStore, IBulk, useAdminStore } from '../../data/store'
import BulkDeleter from './BulkDeleter'
import BulkEditor from './BulkEditor'
import BulkVisibler from './BulkVisibler'
import MetaFilterElement from './MetaFilterElement'
import DataTableSearchPanel from './DataTableSearchPanel'
import TaxonomyFilterElement from './TaxonomyFilterElement'
import BulkDuplicator from './BulkDuplicator'

const DataTableFiltersPanel = (props: IDataTableFilterProps): JSX.Element => {
    const bulks: IBulk[]                        = useAdminStore((state:IAdminStore) => state.bulks) 
    const search: string                        = useAdminStore((state:IAdminStore) => state.search) 
    const setMetaFilter                         = useAdminStore((state:IAdminStore) => state.setMetaFilter) 
    const setPost_status                         = useAdminStore((state:IAdminStore) => state.setPost_status) 
    const setIn__roles                          = useAdminStore((state:IAdminStore) => state.setIn__roles) 
    const setSearch                             = useAdminStore((state:IAdminStore) => state.setSearch) 
    const setTaxonomies                         = useAdminStore((state:IAdminStore) => state.setTaxonomies) 
    const landId : ID                           = useMainStore((state: IState) => state.landId )
    const [filters,]                            = useState<any[]>( [] )
    const [metaFilters, setMetaFilters]         = useState<any[]>( [] ) 
    const [relation, setRelation]               = useState<"OR" | "AND" >( 'OR' )
    const [isSettingsOpen, setIsSettingsOpen]   = useState( false )
    const [isFilterOpen, setIsFilterOpen]       = useState( false ) 

    const pageCount: number = useMainStore(( state: IState ) => state.pageCount)
    const setPageCount: any = useMainStore((state: IState ) => state.setPageCount)
    
    
    // console.log( in__roles, metaFilter )

    useEffect(() => {
        // set filters and meta_filters
        const fields: IAppoloField[] = Object.keys( apolloFields( props.data_type ) ).map( af => ({ ...apolloFields( props.data_type )[af], name: af }) )
        const _metaFilters: any[] = []
        fields.forEach( (field: any, i: number) => { 
            if( field.filter_type === APOLLO_FIELD_FILTER_TYPE.TAXONOMIES ) {
                _metaFilters.push(
                    <TaxonomyFilterElement 
                        key={ i } 
                        field={ field } 
                        onFilter = { onTaxonomy }
                    /> 
                )
            }
            if( field.filter_type === APOLLO_FIELD_FILTER_TYPE.META ) { 
                _metaFilters.push(
                    <MetaFilterElement 
                        key={ i } 
                        field={ field } 
                        onFilter = { onFilter }
                    /> 
                )
            }
        }) 
        setMetaFilters(_metaFilters)
    }, [ props.data_type ])


    const onFilter = ( key: string, value: any ) => { 
        let _in__roles: string[] | null = null

        //console.log( useAdminStore.getState().metaFilter, key ) 

        let _metas = [ ...useAdminStore.getState().metaFilter ].filter( m => m.key !== key ) 

        //console.log( _metas )

        if( key === "roles" && !!value?.length && props.data_type === "User" ) { 
            _in__roles = value
        }
        else if(key === "post_status") {
            setPost_status( value )
        }
        else if( value !== null && ( value?.length !==  0) ) { 
            _metas.push({ key, value} as IMetaFilter )
        }
    
        setMetaFilter( _metas ) 
        setIn__roles( _in__roles )
        onSetPaging()
    }

    const onTaxonomy = (tax_name:string, term_ids: Number[]) => { 
        let _taxonomies = (useAdminStore.getState().taxonomies || [])
            .filter( (t: ITaxonomyFilter) => t.tax_name !== tax_name )
        _taxonomies.push({tax_name, term_ids})
        console.log(_taxonomies)
        setTaxonomies(_taxonomies)
        onSetPaging()
    }

    const onSearch =(_search: string) => {
        setSearch(_search)
        onSetPaging()
    }

    const clearFilter = () => {
        setMetaFilter([])
        setIn__roles(null)
        setRelation("OR")
        props.clearFilter()
    }

    const onSetPaging = () => {
        let paging: IPaging = {
            metas:          useAdminStore.getState().metaFilter,
            in__roles :     useAdminStore.getState().in__roles,
            post_status:    useAdminStore.getState().post_status,
            relation:       relation,
            search:         useAdminStore.getState().search,
            taxonomies:     useAdminStore.getState().taxonomies,
        } 
        if( props.onFilter ) {
            props.onFilter(paging)
        }
    } 
    const filterBtn = filters.length > 0 || metaFilters.length > 0
	    ?
		<Button 
            minimal 
            icon={ isFilterOpen ? "filter-remove" : "filter" }
            onClick={ () => setIsFilterOpen(!isFilterOpen ) }
        >
			{ __(isFilterOpen ? "hide Filters" : "show Filters") }
		</Button>
	    :			
		null
	
    const filterBlock = filters.length > 0 || metaFilters.length > 0
	    ? 
        <Collapse isOpen={isFilterOpen} className="w-100">
            <div className="p-3 pe-light mt-2">
                <div className="d-flex flex-wrap mb-2">
                    { filters }
                    { metaFilters }
                </div>
                <div className="d-flex flex-wrap">
                    <ButtonGroup className="flex-wrap ">
                        <Button
                            minimal={relation === "AND"}
                            intent={Intent.SUCCESS}
                            data-relation="OR"
                            onClick={() => {
                                setRelation('OR')
                                onSetPaging()
                            }}
                        >
                            { __("All by any fields")}
                        </Button>
                        <Button
                            minimal={relation === "OR"}
                            intent={Intent.DANGER}
                            data-relation="AND"
                            onClick={() => {
                                setRelation('AND');
                                onSetPaging()
                            }}
                        >
                            {__("Only have all fields")}
                        </Button>
                    </ButtonGroup>
                    <Button minimal onClick={ clearFilter } className="ml-3 w-32 w-sm-100">
                        { __("clear filters") }
                    </Button>
                </div>
            </div>
        </Collapse> 
	    :			
		null 



    
    return <>
        <div className="d-flex w-100">
            <Button
                minimal
                className="ml-2"
                icon="cog"
                onClick={() => setIsSettingsOpen( !isSettingsOpen )}
            >
                { __( isSettingsOpen ? "hide Settings" : "show Settings") }
            </Button>
            { filterBtn }
            <div className="ml-auto">
                <DataTableSearchPanel search={search} onSearch={onSearch} />               
            </div> 
        </div>
        <Collapse isOpen={ isSettingsOpen } className="w-100">
            <div className="p-3 pe-light mt-2 border-bottom-dark">
                <div className="row align-items-center ">
                    <div className="h-100 col-md-5 text-md-left text-center  align-items-center">
                        {__("Count elements by page:")}
                    </div>
                    <input
                        type="number"
                        className="form-control input dark ml-2 col-md-6"
                        value={ pageCount }
                        onChange={ (evt: any ) => setPageCount( evt.target?.value )}
                    />
                </div>
            </div>
        </Collapse>
		{ filterBlock } 
        <Collapse isOpen={ !!bulks.filter((bulk: IBulk) => bulk.checked).length } className="w-100">
            <div className="p-3 pe-light mt-2 border-bottom-dark">
                <ButtonGroup className="d-flex align-items-center flex-wrap">
                    <BulkDeleter data_type={props.data_type}/> 
                    <BulkEditor data_type={props.data_type} landId={landId} /> 
                    <BulkDuplicator onConfirm={ () => { if( props.onDoubled) props.onDoubled() } }/> 
                    <BulkVisibler data_type={props.data_type}/>                    
                </ButtonGroup>
            </div>
        </Collapse>
    </>
} 

export default DataTableFiltersPanel

export interface IDataTableFilterProps {
    data_type: string
    data_status?: string
    count: number
    onChangeCount : ( count: number ) => void
    clearFilter : () => void
    onFilter?: ( paging: IPaging ) => void
    onDoubled?: () => void
}