import { __ } from "@/libs/utilities"
import { IFestForm, IFestival } from "@/modules/pe-fest-module/data/interfaces"
import { Tab, Tabs } from "@blueprintjs/core"
import PatternsTab from "./add-festival-tabs/PatternsTab"
import PelandFileTab from "./add-festival-tabs/PelandFileTab"
import PureTab from "./add-festival-tabs/PureTab"
import StewTab from "./add-festival-tabs/StewTab"

/* Protopia Ecosystem component */
export interface IAddFestivalTabsProps {
    festival: IFestival
    item: IFestForm
    on: (value: any) => void
}
const AddFestivalTabs = (props: IAddFestivalTabsProps): JSX.Element => {
    return <div className='pe-add-festival-tabs-container'>
        <Tabs vertical className="w-100">
            <Tab 
                id="clear" 
                title={__("By pure")} 
                panel={<PureTab {...props} />}
                className="layout-label" 
                panelClassName="flex-grow-100 opacity_1" 
            /> 
            <Tab 
                id="pattern" 
                title={__("By pattern")} 
                panel={<PatternsTab {...props} />} 
                className="layout-label" 
                panelClassName="flex-grow-100 opacity_1" 
            />  
            <Tab 
                id="stew" 
                title={__("Stew")} 
                panel={<StewTab {...props} />} 
                className="layout-label" 
                panelClassName="flex-grow-100 opacity_1" 
            /> 
            <Tab 
                id="peland" 
                title={__("By *.peland scenario file")} 
                panel={<PelandFileTab {...props} />} 
                className="layout-label" 
                panelClassName="flex-grow-100 opacity_1" 
            /> 
        </Tabs>
    </div>
}
export default AddFestivalTabs


