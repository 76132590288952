import { __ } from "@/libs/utilities"
import { Tag } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface ILogUserProps {
   user: any
}
const LogUser = ({user}: ILogUserProps): JSX.Element => { 
    return <div className="d-flex w-100 mb-1 pb-1 pt-1 opacity_75 align-items-center small ">
        <div className="opacity_5 ">{ user?.ID }</div>
        <div className="mx-2">{ user?.display_name || __("No logged user") }</div>
        <div className="d-flex flex-wrap">
            { user?.roles?.map((r:string) => <Tag className="m-1 px-2 py-0 "><div className="small">{ __( r ) }</div></Tag>) }
        </div>
    </div>
}
export default LogUser