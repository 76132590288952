import { DIALOG_SIZE, ID, IPost } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import { useFestExport } from "@/modules/pe-fest-module/data/store"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IResourceParentBtnProps<T> {
    resources: T | T[]
    dataTypeLabel: string
    onChoose: (item: T) => void
}
function ResourceParentBtn<T extends IPost>({resources, dataTypeLabel, ...props}: IResourceParentBtnProps<T>): JSX.Element { 
    const allResources: T[]     = useFestExport((state: any) => state[ dataTypeLabel.toLowerCase() ]) || [] 
    const [value, setValue]     = useState<T>( Array.isArray(resources) ? resources[0] : resources ) 
    const onValue = (value: ID ) => { 
        setValue( allResources.filter(m => m.id === value)[0] )
    }
    return <div className='pe-school-destrict-btn-container'>
        <ConfirmButton
            buttonLabel={
                <div className="flex-centered hint hint--top" data-hint={__(dataTypeLabel)}>
                    <div 
                        className="d-flex d-md-none pe-fest-resource-icon color-force-contrast" 
                        style={{backgroundImage:`url(/assets/img/fest/${dataTypeLabel.toLowerCase()}-icon.svg)`}} 
                    /> 
                    {
                        value?.title ? value.title : "---"
                    }
                </div>
            }
            buttonMinimal
            dialogClasssName={DIALOG_SIZE.MINIMAL}
            onConfirm={() => props.onChoose( value ) }
        >
            <div className="p-4">
                <FieldInput
                    editable
                    value={value}
                    vertical={VerticalType.VERTICAL}
                    onChange={onValue}
                    type={SCALAR_TYPES.TAGS}
                    values={ allResources.map(m => ({...m, _id: (m.id || m.ID) })) }
                />
            </div>
        </ConfirmButton>
    </div>
}
export default ResourceParentBtn