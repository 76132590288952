import { Button, Dialog, Icon, Intent } from "@blueprintjs/core"
import { ITrack } from "../../data/interfaces"
import { __ } from "@/libs/utilities"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface ITrackVideoProps {
   item:ITrack
}
const TrackVideo = (props: ITrackVideoProps): JSX.Element => {
    const[isVideoOpen, setVideoOpen] = useState(false)
    
    const playVideo = (evt: any) => {
        evt.stopPropagation()
        evt.preventDefault()
        setVideoOpen(true)
      }
      const stopVideo = (evt: any) => {
        evt.stopPropagation()
        evt.preventDefault()
        setVideoOpen(false)
      } 
    
    return props.item.video_id 
        ?
        <> 
            <div className='pe-track-video soc codepen ' onClick={playVideo}>
                <i className="fas fa-play" />
            </div>
            <Dialog
                isOpen={ isVideoOpen }
                onClose={ stopVideo }
            >
                <div className='position-relative'>
                    <iframe 
                        title={ props.item.title }
                        width="800" 
                        height="450" 
                        src={`https://www.youtube.com/embed/${props.item.video_id}?autoplay=1`} 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen
                    />
                    <Button className='position-absolute top right px-3 py-2' onClick={stopVideo} intent={Intent.DANGER}>
                        <Icon size={33} color="#FFFFFF" icon="cross" />
                    </Button>
                </div>
                
            </Dialog>
        </>
        :
        <></>
}
export default TrackVideo