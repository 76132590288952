import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { __ } from '@/libs/utilities'
import { ICategory, ICritery, IProject, IProjectMilestone } from '../../data/interfaces'
import Category from './rating/Category'
import Critery from './rating/Critery'
import { Tag } from '@blueprintjs/core'

interface IProjectRatingProps {
    item: IProject
    milestone?: IProjectMilestone | undefined
}

const ProjectRating = (props: IProjectRatingProps): JSX.Element => {
    const criteries: ICritery[]     = [...useDataStore((state: any) => state.PEFestCritery )];
    const categories: ICategory[]   = [];
    const items : JSX.Element | JSX.Element[] = !!props.item.is_verified && Array.isArray(criteries) && !!criteries.length
        ?
        criteries
            .sort((a: ICritery, b: ICritery) => { 
                return (a?.order === b?.order) 
                    ?
                    0 
                    :
                    a?.order > b?.order ? 1 : -1
            })
            .filter((critery:ICritery) => {
                if( !!props.milestone )
                {
                    return critery.milestone?.id === props.milestone?.id
                }
                return  !critery.milestone
            })
            .filter((critery:ICritery) => { 
                if(critery.category?.id && !categories.filter((category:ICategory) => category.id === critery.category.id).length) {
                    //console.log( critery.category )
                    categories.push( critery.category )
                    //console.log( categories )
                }
                return  !critery.category?.id
            })
            .map((critery:ICritery) => {   
                return <Critery 
                    item={critery}
                    key={critery.id} 
                    project={ props.item}
                    milestone={props.milestone!}
                />
            })
        :
        <div className="alert alert-danger py-5 lead"> {
            __( 
                !!props.item.is_verified 
                    ?
                    "No criteries exists"
                    :
                    "Rating disabled. Reason: the project has not yet been approved"
            ) 
        }</div>
    const cats: JSX.Element[] = !!props.item.is_verified
        ?
        categories.map( ( category:ICategory ) => {
            //console.log( category )
            return <Category 
                item={ category } 
                key={ category.id }
                criteries={ criteries.filter( ( critery: ICritery ) => critery.category?.id === category.id ) }
                project={ props.item }
                milestone={ props.milestone }
            />
        } )
        :
        []
    return <div className="container">
        <MilestoneTitle milestone={props.milestone} /> 
        { cats }
        { items } 
    </div>
}
export default ProjectRating

interface IMTitlePros {
    milestone: IProjectMilestone | undefined
}

const MilestoneTitle = ({ milestone }: IMTitlePros) : JSX.Element => {
    return !milestone
        ?        
        <></>
        :
        <>
            <div className='display-6 '>
                {__("Milestone")}: { milestone.milestone.title }
            </div>
            <div className="mb-0">
                { milestone.milestone.post_content }
            </div>  
            {
                !!milestone.experts.length && <div className="small my-1">
                    <span>
                        {__("Experts, who put raiting:")}
                    </span>
                    <span className=''>
                        { milestone.experts.map(user => {
                            return <Tag round minimal className='title ml-1 mb-1 px-2' key={user.id}>
                                { user.display_name }
                            </Tag>
                        }) }
                    </span>
                </div>
            }     
            <div className="mb-3 small">
                <span>
                    {__("Roles for which rate is available")}:
                </span>
                <span className=''>
                    { milestone.milestone.roles.map(r => {
                        return <Tag round className='title ml-1 mb-1 px-2' key={r}>
                            {__(r)}
                        </Tag>
                    }) }
                </span>
            </div>
        </>
}