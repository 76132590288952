import { DIALOG_SIZE, ID, POST_STATUS, Role } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { IUser } from "@/settings/zustand"
import { Button, ButtonGroup, Dialog, Intent } from "@blueprintjs/core"
import { useState } from "react"
import { useNavigate, useParams } from "react-router"
import { IFestival, IProject } from "../../data/interfaces"
import { IFestDataStor, useFestDataStore } from "../../data/store"
import getProjectLink from "../../data/utils/getProjectLink"
import MemberAllowForm from "./MemberAllowForm"
import MemberStatusForm from "./MemberStatusForm"

/* Protopia Ecosystem component */
export interface ISingleProjectFormProps {
    item:IProject
	route: string
    onChangeProject: (id: ID, field: keyof IProject, value: any) => void
}
const SingleProjectForm = ({item, onChangeProject, ...props}: ISingleProjectFormProps): JSX.Element => {
	const {landId} = useParams()
	const navigate = useNavigate()
    const festival : IFestival = useFestDataStore( (state: IFestDataStor) => state.festival )
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isVerifyOpen, setIsVerifyOpen] = useState<boolean>(false)
    const [reason, setReason] = useState<string>("")
    const onLinkMember = ( ) => 
    { 
        const url = getProjectLink( item.id )
        navigate( url )
    }
    const onChangeStatus = (member_id: ID, post_status: POST_STATUS) =>
    { 
        setIsLoading(true)
        actions(
            UPDATE_SINGLE_TYPE,
            {
                data_type: "PEFestProject",
                id: member_id,
                item: { post_status },
                landId
            }
        )
        .then(resp => {
            console.log( resp )
            setIsLoading(false)
            onChangeProject( resp.id, "post_status", resp.post_status )
        })
    }
    const onChangeVerify = (member_id: ID, is_verified: boolean) =>
    {
        is_verified ? onSendVerify(member_id, is_verified) : setIsVerifyOpen(true)
    }
    const onPressSendVerify = ( ) => {
        onSendVerify(item.id, false, reason)
    }
    const onSendVerify = (member_id: ID, is_verified: boolean, verified_reason:string="") => {
        console.log( is_verified )
        setIsLoading(true)
        actions(
            UPDATE_SINGLE_TYPE,
            {
                data_type: "PEFestProject",
                id: member_id,
                item: { is_verified, verified_reason }, 
                landId 
            }
        )
        .then(resp => {
            setIsLoading(false)
            onChangeProject( resp.id, "is_verified", resp.is_verified )
            setIsVerifyOpen(false)
        })
    }
    const onTutorChange = (value: IUser, member_id: ID) =>
    { 
        setIsLoading(true)
        actions(
            UPDATE_SINGLE_TYPE,
            {
                data_type: "PEFestProject",
                id: member_id,
                item: { tutor: value?.id || "-1" },
                landId
            }
        )
        .then(resp => {
            onChangeProject( resp.id, "tutor", resp.tutor ) 
            setIsLoading(false)
        })
    }
    
	const onAuthorChange = (value: IUser, member_id: ID) =>
    {
        setIsLoading(true)
        actions(
            UPDATE_SINGLE_TYPE,
            {
                data_type: "PEFestProject",
                id: member_id,
                item: { leader: value?.id || "-1" },
                landId
            }
        )
        .then(resp => {
            onChangeProject( resp.id, "leader", resp.leader ) 
            setIsLoading(false)
        })
    }

    return <div className="col-12 grey2 " >
        <div className={`row flex-centered py-5 py-md-0 ${ isLoading ? "opacity_5 untouchble ": "" }`}>
            <div className={`col-md-1 col-2 py-2 lead justify-content-end text-secondary ${ item.post_status === POST_STATUS.DRAFT ? "opacity_5" : "" } d-flex`} >
                {item.order}
            </div>
            <div 
                className={ `col-md-4 col-10 py-2 ${ item.post_status === POST_STATUS.DRAFT ? "opacity_5 d-none" : "" } d-flex flex-column track-moder-title-cell` }
            >
                <div className=" lead text-center text-md-left w-100">
                    {item.title}
                </div>
                <div className="d-flex py-2">
                    {item.fmru_school.map(sc => <span className="px-2 small " key={sc.id} >{sc.title}</span>)}
                </div>
                <div className="d-flex mt-1 cell-hover">
                    <div
                        className="text-primary hover-straght pointer small px-2"
                        onClick={ onLinkMember }
                    >
                        {__("Goto")}
                    </div>
                    {
                        item.post_status === POST_STATUS.DRAFT
                            ?
                            <div 
                                className="text-danger hover-straght pointer small px-2"
                                onClick={() => onChangeStatus( item.id, POST_STATUS.PUBLISH )}
                            >
                                {__("Do show")}	
                            </div>
                            :
                            <div 
                                className="text-primary hover-straght pointer small px-2"
                                onClick={() => onChangeStatus(item.id, POST_STATUS.DRAFT)}
                            >
                                {__("Do hide")}	
                            </div>
                    }
                </div>
            </div>
            <div className={`col-md-5 p-0 ${ item.post_status === POST_STATUS.DRAFT ? "opacity_5" : "" }`}>

            { 
                festival?.availableRoles && 
                festival?.availableRoles.filter((role: Role) => role === "Tutor").length > 0
                    ?
                    <FieldInput 
                        type={SCALAR_TYPES.USER}
                        title={__("Tutor")}
                        editable
                        component={"User"}
                        paging={` role__in: [ "Tutor" ]`}
                        landId={landId}
                        value={  item.tutor } 
                        isMulti
                        onlyThisLands
                        onChange={ value => onTutorChange(value, item.id) }
                    />
                    :
                    null
            }
            {
                festival?.availableRoles!.filter((role: Role) => role === "Project_author").length > 0
                    ?
                    <FieldInput 
                        type={SCALAR_TYPES.USER}
                        title={__("Project_author")}
                        editable
                        component={"User"}
                        paging={` role__in: [ "Project_author" ]`}
                        landId={landId}
                        value={  item.leader } 
                        isMulti
                        onlyThisLands
                        onChange={ value => onAuthorChange(value, item.id) }
                    />
                    :
                    null 
            }
            </div> 
            <div className="col-md-2 " >
                <MemberStatusForm
                    item={item}
                    onChangeStatus={onChangeStatus}
                />
                <MemberAllowForm
                    item={item}
                    onChangeVerify={onChangeVerify}
                />
            </div>
        </div>
        <Dialog
            isOpen={isVerifyOpen}
            onClose={() => setIsVerifyOpen(false)}
            className={DIALOG_SIZE.MINIMAL}
            title={__("Send reason")}
        >
            <div className="p-4">
                <FieldInput
                    type={SCALAR_TYPES.TEXT}
                    placeholder={__("Send email text")}
                    value={reason}
                    onChange={ value => setReason(value) }
                    className={"w-100 h_250"}
                    editable
                />
            </div>
            <ButtonGroup fill >
                <Button fill intent={Intent.DANGER} onClick={() => setIsVerifyOpen(false)}>
                    {__("Escape")}
                </Button>
                <Button fill intent={Intent.SUCCESS} onClick={onPressSendVerify}>
                    {__("Send and mail")}
                </Button>
            </ButtonGroup>
        </Dialog>
    </div>
}
export default SingleProjectForm