import { Fragment, useEffect, useState } from "react"
import { areas, extentions, moduleExists, widgets } from "../layouts"

/* Protopia Ecosystem component */
export interface IWidgetProps { 
    areaName:string
    data: any                   // config data
    defArea?: JSX.Element | null // default element
}
const Widgets = ({areaName, data, defArea}: IWidgetProps) : JSX.Element | null => {
    const [widget, setWidget] = useState<JSX.Element | JSX.Element[]>( defArea || <Fragment /> )
    useEffect(() => {  
        if( !areas()[areaName] ) { 
            console.log(`unknown widget area: ${areaName}`)
            return setWidget( defArea || <Fragment /> )
        }
        if( !moduleExists(areas()[areaName]?.module ) ) {
            return setWidget( defArea || <Fragment /> )
        }
        const ts: any[] = areas()[areaName]?.area
        if( Array.isArray(ts) ) {
            let i: number = 0;
            let _widgets: JSX.Element[] = []
            const getWidget = (step: number) => {  
                const widgetAreaData: any = ts[i]
                if( widgetAreaData) {                    
                    const widgetData: any = widgets()[ widgetAreaData.component ] || extentions()[ widgetAreaData.component ] || {}
                    if( moduleExists( widgetData.module ) ) { 
                        // console.log(widgetAreaData, widgetData)
                        const promise:Promise<any> = import( `src/modules/${widgetData.module}/widgets/${ widgetAreaData.component }` )
                        promise.then(
                            response => {
                                const ElWidget = response.default 
                                _widgets[i] = <ElWidget {...ts[i]} {...data} defArea={defArea} key={ ts[i].component } /> 
                                i = step + 1
                                if( i < ts.length) {
                                    getWidget( i )
                                }
                                else {
                                    setWidget ( _widgets )
                                }
                            },
                            error => console.error(`No exists widget ${ widgetAreaData.component }`)
                        )
                    }
                }   
            }
            getWidget(0) 
        }
        else {
            setWidget( defArea || <Fragment /> )
        }
    }, []) 
    return <>
        {widget}
    </>
}
export default Widgets