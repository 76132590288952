import React, { useState } from 'react' 
import FieldInput from '@/libs/scalars'
import { __ } from '@/libs/utilities'
import { Button, Intent } from '@blueprintjs/core'
import { SCALAR_TYPES } from '@/libs/scalars/interface/IScalarForm'
import { PEFestClearPrescription } from '@/modules/pe-fest-module/data/interfaces'

interface ICleanMasterProps {
    onStartSnapshot: ( data: any ) => void
}

const SnapShotMaster = (props: ICleanMasterProps) : JSX.Element => {
    const[raitingData, setRatingData] = useState(PEFestClearPrescription.IGNORE)
    const[ratingSystem, setRatingSystem] = useState(PEFestClearPrescription.IGNORE) 
    const[projects, setProjects] = useState(PEFestClearPrescription.IGNORE)  
    const[users, setUsers] = useState(PEFestClearPrescription.IGNORE)
    const changeRatingData = (value: PEFestClearPrescription) =>
    {
        setRatingData(value)
    }
    const changeUsersData = (value: PEFestClearPrescription) =>
    {
        setUsers(value)
    }
    const changeRatingSystem = (value: PEFestClearPrescription) =>
    {
        setRatingSystem(value)
    } 
    const changeProjects = (value: PEFestClearPrescription) =>
    {
        setProjects(value)
    } 

    const onStartSnapshot = () =>
    {
        const data = {
            raitingData, ratingSystem, projects, users 
        }
        props.onStartSnapshot(data)
    }
    return <div>
        <div className='display-6 mb-3'>
            {__("Snapshot festival data")}
        </div>
        <FieldInput 
            title={__("All rating data")} 
            type={SCALAR_TYPES.TAGS}
            ID="raitingData"
            id="raitingData"
            commentary={__("Include all expert's rates, commentaries, all expert statistics")}
            value={ raitingData }
            values={[
                { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                { _id:PEFestClearPrescription.CLEAR, title:__("Add") }, 
            ]}
            default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
            editable
            onChange={changeRatingData}
        />
        <FieldInput  
            title={__("All rating system")}
            type={SCALAR_TYPES.TAGS}
            ID="ratingSystem"
            id="ratingSystem"
            commentary={__("Include all categories, criteries, milestones and ganres")}
            value={ ratingSystem }
            values={[
                { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                { _id:PEFestClearPrescription.CLEAR, title:__("Add") }, 
            ]}
            default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
            editable
            onChange={changeRatingSystem}
        />
        <FieldInput 
            title={__("All projects system")}
            type={SCALAR_TYPES.TAGS}
            ID="projects"
            id="projects"
            commentary={__("Include all projects, tracks and honeycombs. All projects includes and extened fields data")}
            value={ projects }
            values={[
                { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                { _id:PEFestClearPrescription.CLEAR, title:__("Add") }, 
            ]}
            default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
            editable
            onChange={changeProjects}
        />
        <FieldInput 
            title={__("Include all users")}
            type={SCALAR_TYPES.TAGS}
            ID="users"
            id="users"
            commentary={__("")}
            value={ users }
            values={[
                { _id:PEFestClearPrescription.IGNORE,  title:__("Ignore") },
                { _id:PEFestClearPrescription.CLEAR, title:__("Add") }, 
            ]}
            default={ {_id:PEFestClearPrescription.IGNORE,  title:"Ignore"} }
            editable
            onChange={changeUsersData}
        /> 
        <div className='mt-4'>
            <Button
                intent={Intent.DANGER}
                className="lead p-4"
                fill
                onClick={ onStartSnapshot }
            >
                {__("Start snapshot now")}
            </Button>
        </div>
    </div> 
} 

export default SnapShotMaster