import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import { Intent } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface IBulkDuplicatorProps { 
    onConfirm: () => void
}
const BulkDuplicator = (props: IBulkDuplicatorProps): JSX.Element => {
    return <>
        <ConfirmButton 
            buttonIcon="duplicate" 
            buttonLabel={__("Doubled bulk items")}
            buttonClassName=" h-100 px-4" 
            buttonIntent={Intent.SUCCESS} 
            buttonMinimal
            onConfirm={ props.onConfirm }
            confirm={__("Really duplicate selected items?")}
        /> 
        
    </>
}
export default BulkDuplicator