import { LayoutIcon } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { component_url } from "@/libs/utilities/links"
import { Icon, Tag } from "@blueprintjs/core"
import { useNavigate } from "react-router"
import { ILand } from "../../data/interfaces"
import PhaseLabel from "../PhaseLabel"
import FestivalSiteOwnerForm from "./FestivalSiteOwnerForm"
import FestPatternLabel from "./FestPatternLabel" 
import EventTypeLabel from "@/modules/pe-topos-module/views/labels/EventTypeLabel"
import { IEvent } from "@/modules/pe-topos-module/data/interfaces"
import { eventTypes } from "@/modules/pe-topos-module/data/mocks/eventTypes"

/* Protopia Ecosystem component */
export interface IFestivalAdminButtonProps {
    item: ILand
}
const FestivalAdminButton = (props: IFestivalAdminButtonProps): JSX.Element => {
    const navigate = useNavigate() 
    const onGoto = () => {
        const url = component_url("FestivalViewForm")
        navigate( `${url}/fest/${props.item.id}` )
    }
    const onEdit = () => {
        navigate( props.item.id.toString() )
    } 
    return <li className='list-group-item strob01'>
        <div className="row">
            <div className="col-md-1 pl-2 py-3 flex-centered pe-surface">
                { props.item.id }
            </div> 
            <div className="col-md-7 p-3 d-flex justify-content-between w-100 flex-wrap">
                <div className="flex-centered">
                    <div className="p-3 bg-light mr-3 pe-surface3">
                        <LayoutIcon
                            height={35}
                            width={35}
                            style={{ height: 35, width: 35 }}
                            src={props.item.icon}
                            className=""
                        />
                    </div> 
                    <div className="flex-column">
                        <div className="mb-1 d-flex align-items-center">
                            <div 
                                className={`lead mr-4 px-2 ${ props.item.isPattern ? "border border-secondary hint hint--top" : "" }`}
                                data-hint={__("This Event is pattern for over")}
                            >
                                { props.item.name }
                            </div> 
                            <PhaseLabel phase={props.item.status} className=" small "/>
                        </div>
                        <EventTypeLabel 
                            item={{
                                ...props.item, 
                                eventTypes: eventTypes().filter(et => props.item.event_types.includes( et.title ) ),
                                start: props.item.startDate || new Date().getTime(),
                                finish: props.item.finishDate || new Date().getTime()
                            } as IEvent} 
                            className=" pe-surface4" 
                        />
                    </div>
                </div>
                {
                   !!props.item.domain_type && props.item.domain_type !== "false"
                   && <Tag minimal className="pointer my-auto pe-surface2">{props.item.domain_type}</Tag>
                }
            </div>
            <div className="col-md-3 flex-centered pe-surface">
                <div className=" flex-centered w-100">
                    <div className="hint hint--top mr-1" data-hint={__("Owner")}>
                        <Icon icon="person" />
                    </div> 
                    <FestivalSiteOwnerForm item={props.item} />
                </div>
                {/* <FestPatternLabel item={props.item} />  */}
            </div>
            <div className="col-md-1 col-12 d-flex justify-content-between pe-surface">
                <div className="my-auto d-flex">
                    <button 
                        className="small d-flex p-2 bg-transparent text-force-contrast hover pointer hint hint--top" 
                        onClick={onGoto}
                        data-hint={ __("Goto") }
                    >
                        <Icon icon="double-chevron-right" />
                        <div className="ml-2 d-sm-inline d-lg-none">
                            { __("Goto") }
                        </div>
                    </button>
                    <button 
                        className="small d-flex p-2 bg-transparent text-force-contrast hover pointer hint hint--top" 
                        data-hint={__("Edit")} 
                        onClick={onEdit}
                    >                        
                        <Icon icon="cog" />
                        <div className="ml-2 d-sm-inline d-lg-none">
                            { __("Edit") }
                        </div>
                    </button>  
                </div>
                
            </div>
            {
                props.item.archived && 
                    <div className="mine-corner-no-verified admin-card-marathon ">
                        {__("in archive")}
                    </div>
            }
        </div> 
    </li>
}
export default FestivalAdminButton