import { useEffect, useState } from "react"
import { actions } from "../../data/actions"
import { GET_LOG_FILES } from "../../data/actionTypes"
import { ID, LOADING_STATUS } from "@/libs/interfaces/layouts"
import { IState, useMainStore } from "@/settings/zustand"
import LogFileForm from "./LogFileForm"
import { getLogTitleData } from "./LogFileTitle"
import LogsFilter from "./LogsFilter"
import { __ } from "@/libs/utilities"
import { LogData } from "../../data/LogData"
import { ILogData, ILogDatas, TIME_PERIOD } from "../../data/interfaces"
import { Loading } from "@/libs/useful"
import { Callout, Intent } from "@blueprintjs/core"
import "../../assets/style.scss" 

/* Protopia Ecosystem component */
export interface ILogsFormProps {
   
}
const LogsForm = (props: ILogsFormProps): JSX.Element => {

    const landId : ID = useMainStore((state: IState) => state.landId)
    const [isLoading, setIsLoading] = useState<LOADING_STATUS>(LOADING_STATUS.LOADING)
    const [logFiles, setLogFiles] = useState<any[]>([])
    const [period, setPeriod] = useState<TIME_PERIOD>(TIME_PERIOD.DAY)
    useEffect(() => {
        onReload( )
    }, [])

    const onReload = ( ) => {
        actions( GET_LOG_FILES, {landId: landId, ignore_land_id: true, period } )
            .then(res => {
                // console.log( res )
                setIsLoading( !!res.length ? LOADING_STATUS.SUCCESS_RESULT: LOADING_STATUS.NULL_RESULT )
                setLogFiles( res )
            })
    }

    let content: JSX.Element = <></>
    switch(isLoading){
        case LOADING_STATUS.NULL_RESULT:
            content = <Callout intent={Intent.DANGER} className="p-5 ">
                {__("No element exists")}
            </Callout>
            break
        case LOADING_STATUS.LOADING:
            return <div className="layout-app-state flex-centered">
                <Loading />
            </div>
        case LOADING_STATUS.SUCCESS_RESULT:
        default:
            const logGroups: any = {}
            logFiles.forEach(lf => { 
                const titleData = getLogTitleData( lf.file_name )
                if(!logGroups[ titleData.chapter ]) {
                    logGroups[ titleData.chapter ] = []
                }
                logGroups[ titleData.chapter ].push( lf )
            })
            content = <div className={`py-3 ${ isLoading === LOADING_STATUS.SECONDARY_LOADING ? "opacity_5" : "" }`}>
            {
                Object.keys(logGroups).map((chapter: string) => {
                    const logType: ILogData =  LogData()[chapter]
                    return <div key={chapter}>
                        <div className="lead title" >
                            <span className={` title-${ logType?.dangerType } `}>
                                {__( logType?.title || chapter )}
                            </span>
                        </div>
                    {
                        logGroups[chapter].map(( l: any, i: number ) => { 
                            return <LogFileForm logFile={ l } key={i} onReload={onReload} />
                        })
                    }
                    </div>
                }) 
            } 
            </div>
    }

    const onFilter = ( filters: ILogDatas, _period: TIME_PERIOD ) => {
        console.log( _period )
        setIsLoading( LOADING_STATUS.SECONDARY_LOADING )
        setPeriod( _period )
        actions( GET_LOG_FILES, { landId: landId, chapters: Object.keys(filters), ignore_land_id: true, period:_period } )
            .then(res => {
                console.log( res )
                setIsLoading( !!res.length ? LOADING_STATUS.SUCCESS_RESULT: LOADING_STATUS.NULL_RESULT )
                setLogFiles( res )
            })
    }
 

    return <div className='pe-logs-form-container'> 
        <LogsFilter onFilter={onFilter}/>
        { content } 
    </div>
}
export default LogsForm