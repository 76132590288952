import { ILandGroup } from "@/libs/interfaces/layouts"
import { IAdminStore, useAdminStore } from "@/modules/pe-admin-module/data/store"
import { Tag } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface ISingleLandGroupProps {
   item: ILandGroup
}
const SingleLandGroup = (props: ISingleLandGroupProps): JSX.Element => {
    const currentLandGroupID = useAdminStore((state: IAdminStore) => state.currentLandGroupID)
    const onClick = () => {
        useAdminStore.setState({ currentLandGroupID : props.item.id })
    }
    return <Tag 
        className='mr-1 mb-1 px-3 py-1 pointer hover' 
        round 
        minimal={props.item.id !== currentLandGroupID} 
        onClick={onClick}
    >
       { props.item.title }
    </Tag>
}
export default SingleLandGroup