import { __ } from "@/libs/utilities"
import { DataRow } from "./LogData"

/* Protopia Ecosystem component */
export interface ILogErrorProps {
   err: any
}
const LogError = ({ err }: ILogErrorProps): JSX.Element => {
    return !!err 
        ?
        <div className="w-100 bg-secondary-super-cloudy px-2 py-3">
            <div className="lead">{__("Info")}:</div>
            <DataRow data={err} />
        </div>
        :
        <></>
}
export default LogError