import { IFestival, ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { __ } from "@/libs/utilities"
import { Button, Callout, Popover, Position } from "@blueprintjs/core"
import { useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import TrackDefaultCarousel from "./TrackDefaultCarousel"
import TrackEditCard from "./TrackEditCard"
import { ID } from "@/libs/interfaces/layouts"

/* Protopia Ecosystem component */
export interface ITracksContentProps {
    festival: IFestival
    tracks: ITrack[]
    setTracks: (tracks: ITrack[]) => void
    onRestore: () => void
}
const TracksContent = ({tracks, ...props}: ITracksContentProps): JSX.Element => {
      
    const [isPopover, setIsPopover] = useState<boolean>(false)
    
 
    

    // a little function to help us with reordering the result
    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed); 
        return result;
    }; 

    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({ 
        userSelect: "none",
        padding: 0, 
        margin: `0 0 0 0`, 
        width: "100%",
        color: "#FFFFFF!important",
        // change background colour if dragging
        background: isDragging ? "#45526d" : "transparent", 
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? "var(--drop-select-color)" : "transparent",
        padding: 0, 
    })
    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        } 
        const _items: ITrack[] = reorder(
            tracks,
            result.source.index,
            result.destination.index
        );   
        props.setTracks( _items.map((item, i) => ({...item, order: i + 1 })) )
    } 
    const onTrackChoose = (trackData: ITrack) => {
        const _tracks: ITrack[] = [...tracks, {...trackData, order: tracks.length + 1} ] 
        props.setTracks(_tracks)
    }
    const onDelete = (id: ID) => {
        props.setTracks(tracks.filter(t => t.id !== id))
    }
    const onChangeTrack = (id: ID, field: keyof ITrack, value: any ) => {
        props.setTracks(tracks.map(t => {
            if(t.id === id) {
                console.log( { ...t, [field]: value } )
                return { ...t, [field]: value }
            }
            return t
        }))
    }
    const onClear = () => {
        props.setTracks( [] )
    }
    return <> 
        {
            !!tracks.length
                ?
                <div>
                    <DragDropContext onDragEnd={ onDragEnd }>
                        <Droppable 
                            droppableId="droppable"
                            direction="vertical" 
                        >
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        ...getListStyle(snapshot.isDraggingOver),
                                        padding: "20px 20px 0 20px"
                                    }}
                                >
                                    { tracks.map((item, index) => (
                                        <div key={item.id} className="d-flex mb-3"> 
                                            <Draggable 
                                                draggableId={item.id.toString()} 
                                                index={index}
                                            >
                                                {(provided, snapshot) => ( 
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <TrackEditCard 
                                                            item={item} 
                                                            onDelete={() => onDelete(item.id)}
                                                            onChange={(field, value) => onChangeTrack(item.id, field, value)}
                                                            key={item.id} 
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        </div>
                                    ))}
                                    { provided.placeholder }
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext> 
                </div> 
                :
                <Callout className="p-5 lead text-center">
                    {__("No elements exists")}
                </Callout>
        }
        <div className="d-flex justify-content-end w-100">
            <Button icon="cross" minimal onClick={onClear}>
                {__("Clear all")}
            </Button> 
            <Button icon="reset" minimal onClick={props.onRestore}>
                {__("Restore")}
            </Button>
            <Popover
                position={Position.TOP_RIGHT}
                content={ <TrackDefaultCarousel 
                    onChoose={onTrackChoose} 
                    tracks={tracks} 
                    setIsPopover={ is => setIsPopover(is) }
                /> }
                isOpen={isPopover}
            >
                <Button 
                    icon="plus" 
                    minimal 
                    onClick={() => setIsPopover(!isPopover)} 
                >
                    {__("Add new")}
                </Button>
            </Popover>
        </div>
    </>
}
export default TracksContent