export const SET_SIGN_TYPE: string = 'setSignType'
export const GET_SIGN_TYPE: string = 'getSignType'
export const GET_COLLECTION_TYPE: string = 'getCollectionType'
export const UPDATE_SINGLE_TYPE = 'updateSingle'
export const GET_OPTIONS_ACTION = 'getOptions'
export const UPDATE_OPTIONS_ACTION = 'updateOptions'
export const PREVIEW_LAYOUT_ACTION = "previewLayout"
export const UPDATE_DICTIONARY_ACTION = "updateDictionary" 
export const GET_LOG_FILES: string="getLogFiles"
export const DELETE_LOG_FILE_ACTION: string="deleteLogFile"
export const GET_TRASH_FILES_ACTION: string="getTrashFiles"
export const RESTORE_LOG_FILE_ACTION: string="restoreLogFile"
export const ERASE_TRASH_LOG_FILE_ACTION: string="eraseTrashLogFile"
export const DELETE_ALL_LOGS_ACTION: string="deleteAllLogs"
export const BULK_DOUBLE_ACTION: string="bulkDouble"