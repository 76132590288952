import { DIALOG_SIZE, ID, IPost } from "@/libs/interfaces/layouts"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { ILand } from "@/modules/pe-fest-module/data/interfaces"
import { Button } from "@blueprintjs/core"
import { useState } from "react"
import ImportFromLandForm from "./ImportFromLandForm"
import ResourceDrag from "./ResourceDrag"
import { useFestExport } from "@/modules/pe-fest-module/data/store"
import { useDataStore } from "@/modules/pe-basic-module/data/store"

/* Protopia Ecosystem component */
export interface IResourceContentProps<T> { 
    Component: any
    dataTypeName: string 
}
function ResourceContent<T extends IPost>({ Component, dataTypeName }: IResourceContentProps<T> ): JSX.Element {
    const [landId, setLandId] = useState<ID>(-1)
    const resources: T[] = useFestExport((state:any) => state[ dataTypeName ] || [] )
      
    const setResources = ( resources: T[] ) => {
        console.log( dataTypeName, resources )
        useFestExport.setState({[dataTypeName]: resources })
    }
    const onRestore = () => {
        const items: T[] = useDataStore.getState()[dataTypeName] || []
        useFestExport.setState({[dataTypeName]: items})
    }

    const onClear = () => {
        setResources( [] )
    } 
     
    const onImport = () => {
        actions(GET_FEED_ACTION, {data_type: dataTypeName, land_id: landId})
            .then(( res ) => { 
                setResources( [ ...resources, ...res.feed ].map((item, i) => ({...item, order: i + 1 })) )
            })
    }
    const onLands = (land: ILand ) => {
        setLandId(land.id) 
    }
    const addNew = () => {
        setResources([
            ...resources, 
            { 
                id: "-" + (resources.length + 1), 
                order: resources.length + 1, 
                title: `${dataTypeName} ${ resources.length +1 }`,
                post_content: "",

            } as T
        ])
    }

    return <div className="container h-100 my-3"> 
        <ResourceDrag
            Component={ Component } 
            resources={resources} 
            setResources={setResources} 
            dataTypeName={dataTypeName}
        />
        <div className="d-flex justify-content-md-end  justify-content-center w-100 mt-auto flex-wrap">
            <ConfirmButton
                buttonIcon="cross"
                buttonLabel={__("Clear all")}
                buttonMinimal
                dialogClasssName={DIALOG_SIZE.MINIMAL}  
                onConfirm={onClear}
            > 
                <div className="p-4">{__("Really clear all?")}</div> 
            </ConfirmButton>
            <ConfirmButton
                buttonIcon="reset"
                buttonLabel={__("Restore")}
                buttonMinimal
                dialogClasssName={DIALOG_SIZE.MINIMAL}  
                onConfirm={onRestore}
            >   
                <div className="p-4">{__("Really delete some object for restore default?")} </div>
            </ConfirmButton> 
            <ConfirmButton
                buttonIcon="import"
                buttonLabel={__("Import")}
                buttonMinimal
                dialogClasssName={DIALOG_SIZE.MINIMAL} 
                onConfirm={onImport}
            >
                <ImportFromLandForm onSelect={onLands} />
            </ConfirmButton>  
            <Button 
                icon="plus" 
                minimal 
                onClick={addNew} 
            >
                {__("Add new")}
            </Button> 
        </div>
    </div>
}
export default ResourceContent