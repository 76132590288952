import { Role } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import UserSearchEngine from "@/libs/useful/UserSearchEngine"
import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { CHANGE_USER_ROLES_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { Card } from "@blueprintjs/core"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface ISchoolRoleChooserProps {
   
}
const SchoolRoleChooser = (props: ISchoolRoleChooserProps): JSX.Element => {
    const landId = useMainStore((state: IState) => state.landId)
    const [user, setUser]=useState<IUser>()

    const onUserRole = (roles: Role[]) => {
        console.log(roles)
        const Tutor             = {role: "Tutor",           add: !!roles.filter(r => r === "Tutor").length          }
        const Project_author    = {role: "Project_author",  add: !!roles.filter(r => r === "Project_author").length }
        actions(
            CHANGE_USER_ROLES_ACTION,             
            {
                id: user!.id,
                roles:[Tutor, Project_author],
                landId
            }
        )
    }
    return <Card className='pe-school-role-chooser-container my-4 p-3 border border-secondary'>
        <div className="text-uppercase">
            {__("Choose user and send Role")}
        </div> 
        <div className="row align-items-center">
            <div className="col-md-6">
                <UserSearchEngine 
                    onSelect={setUser} 
                    user={user} 
                    notClear 
                    isMulti={false} 
                    // onlyThisLands
                    landId={landId}
                />
            </div>
            <div className="col-md-6">
                {
                    user && <div>
                        <FieldInput
                            editable
                            onChange={onUserRole}
                            value={user.roles}
                            type={SCALAR_TYPES.TAGS}
                            multiple
                            notClear
                            values={[
                                {_id: "Tutor", title: __("Tutor") },
                                {_id: "Project_author", title: __("Project_author") },
                            ]}
                        />
                    </div>
                }
            </div>
        </div>
        
    </Card>
}
export default SchoolRoleChooser